<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useRouter } from "vue-router";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useFaqStore } from "@/stores/faq";
import { Article } from "@/types/Faq";

import LoadingSection from "@/components/molecules/LoadingSection.vue";
import PathIndicator from "@/components/molecules/PathIndicator.vue";
import OpenedArticleContent from "@/components/molecules/OpenedArticleContent.vue";
import RateArticle from "@/components/molecules/RateArticle.vue";

const props = defineProps<{
  article: Article;
  loading: boolean;
}>();

const router = useRouter();
const faqStore = useFaqStore();

const relatedArticles = computed(() => {
  if (!faqStore.currentSectionArticles?.length) return [];

  return faqStore.currentSectionArticles
    .filter(({ id }) => id !== props.article.id)
    .slice(0, 3);
});

function selectTopicFromRelated(article: Article) {
  faqStore.getArticleContent(article.id);
}

function handleGoBackButton() {
  router.push("/central-de-ajuda");
}
</script>

<template>
  <div class="adc__invoices__section opened-article" v-if="loading">
    <LoadingSection />
  </div>
  <div class="adc__invoices__section opened-article" v-else>
    <div v-if="!article">
      {{ faqStore.gettingArticleError }}
    </div>
    <div v-else>
      <div class="opened-article__path">
        <PathIndicator
          :items="['Ajuda', faqStore.currentSection, article.title]"
        />
      </div>
      <div>
        <MeButton variant="outline" @click="handleGoBackButton">
          <span class="opened-article__go-back"
            ><MeIcon>arrow-down</MeIcon></span
          >
          Voltar
        </MeButton>
      </div>
      <OpenedArticleContent :article="article" />
      <div
        class="opened-article__recommendations"
        v-if="relatedArticles.length"
      >
        <p class="opened-article__recommendations__title">
          Tópicos relacionados:
        </p>
        <div
          v-for="(relatedArticle, index) in relatedArticles"
          :key="`opened-article-related-article-${index}`"
          class="opened-article__recommendations__link"
          @click="() => selectTopicFromRelated(relatedArticle)"
        >
          {{ relatedArticle.title }}
        </div>
      </div>
      <div class="opened-article__rate-article">
        <RateArticle :article="article" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.opened-article {
  background: #fff;
  margin: 1.5rem auto;
  border-radius: 1rem;

  padding: 2rem 1.675rem;

  &__path {
    border-bottom: 1px solid #dee2e6;

    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }

  &__go-back {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
  }

  &__recommendations {
    margin-bottom: 3.5rem;

    &__title {
      margin-bottom: 1.125rem;
      color: #000;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &__link {
      text-decoration: underline;
      cursor: pointer;

      width: fit-content;

      color: #2db699;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;

      &:not(:last-child) {
        margin-bottom: 1.125rem;
      }

      &:hover {
        font-size: 18px;
      }
    }
  }

  &__rate-article {
    margin-bottom: 1.5rem;
  }
}
</style>
