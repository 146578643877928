import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "ca-composed-select" }
const _hoisted_2 = {
  key: 0,
  class: "ca-composed-select__icon"
}
const _hoisted_3 = { class: "ca-composed-select__label" }
const _hoisted_4 = {
  key: 0,
  class: "ca-composed-select__options"
}
const _hoisted_5 = { class: "ca-composed-select__primary-options" }
const _hoisted_6 = {
  class: /*@__PURE__*/_normalizeClass(['ca-composed-select__primary-options__button'])
}
const _hoisted_7 = { class: "ca-composed-select__primary-options__label" }
const _hoisted_8 = {
  class: /*@__PURE__*/_normalizeClass(['ca-composed-select__primary-options__button'])
}
const _hoisted_9 = ["onClick"]

import { ref, computed, PropType } from "vue";

import { MeIcon } from "@/libs/metha-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'CaComposedSelect',
  props: {
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  primaryOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  secondaryOptions: {
    type: Object as PropType<{ [key: number]: string[] }>,
  },
  getValue: {
    type: Function,
    default: (item: number | string) => {
      return item;
    },
  },
  getName: {
    type: Function,
    default: (item: number | string) => {
      return item;
    },
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  selectedPrimary: {
    type: Number,
    required: true,
  },
},
  emits: ["update:modelValue", "update:selectedPrimary"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const isOpen = ref(false);
const actualPrimary = ref(props.selectedPrimary);

const isOnFirstSelectPage = computed(
  () =>
    props.primaryOptions[props.primaryOptions.length - 1] !==
    actualPrimary.value
);

const isOnLastSelectPage = computed(
  () => props.primaryOptions[0] !== actualPrimary.value
);

function handleChange(item: string | number) {
  const value = props.getValue(item);
  emit("update:modelValue", value);
  isOpen.value = false;
}

function goToNextPrimaryOption() {
  const currentOptionIndex = props.primaryOptions.findIndex(
    (el: number) => el === actualPrimary.value
  );

  if (currentOptionIndex === 0) {
    return;
  }

  actualPrimary.value = props.primaryOptions[currentOptionIndex - 1];
}

function goToPreviousPrimaryOption() {
  const currentOptionIndex = props.primaryOptions.findIndex(
    (el: number) => el === actualPrimary.value
  );

  if (currentOptionIndex === props.primaryOptions.length - 1) {
    return;
  }

  actualPrimary.value = props.primaryOptions[currentOptionIndex + 1];
}

function closeComposedSelect() {
  isOpen.value = false;
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "ca-composed-select__label-container",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
    }, [
      (__props.icon && __props.icon.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.icon), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.label), 1),
      _createElementVNode("span", {
        class: _normalizeClass([
          'ca-composed-select__switch-icon',
          { 'ca-composed-select__switch-icon--open': isOpen.value },
        ])
      }, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("chevron-bottom")
          ])),
          _: 1
        })
      ], 2)
    ]),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _withDirectives(_createVNode(_unref(MeIcon), { onClick: goToPreviousPrimaryOption }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" nm-arrow-left-circle-outline ")
                ])),
                _: 1
              }, 512), [
                [_vShow, isOnFirstSelectPage.value]
              ])
            ]),
            _createElementVNode("span", _hoisted_7, _toDisplayString(actualPrimary.value), 1),
            _createElementVNode("span", _hoisted_8, [
              _withDirectives(_createVNode(_unref(MeIcon), { onClick: goToNextPrimaryOption }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode(" nm-arrow-right-circle-outline ")
                ])),
                _: 1
              }, 512), [
                [_vShow, isOnLastSelectPage.value]
              ])
            ])
          ]),
          (__props.secondaryOptions)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(__props.secondaryOptions[actualPrimary.value], (item, index) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: `${index}`,
                  onClick: () => handleChange(item),
                  class: "ca-composed-select__options__item"
                }, _toDisplayString(__props.getName(item)), 9, _hoisted_9))
              }), 128))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, closeComposedSelect]
  ])
}
}

})