import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount } from "vue";

import { useRoute } from "vue-router";
import { useUserStore, useFaqStore } from "@/stores";

import FaqSearchBar from "@/components/organisms/FaqSearchBar.vue";
import ArticleSection from "@/components/organisms/ArticleSection";
import ServiceChannels from "@/components/organisms/ServiceChannels.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqArticle',
  setup(__props) {

const route = useRoute();

const userStore = useUserStore();
const faqStore = useFaqStore();

onBeforeMount(async () => {
  const id =
    typeof route.params.id === "string"
      ? +route.params.id
      : +route.params.id[0];

  await faqStore.getArticleContent(id);
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("section", null, [
    _createVNode(FaqSearchBar),
    _createVNode(_unref(ArticleSection), {
      loading: _unref(faqStore).loadingArticle,
      article: _unref(faqStore).currentArticle
    }, null, 8, ["loading", "article"]),
    _createVNode(ServiceChannels)
  ], 512)), [
    [_vShow, !_unref(userStore).pageLoading]
  ])
}
}

})