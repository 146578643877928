<script setup lang="ts">
import { defineProps, computed, markRaw, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";

import {
  getSustainabilityCardText,
  getSustainabilityCardValue,
} from "@/utils/sustainabilityCard";

const props = defineProps({
  kwhConsumption: {
    type: Number,
    required: true,
  },
});

const switchCardsOptions = markRaw([
  {
    icon: "nm-reduction-co2",
    type: "co2",
  },
  {
    icon: "nm-plant",
    type: "trees",
  },
  {
    icon: "nm-rising-sun",
    type: "coal",
  },
]);

const type = ref<"co2" | "trees" | "coal">("co2");

const cardValue = computed(() => {
  return getSustainabilityCardValue(type.value, props.kwhConsumption);
});
</script>

<template>
  <div class="sustainability-statistics">
    <div class="sustainability-statistics__header">
      <h2 class="sustainability-statistics__header__title">Sustentabilidade</h2>
      <nav class="sustainability-statistics__header__switch">
        <div
          v-for="option in switchCardsOptions"
          :key="`sustainability-statistics-switch-nav-item-${option.type}`"
          :class="[
            `sustainability-statistics__header__switch__item sustainability-statistics__header__switch__item--${option.type}`,
            {
              'sustainability-statistics__header__switch__item--active':
                type === option.type,
            },
          ]"
          @click="type = option.type"
        >
          <MeIcon>{{ option.icon }}</MeIcon>
        </div>
      </nav>
    </div>
    <p class="sustainability-statistics__text">
      {{ getSustainabilityCardText(type) }}
    </p>
    <p
      :class="[
        'sustainability-statistics__value',
        {
          'sustainability-statistics__value--sub-margin-remove':
            cardValue.match('<sub>'),
        },
      ]"
      v-html="cardValue"
    ></p>
  </div>
</template>

<style lang="scss" scoped>
.sustainability-statistics {
  background-color: #a8df53;
  border-radius: 1rem;
  color: #000;
  padding: 1.5rem 2rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;

    &__title {
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.3125rem;
    }

    &__switch {
      display: flex;
      align-items: center;
      gap: 0.25rem;

      &__item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 32px;
        height: 32px;
        border-radius: 100%;

        color: #000;

        font-size: 1.25rem;

        &:hover,
        &--active {
          background-color: #000;
          color: #a8df53;
          cursor: pointer;
        }

        &--co2 {
          align-items: center;
        }

        &--trees,
        &--coal {
          align-items: baseline;
          padding-top: 2px;
        }
      }
    }
  }

  &__text {
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;

    margin: 1.5rem 0 0.25rem;
  }

  &__value {
    font-size: 1.875rem;
    font-weight: 600;
    line-height: 2.5rem;

    @media (min-width: 993px) and (max-width: 1180px) {
      font-size: 1.5rem;
    }

    sub {
      font-size: 1rem;
    }

    &--sub-margin-remove {
      margin-bottom: -1rem;
    }
  }
}
</style>
