import { calculate } from "@/utils/carbonEquivalence";
import { formatTwoDecimalPlaces } from "@/utils/format";

export type SustainabilityCardType = "co2" | "trees" | "coal";

export const availableTexts = {
  co2: "Seu consumo de energia limpa evitou a emissão de",
  trees:
    "A quantidade de CO2 que evitou emitir equivale ao impacto positivo de",
  coal: "Seu consumo de energia limpa equivale a evitar a queima de",
};

export function getSustainabilityCardText(type: SustainabilityCardType) {
  return availableTexts[type];
}

export function getSustainabilityCardValue(
  type: SustainabilityCardType,
  kwhConsumption: number
) {
  const value = formatTwoDecimalPlaces(calculate(kwhConsumption)[type]);

  switch (type) {
    case "co2":
      return `${value} t de CO<sub>2<sub>`;
    case "trees":
      return `plantar ${value} árvore${+value === 1 ? "" : "s"}`;
    case "coal":
      return `${value} kg de carvão`;
    default:
      return "";
  }
}
