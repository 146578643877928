import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "header" }
const _hoisted_3 = { class: "main" }

import { useBreakpoints } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import LoggedIn from "@/layouts/LoggedIn.vue";
import LayoutHeader from "@/components/organisms/LayoutHeader.vue";
import LayoutSidebar from "@/components/organisms/LayoutSidebar.vue";
import SkeletonLoading from "@/components/organisms/SkeletonLoading.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const notificationNumber = 3;


export default /*@__PURE__*/_defineComponent({
  __name: 'DefaultLayout',
  setup(__props) {

const route = useRoute();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

const hideInstallationSwitchComponent = computed(() => {
  return route.meta.hideInstallationSwitchComponent ?? false;
});

const rounded = computed(() => {
  return route.meta.rounded ?? false;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(LoggedIn, null, {
    default: _withCtx(() => [
      _createElementVNode("aside", _hoisted_1, [
        (_unref(lgAndUp))
          ? (_openBlock(), _createBlock(LayoutSidebar, {
              key: 0,
              notificationNumber: notificationNumber
            }))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("header", _hoisted_2, [
        _createVNode(LayoutHeader, {
          title: _unref(userStore).pageTitle,
          notificationNumber: notificationNumber,
          hideInstallationSwitchComponent: hideInstallationSwitchComponent.value
        }, null, 8, ["title", "hideInstallationSwitchComponent"])
      ]),
      _createElementVNode("main", _hoisted_3, [
        _createElementVNode("div", {
          class: _normalizeClass(['main__content', { 'main__content--rounded': rounded.value }])
        }, [
          _withDirectives(_createVNode(SkeletonLoading, null, null, 512), [
            [_vShow, _unref(userStore).pageLoading]
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ])
    ]),
    _: 3
  }))
}
}

})