<script setup lang="ts">
import { computed, onBeforeMount } from "vue";

import { useUserStore, useInvoicesStore } from "@/stores";

import OverdueInvoicesList from "@/components/organisms/OverdueInvoicesList.vue";
import InvoicesList from "@/components/organisms/InvoicesList.vue";
import InvoicesNotFound from "@/components/organisms/InvoicesNotFound.vue";
import InvoicesBlockedByInstallationStatus from "@/components/organisms/InvoicesBlockedByInstallationStatus.vue";

import { orderInvoices } from "@/utils/orderInvoices";
import { handleError } from "@/utils/handleError";

const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const overdueInvoices = computed(() => {
  const orderedInvoices =
    orderInvoices(invoicesStore.invoices, "reference_month") ?? [];
  return orderedInvoices.filter(({ status }) => status === "overdue");
});

onBeforeMount(async () => {
  try {
    userStore.setPageTitle("Minhas Faturas");
    userStore.pageLoading = true;

    if (!hasInvoices.value) {
      await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});
</script>

<template>
  <section class="invoices-page" v-show="!userStore.pageLoading">
    <InvoicesBlockedByInstallationStatus
      v-if="userStore.selectedInstallation.status === 'canceled'"
    />
    <InvoicesNotFound v-else-if="!hasInvoices" />
    <template v-else>
      <OverdueInvoicesList
        v-if="overdueInvoices.length >= 1"
        :overdueInvoices="overdueInvoices"
      />
      <InvoicesList :invoices="invoicesStore.invoices" />
    </template>
  </section>
</template>

<style lang="scss" scoped>
.invoices-page {
  display: flex;
  flex-direction: column;
  gap: 0;
  padding-bottom: 2rem;

  @media (min-width: 993px) {
    margin-top: 1rem;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
}
</style>
