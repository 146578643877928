<script setup lang="ts">
import { ref, watch, computed } from "vue";

import { MeTextField, MeButton, useBreakpoints } from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useLoginStore, useUserStore } from "@/stores";

import { validateCPF } from "@/utils/validate";

const loginStore = useLoginStore();
const userStore = useUserStore();
const { mdAndDown } = useBreakpoints();

const cpf = ref("");
const loading = ref(false);

const invalidSubmit = computed(
  () =>
    !!(
      loading.value ||
      cpf.value.length !== 14 ||
      loginStore.cpfError.length ||
      !loginStore.isActiveUser
    )
);

async function handleSubmit() {
  if (invalidSubmit.value) return;

  loading.value = true;
  userStore.loadingScreen = true;

  await loginStore.submitCpf();

  loading.value = false;
  userStore.loadingScreen = false;
}

function redirectToMethaPwa() {
  window.location.href = "https://cadastro.methaenergia.com.br/";
}

watch(
  cpf,
  async (value: string) => {
    const normalizedCPF = value.replace(/\D/gm, "");
    loginStore.setCpfError("");
    loginStore.isActiveUser = true;

    if (normalizedCPF.length < 11) return;

    if (!validateCPF(normalizedCPF)) {
      loginStore.setCpfError("É necessário digitar um número de CPF válido");
    } else {
      loginStore.updateCpf(normalizedCPF);
    }
  },
  { immediate: true }
);
</script>

<template>
  <Form class="login__container login-verify-cpf" @submit="handleSubmit">
    <div class="login-verify-cpf__container">
      <div class="login-verify-cpf__metha-logo">
        <img
          src="@/assets/brand/logo.svg"
          alt="Logo da Metha Energia"
          class="login-verify-cpf__metha-logo"
          width="205"
        />
      </div>
      <h2 class="login-verify-cpf__title">Bem vindo(a)!</h2>
      <p class="login-verify-cpf__register">
        Faça login abaixo ou
        <a class="me--link" href="https://cadastro.methaenergia.com.br/"
          >cadastre-se{{ mdAndDown ? "" : " na Metha" }}</a
        >
      </p>
      <MeTextField
        label="Seu CPF"
        v-model="cpf"
        v-maska="'###.###.###-##'"
        placeholder="000.000.000-00"
        :error="loginStore.cpfError"
        class="login-verify-cpf__cpf-input"
      />
      <p
        v-if="!loginStore.isActiveUser"
        class="login-verify-cpf__not-active-error"
      >
        Você ainda não é um usuário ativo. Finalize seu cadastro
        <a
          class="me--link"
          href="https://cadastro.methaenergia.com.br/cadastro-existente"
          >clicando aqui</a
        >
        .
      </p>
      <MeButton
        block
        class="login-verify-cpf__login-button"
        :disabled="invalidSubmit"
      >
        Entrar
      </MeButton>
      <div class="login-verify-cpf__create-account">
        <p class="login-verify-cpf__create-account__text">
          Ainda não possui uma conta?
        </p>
        <div class="login-verify-cpf__create-account__line"></div>
      </div>
      <div class="login-verify-cpf__register-button">
        <MeButton variant="outline" @click="redirectToMethaPwa()">
          Cadastrar na Metha
        </MeButton>
      </div>
    </div>
  </Form>
</template>

<style lang="scss" scoped>
.login-verify-cpf {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: 993px) {
    width: 50vw;
    align-items: unset;
  }

  &__container {
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    padding: 1.5rem;
  }

  &__metha-logo {
    width: 230px;
    margin: 0 auto;

    @media (min-width: 993px) {
      margin: 0;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;

    margin: 2.5rem 0 0.5rem;
  }

  &__register {
    color: #7a7a7a;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  &__cpf-input {
    display: block;
    margin: 2.5rem 0 0;
  }

  &__not-active-error {
    font-size: 0.875rem;
    color: #ff0011;
  }

  &__login-button {
    width: 100%;
    margin: 1.5rem 0 2.5rem;
  }

  &__create-account {
    position: relative;
    width: 100%;
    height: 21px;
    z-index: -1;

    &__text {
      text-align: center;
      width: fit-content;
      height: 21px;
      z-index: 2;
      margin: 0 auto;

      color: #7a7a7a;
      font-size: 1rem;
      font-weight: 400;
      line-height: normal;
      width: fit-content;
      white-space: nowrap;
      padding: 0 0.5rem;
      background-color: #fff;
    }

    &__line {
      height: 1px;
      width: 100%;

      background-image: linear-gradient(
        to right,
        black 33%,
        rgba(255, 255, 255, 0) 0%
      );
      background-position: bottom;
      background-size: 5px 2px;
      background-repeat: repeat-x;

      z-index: 1;
    }

    &__text,
    &__line {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__register-button {
    color: #7a7a7a;
    font-size: 1rem;
    font-weight: 400;
    line-height: normal;

    width: fit-content;
    margin: 1rem auto 0;
  }

  @media (min-width: 993px) {
    &__metha-logo {
      width: 205px;
      margin: 0 auto;
    }

    &__title {
      font-size: 2.25rem;
      line-height: 2.25rem;
      letter-spacing: -0.72px;
      margin: 4rem 0 1.5rem;
    }

    &__register {
      font-size: 1.125rem;
      line-height: 1.875rem;
      letter-spacing: -0.36px;
    }

    &__cpf-input {
      margin: 2.5rem 0 0;
    }

    &__login-button {
      margin: 2.5rem 0;
    }

    &__create-account {
      &__text {
        font-size: 1rem;
      }
    }

    &__register-button {
      margin: 1.5rem auto 0;
    }
  }
}
</style>
