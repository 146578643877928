import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/brand/logo.svg'


const _hoisted_1 = { class: "login-verify-otp__return__icon-container" }
const _hoisted_2 = { class: "login-verify-otp__return__icon" }
const _hoisted_3 = { class: "login-verify-otp__container" }
const _hoisted_4 = { class: "login-verify-otp__register" }
const _hoisted_5 = { class: "login-verify-otp__code-container" }
const _hoisted_6 = { class: "login-verify-otp__code-container__resend-container" }
const _hoisted_7 = {
  key: 0,
  class: "login-verify-otp--error"
}
const _hoisted_8 = { class: "login-verify-otp__code-container__resend-container__break-in-mobile" }

import { onBeforeUnmount, ref, computed, watch, onMounted } from "vue";

import { MeIcon, MeButton, MeOtpCode } from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useLoginStore, useUserStore } from "@/stores";

import { maskEmail } from "@/utils/mask";
import { handleError } from "@/utils/handleError";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginVerifyOTP',
  setup(__props) {

const loginStore = useLoginStore();
const userStore = useUserStore();

const otpCode = ref("");
const loadingSeconds = ref(0);
const timerInterval = ref<number>();

const loading = ref(false);

const filteredEmail = computed(() => {
  return maskEmail(loginStore.email);
});

function startTimer() {
  if (loadingSeconds.value !== 0) return;

  loadingSeconds.value = 30;
  timerInterval.value = setInterval(() => {
    if (loadingSeconds.value === 1) {
      clearInterval(timerInterval.value);
    }
    loadingSeconds.value -= 1;
  }, 1000);
}

function abortTimer() {
  loadingSeconds.value = 0;
  clearInterval(timerInterval.value);
}

async function sendCode() {
  if (loadingSeconds.value !== 0) return;

  try {
    userStore.loadingScreen = true;

    startTimer();

    await loginStore.sendOtpCode();
  } catch (error) {
    abortTimer();

    if (loginStore.verificationMethod === "SMS") {
      loginStore.blockSmsVerification = true;
      changeMethod();
    } else {
      handleError(error, loginStore.setOtpError);
    }
  } finally {
    userStore.loadingScreen = false;
  }
}

async function handleSubmit() {
  if (otpCode.value.length !== 6 || loginStore.loadingOtp || loading.value)
    return;
  loading.value = true;
  userStore.loadingScreen = true;

  try {
    await loginStore.validateOtpCode(otpCode.value);
    await loginStore.redirectToInstallationSelection();
  } catch (error) {
    handleError(error, loginStore.setOtpError);
  } finally {
    loading.value = false;
    userStore.loadingScreen = false;
    loginStore.loadingOtp = false;
  }
}

function changeMethod() {
  if (loadingSeconds.value !== 0 && !loginStore.blockSmsVerification) return;

  loginStore.changeVerificationMethod();
  sendCode();
}

function goBackButtonSubmit() {
  loginStore.changeStatus("verify-cpf");
}

watch(otpCode, () => {
  loginStore.setOtpError("");
});

onMounted(async () => {
  loginStore.verificationMethod = loginStore.blockSmsVerification
    ? "e-mail"
    : "SMS";
  loginStore.setOtpError("");
  if (loginStore.shouldSendOtpCode) {
    sendCode();
  } else {
    startTimer();
  }
});

onBeforeUnmount(() => {
  abortTimer();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(Form), {
    class: "login__container login-verify-otp",
    onSubmit: handleSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("p", {
        class: "login-verify-otp__return me--link",
        onClick: goBackButtonSubmit
      }, [
        _createElementVNode("span", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[1] || (_cache[1] = [
                _createTextVNode("arrow-back")
              ])),
              _: 1
            })
          ])
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "login-verify-otp__return__text" }, "Voltar", -1))
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[7] || (_cache[7] = _createElementVNode("div", { class: "login-verify-otp__metha-logo" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo da Metha Energia",
            class: "login-verify-otp__metha-logo",
            width: "205"
          })
        ], -1)),
        _cache[8] || (_cache[8] = _createElementVNode("h2", { class: "login-verify-otp__title" }, "Confirmar identidade", -1)),
        _createElementVNode("p", _hoisted_4, [
          (_unref(loginStore).verificationMethod === 'SMS')
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _cache[3] || (_cache[3] = _createTextVNode(" Enviamos um código via SMS de verificação para o celular com número final: ")),
                _createElementVNode("b", null, _toDisplayString(_unref(loginStore).phone.slice(-4)), 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createTextVNode(" Enviamos um código de verificação para seu e-mail: " + _toDisplayString(filteredEmail.value) + ", confira em sua caixa de entrada e também na caixa de spam. ", 1)
              ], 64))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("p", {
              class: _normalizeClass([
              'login-verify-otp__code-container__resend-container__title',
              { 'login-verify-otp--error': _unref(loginStore).otpError },
            ])
            }, " Código ", 2)
          ]),
          _createVNode(_unref(MeOtpCode), {
            modelValue: otpCode.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((otpCode).value = $event)),
            error: _unref(loginStore).otpError
          }, null, 8, ["modelValue", "error"]),
          (_unref(loginStore).otpError && _unref(loginStore).otpError.length > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(loginStore).otpError), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_unref(MeButton), {
          block: "",
          disabled: otpCode.value.length !== 6 || _unref(loginStore).loadingOtp || loading.value,
          class: "login-verify-otp__otp-submit-button"
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Entrar ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        _createElementVNode("p", {
          class: "login-verify-otp__code-container__resend-container__button",
          onClick: sendCode
        }, [
          (loadingSeconds.value !== 0)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createTextVNode(" Código enviado! Aguarde " + _toDisplayString(loadingSeconds.value) + "s para reenviar. ", 1)
              ], 64))
            : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                _createElementVNode("span", _hoisted_8, [
                  _cache[5] || (_cache[5] = _createTextVNode(" Não recebeu? ")),
                  _createElementVNode("span", {
                    class: "me--link me--bold cursor-pointer",
                    onClick: _withModifiers(sendCode, ["prevent"])
                  }, " enviar outro " + _toDisplayString(_unref(loginStore).verificationMethod), 1)
                ]),
                (!_unref(loginStore).blockSmsVerification)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      _cache[6] || (_cache[6] = _createTextVNode(" ou ")),
                      _createElementVNode("span", {
                        class: "me--link me--bold cursor-pointer",
                        onClick: _withModifiers(changeMethod, ["prevent"])
                      }, " enviar via " + _toDisplayString(_unref(loginStore).verificationMethod === "SMS" ? "e-mail" : "SMS"), 1)
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
        ])
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", null, null, -1))
    ]),
    _: 1
  }))
}
}

})