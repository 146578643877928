<template>
  <div class="ca-modal">
    <div class="ca-modal__content">
      <slot />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ca-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;

  background-color: rgba(0, 0, 0, 0.2);

  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    margin: auto;
  }
}
</style>
