import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

const initialWarningState = () => ({
  showAuthenticationError: false,
  showSupplierWarning: false,
  showBusyChatWarning: false,
});

export const useWarningStore = defineStore("warning", {
  state: () =>
    useLocalStorage("@metha-energia/adc-warnings", initialWarningState()),
  actions: {
    getStoreFromMobile(payload: any) {
      Object.assign(this, payload);
    },
    async clear() {
      Object.assign(this, initialWarningState());
    },
    setShowAuthenticationError(value: boolean) {
      this.showAuthenticationError = value;
    },
    closeSupplierWarning() {
      this.showSupplierWarning = false;
    },
    closeBusyChatWarning() {
      this.showBusyChatWarning = false;
    },
  },
});
