import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "contract-display" }
const _hoisted_2 = { class: "contract-display__content" }
const _hoisted_3 = { class: "contract-display__option-buttons" }

import { MeIcon, MeCheckbox, MeButton } from "@/libs/metha-components";
import { useAuthStore, useContractStore } from "@/stores";
import { handleError } from "@/utils/handleError";
import { ref } from "vue";
import { toast } from "vue3-toastify";


export default /*@__PURE__*/_defineComponent({
  __name: 'ContractDisplay',
  setup(__props) {

const contractStore = useContractStore();

const accepted = ref(false);

async function sendContractSignature() {
  if (!accepted.value) return;

  try {
    const authStore = useAuthStore();
    const token = await authStore.getToken("sendContractSignature");

    contractStore.sendContractSignature(token);
  } catch (error) {
    handleError(
      error,
      (errorMessage: string) => {
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      },
      {
        default:
          "Ocorreu um erro ao tentar registrar a assinatura do contrato. Tente novamente mais tarde por favor.",
      }
    );
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "contract-display__close-button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(contractStore).closeContract && _unref(contractStore).closeContract(...args)))
    }, [
      _createVNode(_unref(MeIcon), null, {
        default: _withCtx(() => _cache[5] || (_cache[5] = [
          _createTextVNode("x")
        ])),
        _: 1
      })
    ]),
    _cache[12] || (_cache[12] = _createElementVNode("h2", null, "Termos de Adesão e Comercialização", -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "contract-display__content__download-terms",
        type: "button",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(contractStore).downloadTerms('adesao')))
      }, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode("contract")
          ])),
          _: 1
        }),
        _cache[7] || (_cache[7] = _createElementVNode("span", { class: "contract-display__content__download-terms__title" }, " Termo de Adesão ", -1))
      ]),
      _createElementVNode("button", {
        class: "contract-display__content__download-terms",
        type: "button",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(contractStore).downloadTerms('comercializacao')))
      }, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("contract")
          ])),
          _: 1
        }),
        _cache[9] || (_cache[9] = _createElementVNode("span", { class: "contract-display__content__download-terms__title" }, " Termo de Comercialização ", -1))
      ])
    ]),
    _createVNode(_unref(MeCheckbox), {
      modelValue: accepted.value,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((accepted).value = $event)),
      class: "contract-display__accept-terms"
    }, {
      default: _withCtx(() => _cache[10] || (_cache[10] = [
        _createElementVNode("span", { class: "contract-display__accept-terms__text" }, " Declaro que li e concordo com os termos acima. ", -1)
      ])),
      _: 1
    }, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", {
        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_unref(contractStore).closeContract && _unref(contractStore).closeContract(...args))),
        class: "contract-display__option-buttons__go-back link"
      }, " Voltar "),
      _createVNode(_unref(MeButton), {
        disabled: !accepted.value,
        onClick: sendContractSignature
      }, {
        default: _withCtx(() => _cache[11] || (_cache[11] = [
          _createTextVNode(" Continuar ")
        ])),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}
}

})