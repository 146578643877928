const daysByMonth = {
  "01": 31,
  "02": 28,
  "03": 31,
  "04": 30,
  "05": 31,
  "06": 30,
  "07": 31,
  "08": 31,
  "09": 30,
  "10": 31,
  "11": 30,
  "12": 31,
};

export const birthdate = (value: string) => {
  if (!value) return false;

  const [day, month, year] = value.split("/");

  const dayNumber = Number(day);
  const monthNumber = Number(month);
  const yearNumber = Number(year);

  const isValidMonth = monthNumber >= 1 && monthNumber <= 12;

  if (!isValidMonth) return false;

  const isValidDay =
    dayNumber >= 1 &&
    dayNumber <= daysByMonth[month as keyof typeof daysByMonth];

  const actualYear = new Date().getFullYear();

  const isValidYear = yearNumber < actualYear && yearNumber > actualYear - 150;

  return isValidDay && isValidYear;
};
