import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoice-overrun" }
const _hoisted_2 = { class: "invoice-overrun__subtitle" }
const _hoisted_3 = { class: "invoice-overrun__title" }


import { Invoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'InvoiceOverrun',
  props: {
    invoice: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, [
      _cache[0] || (_cache[0] = _createTextVNode(" Saldo atual de geração: ")),
      _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.invoice.overrun
            ? `${String(_ctx.invoice.overrun).replace(".", ",")} kWh`
            : "Não informado"), 1)
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "invoice-overrun__description" }, " energia enviada para você mas que não foi consumida ", -1))
  ]))
}
}

})