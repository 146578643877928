<script setup lang="ts">
import { defineProps, reactive, onBeforeMount } from "vue";
import { MeColumnsChart } from "@/libs/metha-components";

import { orderByReferenceMonth } from "@/utils/orderInvoices";
import { Invoice } from "@/types/InvoiceTypes";

const props = defineProps<{
  invoices: Invoice[];
  mainColor?: string;
  secondaryColor?: string;
}>();

const chartInfo = reactive({
  labels: [] as string[],
  methaConsumptionValues: [] as number[],
  methaColor: props.mainColor ?? "#FFCC12",
  supplierConsumptionValues: [] as number[],
  supplierColor: props.secondaryColor ?? "#E3E5E4",
});

let lastInvoice: {
  kwh_injected: number;
  kwh_consumption: number;
  reference_month: string;
};

const months = [
  "JAN",
  "FEV",
  "MAR",
  "ABR",
  "MAI",
  "JUN",
  "JUL",
  "AGO",
  "SET",
  "OUT",
  "NOV",
  "DEZ",
];

function getNextMonth(month: string) {
  const monthIndex = months.findIndex((monthName) => month === monthName);

  if (monthIndex === months.length - 1) {
    return "JAN";
  }

  return months[monthIndex + 1];
}

onBeforeMount(() => {
  let orderedInvoices = orderByReferenceMonth(props.invoices) ?? [];
  orderedInvoices = orderedInvoices.reverse();

  orderedInvoices
    .slice(0, 6)
    .forEach(
      (invoice: {
        kwh_injected: number;
        kwh_consumption: number;
        reference_month: string;
      }) => {
        const { kwh_injected, kwh_consumption, reference_month } = invoice;

        const [invoiceMonth] = reference_month.split("/");
        const supplierConsumption = kwh_consumption - kwh_injected;

        chartInfo.labels = [...new Set([...chartInfo.labels, invoiceMonth])];
        chartInfo.methaConsumptionValues = [
          ...chartInfo.methaConsumptionValues,
          kwh_injected,
        ];
        chartInfo.supplierConsumptionValues = [
          ...chartInfo.supplierConsumptionValues,
          supplierConsumption,
        ];

        lastInvoice = invoice;
      }
    );

  if (chartInfo.labels && chartInfo.labels.length < 6 && lastInvoice) {
    let securityLock = 0;

    while (chartInfo.labels.length < 6 && securityLock < 10) {
      const nextMonth = getNextMonth(
        chartInfo.labels[chartInfo.labels.length - 1]
      );

      chartInfo.labels = [...new Set([...chartInfo.labels, nextMonth])];

      securityLock += 1;
    }
  }
});
</script>

<template>
  <div class="past-injections-graph">
    <h3 class="past-injections-graph__title">
      Consumo em kWh dos últimos 6 meses
    </h3>
    <MeColumnsChart
      :labels="chartInfo.labels"
      :primaryValues="chartInfo.methaConsumptionValues"
      :primaryColor="chartInfo.methaColor"
      :secondaryValues="chartInfo.supplierConsumptionValues"
      :secondaryColor="chartInfo.supplierColor"
    />

    <div class="past-injections-graph__legend">
      <p class="past-injections-graph__legend__item">
        <span
          class="past-injections-graph__legend__color-tag past-injections-graph__legend__color-tag--secondary"
        ></span>
        energia concessionária
      </p>
      <p class="past-injections-graph__legend__item">
        <span
          class="past-injections-graph__legend__color-tag past-injections-graph__legend__color-tag--primary"
        ></span>
        energia limpa metha
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.past-injections-graph {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  &__title {
    color: #6c757d;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.5rem;
  }

  &__legend {
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.25rem;
    letter-spacing: 1px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;

    &__item {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__color-tag {
      display: inline-block;
      width: 12px;
      height: 20px;
      border-radius: 0.5rem;

      &--primary {
        background-color: #ffcc12;
      }

      &--secondary {
        background-color: #e3e5e4;
      }
    }
  }
}
</style>
