<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useBreakpoints, MeIcon } from "@/libs/metha-components";

import { Invoice } from "@/types/InvoiceTypes";
import { getQrImage } from "@/utils/aws";

import QrCodeImage from "@/assets/images/mock/qr-code.png";

import CopyContainer from "@/components/atoms/CopyContainer.vue";

const props = defineProps<{
  auxiliarText?: string;
  invoice: Invoice;
}>();

const { mdAndUp } = useBreakpoints();

const hasAuxiliarText = computed(() => {
  if (!props.auxiliarText) return false;

  return props.auxiliarText.trim().length !== 0;
});

const hasPixInformation = computed(() => {
  return (
    !!(props.invoice.pix?.brcode || props.invoice.pix?.qrcode?.s3_file) ?? null
  );
});

const pixCode = computed(() => {
  return props.invoice.pix?.brcode ?? "";
});

const hasQrCodeImage = computed(() => {
  return !!props.invoice.pix?.qrcode?.s3_file ?? false;
});

const qrCodeImagePath = computed(() => {
  const qrCodeInfo = hasQrCodeImage.value
    ? props.invoice.pix?.qrcode?.s3_file
    : null;

  if (qrCodeInfo) {
    return getQrImage(qrCodeInfo.bucket, qrCodeInfo.key);
  }

  return QrCodeImage;
});
</script>

<template>
  <div class="pix-payment">
    <CopyContainer
      :valueToCopy="pixCode"
      :disabled="!hasPixInformation"
      successMessage="Código Pix copiado!"
    >
      <div
        :class="[
          'pix-payment__content',
          { 'pix-payment__content--active': hasPixInformation },
        ]"
      >
        <div class="pix-payment__side-text">
          <span class="pix-payment__side-text__icon">
            <MeIcon>pix</MeIcon>
          </span>
          <div>
            <p
              v-if="hasAuxiliarText && hasPixInformation"
              class="pix-payment__side-text__auxiliar"
            >
              {{ auxiliarText }}
            </p>
            <p
              :class="`${
                hasPixInformation
                  ? 'pix-payment__side-text__copy-text'
                  : 'pix-payment__side-text__auxiliar'
              }`"
            >
              {{
                hasPixInformation
                  ? "Copiar o código Pix"
                  : "O pagamento por pix não está disponível para essa fatura"
              }}
            </p>
          </div>
        </div>
        <div class="pix-payment__image" v-if="mdAndUp">
          <p
            v-if="hasPixInformation && !hasQrCodeImage"
            class="pix-payment__image__error-text"
          >
            Houve um problema ao gerar o QR Code
          </p>
          <img
            :src="qrCodeImagePath"
            alt="QR Code - Aperte aqui para copiar"
            :class="[
              'pix-payment__image__qr-code',
              { 'pix-payment__image__qr-code--blur': !hasQrCodeImage },
            ]"
          />
        </div>
      </div>
    </CopyContainer>
  </div>
</template>

<style lang="scss" scoped>
.pix-payment {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    background: #f5f5f5;

    padding: 12px 16px;

    @media (min-width: 993px) {
      justify-content: space-between;
      border-radius: 8px;
      border: 1px solid #dee2e6;
      padding: 10px 16px;
    }

    &--active {
      @media (min-width: 993px) {
        background: #ffffff;

        &:hover {
          background-color: #dee2e6;
          cursor: pointer;
        }
      }
    }
  }

  &__side-text {
    display: flex;
    align-items: center;

    &__icon {
      display: flex;
      margin-right: 10px;
      font-size: 30px;
    }

    &__copy-text {
      font-size: 16px;
      font-weight: 700;
      line-height: 20px;
      color: #000;

      @media (min-width: 993px) {
        text-decoration: underline;
      }
    }

    &__auxiliar {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #6c757d;
    }
  }

  &__image {
    width: 100%;
    max-width: 130px;
    height: 100%;

    &__error-text {
      font-size: 0.75rem;
      font-weight: 400;
      line-height: 1rem;
      color: #6c757d;
      text-align: center;

      margin-bottom: 2px;
    }

    &__qr-code {
      width: 100%;
      height: 100%;
      aspect-ratio: 1 / 1;
      overflow: hidden;

      &--blur {
        filter: blur(3px);
      }
    }
  }

  &__unnavailable {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 8px;
    color: #4e545a;
    cursor: not-allowed;
    padding: 1.5rem;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;

    &__text {
      max-width: 300px;
      text-align: center;
    }
  }
}
</style>
