import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mobile-navigation" }

import { navLinks } from "@/router/navLinks";

import MobileNavigationItem from "@/components/atoms/MobileNavigationItem.vue";
import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileNavigation',
  setup(__props) {

const filteredNavLinks = computed(() => {
  return navLinks.slice(1);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredNavLinks.value, ({ icon, title, route }) => {
      return (_openBlock(), _createBlock(MobileNavigationItem, {
        key: `mobile-nav-icon-${title}`,
        icon: icon,
        title: title,
        route: route
      }, null, 8, ["icon", "title", "route"]))
    }), 128))
  ]))
}
}

})