import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/brand/logo.svg'


const _hoisted_1 = { class: "nav-links" }

import { useRoute } from "vue-router";
import { navLinks } from "@/router/navLinks";
import SidebarTabs from "@/components/atoms/SidebarTabs.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavLinks',
  setup(__props) {

function isActive(route: string) {
  const { path } = useRoute();
  return path === route;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      src: _imports_0,
      alt: "Logo da Metha Energia",
      class: "nav-links__logo"
    }, null, -1)),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(navLinks), ({ title, icon, route }) => {
      return (_openBlock(), _createBlock(SidebarTabs, {
        key: `${title}`,
        title: title,
        icon: icon,
        route: route,
        active: isActive(route)
      }, null, 8, ["title", "icon", "route", "active"]))
    }), 128))
  ]))
}
}

})