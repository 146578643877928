import { localize, setLocale } from "@vee-validate/i18n";

// Rules
import { all } from "@vee-validate/rules";
import * as customRules from "./rules";
import { configure, defineRule } from "vee-validate";

// Locales
import pt_BR from "./locales/pt_BR.json";

Object.entries({ ...all, ...customRules }).forEach(([name, rule]: any) => {
  defineRule(name, rule);
});

configure({
  // Generates an English message locale generator
  generateMessage: localize({
    pt_BR,
  }),
});

setLocale("pt_BR");
