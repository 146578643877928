import { FlatInstallation } from "@/types/InstallationTypes";

const allowedStatuses = {
  updateContract: [
    "active",
    "approved",
    "pending_agreement",
    "pending_update",
    "suspended",
  ],
};

export function canSignContract(installation: FlatInstallation) {
  if (!allowedStatuses.updateContract.includes(installation.status))
    return false;

  return (
    installation.status === "pending_agreement" ||
    installation.pendency_contract_update
  );
}
