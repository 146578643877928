import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "installations-page" }
const _hoisted_2 = { class: "adc__invoices__section installations-page__client-information" }
const _hoisted_3 = { class: "adc__invoices__section installations-page__installations-list" }

import { computed, onBeforeMount } from "vue";
import { useUserStore } from "@/stores";

import { formatCPF } from "@/utils/format";
import { orderInstallations } from "@/utils/orderInstallations";

import InstallationCard from "@/components/organisms/InstallationCard.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationsPage',
  setup(__props) {

const userStore = useUserStore();

const orderedInstallations = computed(() => {
  return orderInstallations(userStore.installations);
});

onBeforeMount(() => {
  userStore.setPageTitle("Minhas Instalações");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("p", null, [
        _cache[0] || (_cache[0] = _createElementVNode("span", { class: "installations-page__client-information__label" }, " Titular: ", -1)),
        _createTextVNode(" " + _toDisplayString(_unref(userStore).name), 1)
      ]),
      _createElementVNode("p", null, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "installations-page__client-information__label" }, " Cpf: ", -1)),
        _createTextVNode(" " + _toDisplayString(_unref(formatCPF)(_unref(userStore).cpf)), 1)
      ])
    ]),
    _createElementVNode("section", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(orderedInstallations.value, (installation) => {
        return (_openBlock(), _createBlock(InstallationCard, {
          key: `installation-list-${installation.id}`,
          installation: installation
        }, null, 8, ["installation"]))
      }), 128))
    ])
  ]))
}
}

})