<script setup lang="ts">
import { defineProps, computed } from "vue";

const props = defineProps({
  size: {
    type: Number,
    default: 60,
  },
  width: {
    type: Number,
    default: 10,
  },
});

const computedStyle = computed(() => ({
  "--spinner-size": `${props.size}px`,
  "--spinner-width": `${props.width}px`,
}));
</script>

<template>
  <div class="spinner" :style="computedStyle"></div>
</template>

<style lang="scss" scoped>
.spinner {
  content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a12" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="%23FFCC12"></stop><stop offset=".3" stop-color="%23FFCC12" stop-opacity=".9"></stop><stop offset=".6" stop-color="%23FFCC12" stop-opacity=".6"></stop><stop offset=".8" stop-color="%23FFCC12" stop-opacity=".3"></stop><stop offset="1" stop-color="%23FFCC12" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(%23a12)" stroke-width="17" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="1" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="%23FFCC12" stroke-width="17" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>');
  width: var(--spinner-size);
}
</style>
