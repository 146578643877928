import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "invoices-blocked-by-installation-status" }
const _hoisted_2 = { class: "invoices-blocked-by-installation-status__container" }
const _hoisted_3 = { class: "invoices-blocked-by-installation-status__icon" }
const _hoisted_4 = ["src"]

import ChatInfo from "@/assets/icons/chart-info.svg";

export default /*@__PURE__*/_defineComponent({
  __name: 'InvoicesBlockedByInstallationStatus',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", { src: _unref(ChatInfo) }, null, 8, _hoisted_4)
      ]),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "invoices-blocked-by-installation-status__text" }, [
        _createElementVNode("p", null, [
          _createElementVNode("b", null, "Essa instalação está cancelada."),
          _createElementVNode("br"),
          _createElementVNode("br"),
          _createTextVNode(" O histórico de faturas não está disponível devido a uma instabilidade, para ter acesso entre em contato através do chat ")
        ])
      ], -1))
    ])
  ]))
}
}

})