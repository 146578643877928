import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString } from "vue"
import _imports_0 from '@/assets/brand/logo.svg'


const _hoisted_1 = { class: "login__container login-select-installation" }
const _hoisted_2 = { class: "login-select-installation__return__icon-container" }
const _hoisted_3 = { class: "login-select-installation__return__icon" }
const _hoisted_4 = { class: "login-select-installation__installations-container" }
const _hoisted_5 = {
  key: 0,
  class: "login-select-installation--error"
}
const _hoisted_6 = { class: "login-select-installation__return__icon-container" }
const _hoisted_7 = { class: "login-select-installation__return__icon" }

import { ref } from "vue";
import { Form } from "vee-validate";

import {
  MeButton,
  MeIcon,
  MeSelectInstallationCard,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore, useLoginStore, useInvoicesStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";

import { getSupplierLogo } from "@/utils/supplierlogo";
import { handleError } from "@/utils/handleError";
import { capitalize } from "@/utils/format";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginSelectInstallation',
  setup(__props) {

const loginStore = useLoginStore();
const userStore = useUserStore();
const invoicesStore = useInvoicesStore();
const { mdAndDown } = useBreakpoints();

const selectedInstallation = ref<string | number>();
const selectInstallationError = ref("");

async function handleSubmit() {
  if (!selectedInstallation.value) return;

  try {
    userStore.loadingScreen = true;

    const installation = userStore.installations.find(
      (installation: FlatInstallation) =>
        installation.id === selectedInstallation.value
    );

    if (!installation) return;

    userStore.selectInstallation(installation);

    await invoicesStore.getAllInvoices(installation.id);

    userStore.loadingScreen = false;

    loginStore.redirect();
  } catch (error) {
    handleError(error, (err: string) => {
      selectInstallationError.value = err;
    });
  } finally {
    userStore.loadingScreen = false;
  }
}

function goBackToVerifyCpf() {
  userStore.logOutUser();
  loginStore.changeStatus("verify-cpf");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_unref(mdAndDown))
      ? (_openBlock(), _createElementBlock("p", {
          key: 0,
          class: "login-select-installation__return me--link",
          onClick: goBackToVerifyCpf
        }, [
          _createElementVNode("span", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode(" arrow-back ")
                ])),
                _: 1
              })
            ])
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("span", { class: "login-verify-otp__return__text" }, "Voltar", -1))
        ]))
      : _createCommentVNode("", true),
    _createVNode(_unref(Form), {
      class: "login-select-installation__container",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "login-select-installation__metha-logo" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo da Metha Energia",
            class: "login-select-installation__metha-logo",
            width: "205"
          })
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("h2", { class: "login-select-installation__title" }, "Instalações", -1)),
        _cache[7] || (_cache[7] = _createElementVNode("p", { class: "login-select-installation__register" }, " Selecione uma instalação abaixo para continuar ", -1)),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(userStore).operationalInstallations, ({
            id,
            status,
            installation_number,
            address,
            supplier,
          }) => {
            return (_openBlock(), _createBlock(_unref(MeSelectInstallationCard), {
              key: `login-select-installation-${installation_number}`,
              status: status,
              installationNumber: installation_number,
              address: _unref(capitalize)(address),
              clickable: id !== selectedInstallation.value,
              supplierLogo: _unref(getSupplierLogo)(supplier),
              selected: id === selectedInstallation.value,
              selectable: "",
              onClick: ($event: any) => (selectedInstallation.value = id)
            }, null, 8, ["status", "installationNumber", "address", "clickable", "supplierLogo", "selected", "onClick"]))
          }), 128)),
          (selectInstallationError.value && selectInstallationError.value.length > 0)
            ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(selectInstallationError.value), 1))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_unref(MeButton), {
          block: "",
          class: "login-select-installation__submit-button",
          disabled: !selectedInstallation.value
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Continuar ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        (_unref(mdAndDown))
          ? (_openBlock(), _createElementBlock("p", {
              key: 0,
              class: "login-select-installation__return me--link",
              onClick: goBackToVerifyCpf
            }, [
              _createElementVNode("span", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_unref(MeIcon), null, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" arrow-back ")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("span", { class: "login-verify-otp__return__text" }, "Voltar", -1))
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _cache[8] || (_cache[8] = _createElementVNode("div", null, null, -1))
  ]))
}
}

})