import request from "./request";
import { AxiosRequestConfig } from "axios";
import { Query } from "./types";

const baseUrl = process.env.VUE_APP_CONTRACTS_BASE_URL;

export async function getFile<T>(
  fileUrl: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
): Promise<T> {
  return request({
    method: "GET",
    params,
    url: `${baseUrl}${fileUrl}`,
    responseType: "arraybuffer",
    public: true,
    ...config,
  });
}
