<script setup lang="ts">
import { computed, ref } from "vue";

import { useBreakpoints } from "@/libs/metha-components";

const { lgAndUp } = useBreakpoints();

const isOpen = ref(false);

const isAcordeonOpen = computed(() => {
  if (lgAndUp.value) return true;

  return isOpen.value;
});

const bindedProps = computed(() => {
  return {
    isOpen: isAcordeonOpen.value,
    handleClick,
  };
});

function handleClick() {
  isOpen.value = !isOpen.value;
}
</script>

<template>
  <div class="acordeon-switch">
    <div class="acordeon-switch__activator" v-show="!isAcordeonOpen">
      <slot v-bind="bindedProps" />
    </div>
    <div v-show="isAcordeonOpen">
      <slot name="content" v-bind="bindedProps" />
      <div v-show="!lgAndUp">
        <slot name="retractor" v-bind="bindedProps" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.acordeon-switch {
  &__activator {
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>
