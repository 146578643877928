import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createElementBlock as _createElementBlock, vShow as _vShow, normalizeClass as _normalizeClass, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 3,
  class: "homepage__grid"
}
const _hoisted_2 = { class: "homepage__last-invoice" }
const _hoisted_3 = { class: "homepage__mobile-nav" }

import { computed, onBeforeMount } from "vue";

import { useBreakpoints } from "@/libs/metha-components";
import { useUserStore, useInvoicesStore } from "@/stores";

import LastInvoiceDetails from "@/components/organisms/LastInvoiceDetails.vue";
import InvoicesNotFound from "@/components/organisms/InvoicesNotFound.vue";
import InvoicesBlockedByInstallationStatus from "@/components/organisms/InvoicesBlockedByInstallationStatus.vue";

import SwitchStackedCards from "@/components/molecules/SwitchStackedCards.vue";
import MobileNavigation from "@/components/molecules/MobileNavigation.vue";
import ClientEconomy from "@/components/molecules/ClientEconomy.vue";
import SwipeSustainabilityStatistics from "@/components/molecules/SwipeSustainabilityStatistics.vue";
import SustainabilityStatistics from "@/components/molecules/SustainabilityStatistics.vue";
import WarningCommunication from "@/components/molecules/WarningCommunication.vue";

import { orderInvoices } from "@/utils/orderInvoices";
import { handleError } from "@/utils/handleError";
import { canSignContract } from "@/utils/contract";


export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {

const { lgAndUp } = useBreakpoints();
const userStore = useUserStore();
const invoicesStore = useInvoicesStore();

const infoPendingContract = computed(() => {
  return userStore.installations.some((installation) => {
    if (installation.status === "pending_agreement") return false;

    return canSignContract(installation);
  });
});

const hasInvoices = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.length >= 1;
});

const lastInvoice = computed(() => {
  const orderedInvoices = orderInvoices(
    invoicesStore.invoices,
    "reference_month"
  );

  return orderedInvoices[0] ?? {};
});

const totalKwhConsumption = computed(() => {
  const invoices = invoicesStore.invoices ?? [];

  return invoices.reduce(
    (acc, { kwh_consumption }) => acc + kwh_consumption,
    0
  );
});

onBeforeMount(async () => {
  try {
    userStore.setPageTitle(`Olá, ${userStore.name.split(" ")[0]}`);

    if (!userStore.selectedInstallation)
      throw Error("Sessão inválida. Favor fazer o login novamente");

    if (!hasInvoices.value) {
      await invoicesStore.getAllInvoices(userStore.selectedInstallation.id);
    }
  } catch (error) {
    handleError(error, userStore.setLoadingError);
  } finally {
    userStore.pageLoading = false;
  }
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("section", {
    class: _normalizeClass([{ homepage: hasInvoices.value }])
  }, [
    (infoPendingContract.value)
      ? (_openBlock(), _createBlock(WarningCommunication, {
          key: 0,
          type: "contract"
        }))
      : _createCommentVNode("", true),
    (_unref(userStore).selectedInstallation.status === 'canceled')
      ? (_openBlock(), _createBlock(InvoicesBlockedByInstallationStatus, { key: 1 }))
      : (!hasInvoices.value)
        ? (_openBlock(), _createBlock(InvoicesNotFound, { key: 2 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(LastInvoiceDetails, { "last-invoice": lastInvoice.value }, null, 8, ["last-invoice"])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(MobileNavigation)
            ]),
            _createVNode(SwitchStackedCards, null, {
              secondary: _withCtx(({ active }) => [
                (_unref(lgAndUp))
                  ? (_openBlock(), _createBlock(SustainabilityStatistics, {
                      key: 0,
                      kwhConsumption: totalKwhConsumption.value
                    }, null, 8, ["kwhConsumption"]))
                  : (_openBlock(), _createBlock(SwipeSustainabilityStatistics, {
                      key: 1,
                      kwhConsumption: totalKwhConsumption.value,
                      active: active
                    }, null, 8, ["kwhConsumption", "active"]))
              ]),
              default: _withCtx(() => [
                _createVNode(ClientEconomy, {
                  class: "homepage__client-economy",
                  totalEconomy: _unref(userStore).selectedInstallation.total_economy
                }, null, 8, ["totalEconomy"])
              ]),
              _: 1
            })
          ]))
  ], 2)), [
    [_vShow, !_unref(userStore).pageLoading]
  ])
}
}

})