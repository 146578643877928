import { usePersistentAuthStore, useWarningStore } from "@/stores";

export function isValidJwt(expireDate?: number) {
  if (!expireDate) return false;

  return Date.now() < expireDate;
}

export async function validateJwtToken() {
  const persistentAuthStore = usePersistentAuthStore();
  const warningStore = useWarningStore();

  try {
    const tokenData = persistentAuthStore.getJwt();

    const token_exp_date_in_milliseconds =
      tokenData?.token_exp_date_in_milliseconds;
    const isValidToken = isValidJwt(token_exp_date_in_milliseconds);

    if (tokenData && !isValidToken) {
      warningStore.setShowAuthenticationError(true);
      throw Error();
    }

    return !!isValidToken;
  } catch (_) {
    return false;
  }
}
