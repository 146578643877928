import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed } from "vue";
import LoginVerifyCpf from "@/components/molecules/LoginVerifyCpf.vue";
import LoginVerifyOTP from "@/components/molecules/LoginVerifyOTP.vue";
import LoginSelectInstallation from "@/components/molecules/LoginSelectInstallation.vue";
import { useLoginStore } from "@/stores";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginSteps',
  setup(__props) {

const loginStore = useLoginStore();

const component = computed(() => {
  const components = {
    "verify-cpf": LoginVerifyCpf,
    "verify-otp": LoginVerifyOTP,
    "select-installation": LoginSelectInstallation,
    default: LoginVerifyCpf,
  };

  return components[loginStore.step] || components["default"];
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(component.value)))
}
}

})