import { LocationQuery, RouteLocationNormalized } from "vue-router";
import { useAuthStore, useUserStore } from "@/stores";
import { validateJwtToken } from "@/utils/validateJwt";

export default async function auth(to: RouteLocationNormalized) {
  const authStore = useAuthStore();
  const userStore = useUserStore();

  let query = { redirect: to.fullPath } as LocationQuery;

  if (Object.keys(to.query).includes("redirect")) {
    query = to.query;
  }

  const isValidJwt = await validateJwtToken();
  const hasInstallationSelected = userStore.selectedInstallation;

  if (!authStore.has() || !isValidJwt || !hasInstallationSelected) {
    await userStore.logOutUser({ maintainLogin: true });

    return { path: "/login", query };
  }
}
