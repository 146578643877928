import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "installation-card" }

import { computed } from "vue";
import { FlatInstallation } from "@/types/InstallationTypes";

import InstallationInfo from "@/components/molecules/InstallationInfo.vue";
import SignContractActivator from "@/components/atoms/SignContractActivator.vue";
import { canSignContract } from "@/utils/contract";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationCard',
  props: {
    installation: {}
  },
  setup(__props: any) {

const props = __props;

const needContractUpdate = computed(() => {
  return canSignContract(props.installation);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(InstallationInfo, {
      status: _ctx.installation.status,
      installationNumber: _ctx.installation.installation_number,
      address: _ctx.installation.address,
      supplier: _ctx.installation.supplier
    }, null, 8, ["status", "installationNumber", "address", "supplier"]),
    (needContractUpdate.value)
      ? (_openBlock(), _createBlock(SignContractActivator, {
          key: 0,
          installation: _ctx.installation
        }, null, 8, ["installation"]))
      : _createCommentVNode("", true)
  ]))
}
}

})