import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "adc__invoices__section overdue-invoices" }



import InvoicesListTable from "@/components/molecules/InvoicesListTable.vue";
import { SimplifiedInvoice } from "@/types/InvoiceTypes";


export default /*@__PURE__*/_defineComponent({
  __name: 'OverdueInvoicesList',
  props: {
    overdueInvoices: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "overdue-invoices__title" }, "Faturas Vencidas", -1)),
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "overdue-invoices__message" }, [
      _createElementVNode("span", { class: "overdue-invoices__message__content" }, " Você possui débitos pendentes. Realize o pagamento para evitar a interrupção do serviço. ")
    ], -1)),
    _createVNode(InvoicesListTable, { invoices: _ctx.overdueInvoices }, null, 8, ["invoices"])
  ]))
}
}

})