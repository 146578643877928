import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "rate-article" }
const _hoisted_2 = { class: "rate-article__container" }
const _hoisted_3 = {
  key: 0,
  class: "rate-article__loading"
}
const _hoisted_4 = {
  key: 1,
  class: "rate-article__buttons"
}
const _hoisted_5 = {
  key: 0,
  class: "rate-article--error-message"
}

import { onBeforeMount, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";
import { Article } from "@/types/Faq";
import { useFaqStore } from "@/stores/faq";

import CaSpinner from "@/components/atoms/CaSpinner.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'RateArticle',
  props: {
    article: {}
  },
  setup(__props: any) {

const props = __props;

const faqStore = useFaqStore();

const voted = ref(false);
const voteValue = ref("");
const voteError = ref("");
const loading = ref(false);

async function vote(vote: "up" | "down") {
  if (voted.value || loading.value) return;
  voteError.value = "";
  loading.value = true;

  try {
    await faqStore.voteArticle(props.article.id, vote);
    voteValue.value = vote;
    voted.value = true;
  } catch (error) {
    voteError.value =
      "Ocorreu um erro ao registrar seu voto. Por favor vote novamente.";
    voted.value = false;
    voteValue.value = "";
  } finally {
    loading.value = false;
  }
}

onBeforeMount(() => {
  voted.value = false;
  voteValue.value = "";
  voteError.value = "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "rate-article__title" }, "Essa resposta foi útil?", -1)),
      (loading.value)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(CaSpinner, {
              size: 15,
              width: 5
            })
          ]))
        : (!voted.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("span", {
                class: "rate-article__rounded-background rate-article--success",
                style: {"padding-bottom":"2px"},
                onClick: _cache[0] || (_cache[0] = () => vote('up'))
              }, [
                _createVNode(_unref(MeIcon), null, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode("thumbs-up")
                  ])),
                  _: 1
                })
              ]),
              _createElementVNode("span", {
                class: "rate-article__rounded-background rate-article--error",
                style: {"padding-top":"2px"},
                onClick: _cache[1] || (_cache[1] = () => vote('down'))
              }, [
                _createVNode(_unref(MeIcon), null, {
                  default: _withCtx(() => _cache[3] || (_cache[3] = [
                    _createTextVNode("thumbs-down")
                  ])),
                  _: 1
                })
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass([
          `rate-article__thanks ${
            voteValue.value === 'up' ? 'rate-article--success' : 'rate-article--error'
          }`,
        ])
            }, " Obrigado pelo feedback! ", 2))
    ]),
    (voteError.value?.length > 0)
      ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(voteError.value), 1))
      : _createCommentVNode("", true)
  ]))
}
}

})