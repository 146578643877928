<script setup lang="ts">
import { computed } from "vue";
import { MeIcon, MeButton } from "@/libs/metha-components";
import { useContractStore } from "@/stores";

import Spinner from "@/components/atoms/CaSpinner.vue";
import ContractDisplay from "@/components/molecules/ContractDisplay.vue";

const contractStore = useContractStore();

const isUpdatingContract = computed(
  () => contractStore.installation?.status !== "pending_agreement"
);
</script>

<template>
  <div class="metha-contract">
    <div class="metha-contract__content" v-show="contractStore.open">
      <div
        v-show="contractStore.signatureStatus === 'loading'"
        class="metha-contract__content__container"
      >
        <img src="@/assets/gifs/loading-contract.gif" alt="" />
        <p class="metha-contract__loading-text">
          Carregando contrato.<br />Aguarde...
        </p>
      </div>
      <div
        v-show="contractStore.signatureStatus === 'signingContract'"
        class="metha-contract__content__container"
      >
        <Spinner :size="60" />
      </div>
      <div
        v-show="contractStore.signatureStatus === 'successFeedback'"
        class="metha-contract__content__container"
      >
        <div class="metha-contract__success-feedback">
          <div
            class="metha-contract__close-button"
            @click="contractStore.closeContract"
          >
            <MeIcon>x</MeIcon>
          </div>
          <img
            src="@/assets/gifs/spinner-check.gif"
            alt="Contrato assinado com sucesso"
            class="metha-contract__success-feedback__gif"
          />
          <h2 class="metha-contract__success-feedback__title">
            {{
              isUpdatingContract
                ? "Contrato atualizado com sucesso"
                : "Cadastro finalizado com sucesso"
            }}
          </h2>
          <p class="metha-contract__success-feedback__description">
            {{
              isUpdatingContract
                ? "Agora está tudo certo com as condições necessárias para continuar recebendo os serviços da Metha. Obrigado por manter suas informações atualizadas conosco!"
                : "Agora o seu cadastro está sendo analisado. Em até 5 dias úteis você vai receber o nosso retorno sobre a aprovação da sua instalação."
            }}
          </p>
          <div>
            <MeButton variant="outline" @click="contractStore.closeContract">
              Fechar
            </MeButton>
          </div>
        </div>
      </div>
      <div v-show="contractStore.signatureStatus === ''">
        <ContractDisplay />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.metha-contract {
  position: absolute;
  inset: 0;
  background: #0008;
  box-shadow: 0 0 0 10vh #0008;

  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 250px;

    background: #ffffff;
    color: #000000;
    width: 95%;
    position: relative;

    @media (min-width: 993px) {
      width: fit-content;
      min-width: 600px;
      max-width: 90%;
    }

    overflow: hidden;
    border-radius: 1.5rem;
    margin: auto;
    box-shadow: 0 4px 6px 0px rgba(0, 0, 0, 0.2),
      0 -2px 6px 1px rgba(0, 0, 0, 0.3);

    &__container {
      width: fit-content;
      max-width: 100%;
      margin: 0 auto;
      padding: 1rem;

      @media (min-width: 993px) {
        padding: 3rem;
      }
    }
  }

  &__loading-text {
    color: #000;
    text-align: center;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 700;
    line-height: 2rem;

    margin-top: 1rem;
  }

  &__close-button {
    font-size: 1.25rem;

    position: absolute;
    top: 0.5rem;
    right: 0.5rem;

    cursor: pointer;
  }

  &__success-feedback {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    gap: 0.5rem;
    margin: 0 auto;
    padding: 0 0.5rem;
    width: fit-content;
    max-width: 100%;

    @media (min-width: 993px) {
      max-width: 614px;
    }

    &__gif {
      margin: 0 auto;
      height: 80px;
      width: 80px;
    }

    &__title {
      font-weight: 700;
      font-size: 1.125rem;
      line-height: 1.5rem;

      width: 100vw;
      max-width: 324px;

      @media (min-width: 768px) {
        font-size: 1.5rem;
        line-height: 2.25rem;

        max-width: 438px;
      }
    }

    &__description {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.25rem;

      margin-bottom: 0.5rem;
    }
  }
}
</style>
