<script setup lang="ts">
import { computed } from "vue";
import LoginVerifyCpf from "@/components/molecules/LoginVerifyCpf.vue";
import LoginVerifyOTP from "@/components/molecules/LoginVerifyOTP.vue";
import LoginSelectInstallation from "@/components/molecules/LoginSelectInstallation.vue";
import { useLoginStore } from "@/stores";

const loginStore = useLoginStore();

const component = computed(() => {
  const components = {
    "verify-cpf": LoginVerifyCpf,
    "verify-otp": LoginVerifyOTP,
    "select-installation": LoginSelectInstallation,
    default: LoginVerifyCpf,
  };

  return components[loginStore.step] || components["default"];
});
</script>

<template>
  <component :is="component" />
</template>

<style lang="scss">
.login__container {
  position: relative;
  width: 100vw;
  height: 100%;
  padding: 1.5rem 1rem;

  @media (min-width: 993px) {
    padding: 2.5rem 1rem;
  }
}
</style>
