import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { onBeforeMount } from "vue";
import { useUserStore, useFaqStore } from "@/stores";

import FaqSearchBar from "@/components/organisms/FaqSearchBar.vue";
import GridFaq from "@/components/organisms/GridFaq.vue";
import ServiceChannels from "@/components/organisms/ServiceChannels.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HelpCenterPage',
  setup(__props) {

const userStore = useUserStore();
const faqStore = useFaqStore();

onBeforeMount(async () => {
  userStore.setPageTitle("Central de Ajuda");
  faqStore.clearSearchResults();
  faqStore.cleanSelectedArticle();
});

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("section", null, [
    _createVNode(FaqSearchBar),
    _createVNode(GridFaq),
    _createVNode(ServiceChannels)
  ], 512)), [
    [_vShow, !_unref(userStore).pageLoading]
  ])
}
}

})