import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "adc__invoices__section opened-article"
}
const _hoisted_2 = {
  key: 1,
  class: "adc__invoices__section opened-article"
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "opened-article__path" }
const _hoisted_6 = { class: "opened-article__go-back" }
const _hoisted_7 = {
  key: 0,
  class: "opened-article__recommendations"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "opened-article__rate-article" }

import { computed } from "vue";
import { useRouter } from "vue-router";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useFaqStore } from "@/stores/faq";
import { Article } from "@/types/Faq";

import LoadingSection from "@/components/molecules/LoadingSection.vue";
import PathIndicator from "@/components/molecules/PathIndicator.vue";
import OpenedArticleContent from "@/components/molecules/OpenedArticleContent.vue";
import RateArticle from "@/components/molecules/RateArticle.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ArticleSection',
  props: {
    article: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();
const faqStore = useFaqStore();

const relatedArticles = computed(() => {
  if (!faqStore.currentSectionArticles?.length) return [];

  return faqStore.currentSectionArticles
    .filter(({ id }) => id !== props.article.id)
    .slice(0, 3);
});

function selectTopicFromRelated(article: Article) {
  faqStore.getArticleContent(article.id);
}

function handleGoBackButton() {
  router.push("/central-de-ajuda");
}

return (_ctx: any,_cache: any) => {
  return (_ctx.loading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(LoadingSection)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        (!_ctx.article)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_unref(faqStore).gettingArticleError), 1))
          : (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(PathIndicator, {
                  items: ['Ajuda', _unref(faqStore).currentSection, _ctx.article.title]
                }, null, 8, ["items"])
              ]),
              _createElementVNode("div", null, [
                _createVNode(_unref(MeButton), {
                  variant: "outline",
                  onClick: handleGoBackButton
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("span", _hoisted_6, [
                      _createVNode(_unref(MeIcon), null, {
                        default: _withCtx(() => _cache[0] || (_cache[0] = [
                          _createTextVNode("arrow-down")
                        ])),
                        _: 1
                      })
                    ]),
                    _cache[1] || (_cache[1] = _createTextVNode(" Voltar "))
                  ]),
                  _: 1
                })
              ]),
              _createVNode(OpenedArticleContent, { article: _ctx.article }, null, 8, ["article"]),
              (relatedArticles.value.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "opened-article__recommendations__title" }, " Tópicos relacionados: ", -1)),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(relatedArticles.value, (relatedArticle, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: `opened-article-related-article-${index}`,
                        class: "opened-article__recommendations__link",
                        onClick: () => selectTopicFromRelated(relatedArticle)
                      }, _toDisplayString(relatedArticle.title), 9, _hoisted_8))
                    }), 128))
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(RateArticle, { article: _ctx.article }, null, 8, ["article"])
              ])
            ]))
      ]))
}
}

})