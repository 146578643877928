import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";
import { validPersistentJwt } from "@/types/Jwt";

const initialPersistentAuthState = () => ({
  jwt: null as validPersistentJwt | null,
  document: "",
  lastSessionInstallation: "",
});

export const usePersistentAuthStore = defineStore("persistentAuth", {
  state: () =>
    useLocalStorage(
      "@metha-energia/persist-session",
      initialPersistentAuthState()
    ),
  actions: {
    getStoreFromMobile(payload: any) {
      Object.assign(this, payload);
    },
    async clear() {
      Object.assign(this, initialPersistentAuthState());
    },
    logOut() {
      Object.assign(this, {
        ...initialPersistentAuthState(),
        lastSessionInstallation: this.lastSessionInstallation,
      });
    },
    getJwt() {
      return this.jwt;
    },
    setJwt(jwtData: validPersistentJwt) {
      this.jwt = jwtData;
    },
    getDocument() {
      return this.document;
    },
    setDocument(document: string) {
      this.document = document;
    },
    getLastSessionInstallationId() {
      return this.lastSessionInstallation;
    },
    setLastSessionInstallationId(installationId: string) {
      this.lastSessionInstallation = installationId;
    },
  },
});
