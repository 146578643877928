<script setup lang="ts">
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MeIcon } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import NavLinks from "@/components/molecules/NavLinks.vue";

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const userFirstName = computed(() => {
  return userStore.name.split(" ")[0];
});

const isSubpage = computed(() => {
  return !!route.path.match(/\/.*\/.*/);
});

const icon = computed(() => {
  return isSubpage.value ? "arrow-back" : "nm-menu";
});

async function logOut() {
  await userStore.logOutUser();
  router.push("/login");
}

function handleClick() {
  if (isSubpage.value) {
    router.back();
  } else {
    userStore.isSidebarOpen = true;
  }
}
</script>

<template>
  <div>
    <!-- Ativador da sidebar // Botão de voltar -->
    <div
      @click="handleClick"
      :class="[
        'mobile-action-button',
        { 'mobile-action-button--small': isSubpage },
      ]"
    >
      <MeIcon>{{ icon }}</MeIcon>
    </div>
    <!-- Pelicula preta para a sidebar -->
    <div
      v-if="userStore.isSidebarOpen"
      @click="userStore.switchSidebarMenu"
      class="sidebar-mobile__dark-background"
    ></div>
    <!-- Sidebar -->
    <div
      :class="[
        'sidebar-mobile',
        { 'sidebar-mobile--active': userStore.isSidebarOpen },
      ]"
    >
      <div
        :class="[
          'sidebar-mobile__nav',
          { 'sidebar-mobile__nav--is-open': userStore.isSidebarOpen },
        ]"
      >
        <div class="sidebar-mobile__tabs">
          <div class="sidebar-mobile__tabs__user">
            <div class="sidebar-mobile__tabs__user__icon">
              <img
                src="@/assets/brand/logo-icon.svg"
                alt="Metha energia logo"
              />
            </div>
            <h2 class="sidebar-mobile__tabs__user__name">
              {{ userFirstName }}
            </h2>
          </div>
          <NavLinks />
        </div>
        <div class="sidebar-mobile__nav__log-out">
          <div class="sidebar-mobile__nav__log-out__line"></div>
          <span class="sidebar-mobile__nav__log-out__text" @click="logOut">
            <span class="sidebar-mobile__nav__log-out__icon">
              <MeIcon>nm-logout</MeIcon>
            </span>
            Sair
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.mobile-action-button {
  font-size: 1.125rem;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 4px;

  &--small {
    font-size: 1.125rem;
  }
}

.sidebar-mobile {
  position: relative;
  z-index: -1;

  &--active {
    z-index: 10;
  }

  &__dark-background {
    position: absolute;
    z-index: 9;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
  }

  &__tabs {
    width: 100%;

    &__user {
      display: flex;
      align-items: center;
      gap: 16px;

      border-bottom: 1px solid #ccc;

      padding-bottom: 18px;
      margin-bottom: 26px;

      &__icon {
        display: flex;
        width: 32px;
        height: 32px;
      }

      &__name {
        font-size: 1.3125rem;
        font-weight: 700;
        line-height: 1.5rem;
        text-transform: capitalize;
      }
    }
  }

  &__nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 95%;
    max-width: 288px;
    height: 100vh;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 10px;
    padding-top: 8px;
    width: 100%;
    z-index: 4;

    margin-left: -288px;

    padding: 26px 21px;

    transition: 0.25s ease-in-out;

    &--is-open {
      margin-left: 0;
    }

    &__log-out {
      color: #000;
      width: 100%;
      padding-left: 2px;

      &__line {
        width: 100%;
        height: 1px;
        background-color: #ccc;
      }

      &__text {
        display: flex;
        align-items: flex-end;
        gap: 16px;
        margin-top: 12px;

        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      &__icon {
        font-size: 24px;
      }
    }
  }
}
</style>
