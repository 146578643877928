import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sidebar" }
const _hoisted_2 = { class: "sidebar__need-help" }
const _hoisted_3 = { class: "sidebar__need-help__container" }
const _hoisted_4 = { class: "sidebar__need-help__icon" }
const _hoisted_5 = { class: "sidebar__need-help__button-container" }

import { useRoute, useRouter } from "vue-router";
import { MeIcon, MeButton } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import NavLinks from "@/components/molecules/NavLinks.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutSidebar',
  setup(__props) {

const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

function redirectToHelpCenterWithOpenChat() {
  if (route.path.includes("central-de-ajuda")) {
    userStore.openSunshineChat();
  } else {
    router.push({ path: "central-de-ajuda", query: { chat: "open" } });
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(NavLinks),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_unref(MeIcon), null, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createTextVNode("nm-chat")
            ])),
            _: 1
          })
        ]),
        _cache[1] || (_cache[1] = _createTextVNode(" Precisa de ajuda? "))
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_unref(MeButton), {
          onClick: redirectToHelpCenterWithOpenChat,
          block: ""
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Fale com a gente ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})