import { emitNotification, hasAppWrapper } from "@/utils/appComunication";

export default async function downloadInvoice(url: string, filename: string) {
  if (!url) return;

  if (hasAppWrapper()) {
    emitNotification("openInvoice", { url, filename });
  } else {
    const element = document.createElement("a");
    element.setAttribute("href", url);
    element.setAttribute("target", "__blank");
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  }
}
