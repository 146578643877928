<script setup lang="ts">
import { onBeforeMount } from "vue";

import { useRoute } from "vue-router";
import { useUserStore, useFaqStore } from "@/stores";

import FaqSearchBar from "@/components/organisms/FaqSearchBar.vue";
import ArticleSection from "@/components/organisms/ArticleSection";
import ServiceChannels from "@/components/organisms/ServiceChannels.vue";

const route = useRoute();

const userStore = useUserStore();
const faqStore = useFaqStore();

onBeforeMount(async () => {
  const id =
    typeof route.params.id === "string"
      ? +route.params.id
      : +route.params.id[0];

  await faqStore.getArticleContent(id);
});
</script>

<template>
  <section v-show="!userStore.pageLoading">
    <FaqSearchBar />
    <ArticleSection
      :loading="faqStore.loadingArticle"
      :article="faqStore.currentArticle"
    />
    <ServiceChannels />
  </section>
</template>
