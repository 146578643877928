import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/brand/logo-icon.svg'


const _hoisted_1 = { class: "sidebar-mobile__tabs" }
const _hoisted_2 = { class: "sidebar-mobile__tabs__user" }
const _hoisted_3 = { class: "sidebar-mobile__tabs__user__name" }
const _hoisted_4 = { class: "sidebar-mobile__nav__log-out" }
const _hoisted_5 = { class: "sidebar-mobile__nav__log-out__icon" }

import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MeIcon } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import NavLinks from "@/components/molecules/NavLinks.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MobileActionButton',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();

const userFirstName = computed(() => {
  return userStore.name.split(" ")[0];
});

const isSubpage = computed(() => {
  return !!route.path.match(/\/.*\/.*/);
});

const icon = computed(() => {
  return isSubpage.value ? "arrow-back" : "nm-menu";
});

async function logOut() {
  await userStore.logOutUser();
  router.push("/login");
}

function handleClick() {
  if (isSubpage.value) {
    router.back();
  } else {
    userStore.isSidebarOpen = true;
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: handleClick,
      class: _normalizeClass([
        'mobile-action-button',
        { 'mobile-action-button--small': isSubpage.value },
      ])
    }, [
      _createVNode(_unref(MeIcon), null, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(icon.value), 1)
        ]),
        _: 1
      })
    ], 2),
    (_unref(userStore).isSidebarOpen)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(userStore).switchSidebarMenu && _unref(userStore).switchSidebarMenu(...args))),
          class: "sidebar-mobile__dark-background"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", {
      class: _normalizeClass([
        'sidebar-mobile',
        { 'sidebar-mobile--active': _unref(userStore).isSidebarOpen },
      ])
    }, [
      _createElementVNode("div", {
        class: _normalizeClass([
          'sidebar-mobile__nav',
          { 'sidebar-mobile__nav--is-open': _unref(userStore).isSidebarOpen },
        ])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "sidebar-mobile__tabs__user__icon" }, [
              _createElementVNode("img", {
                src: _imports_0,
                alt: "Metha energia logo"
              })
            ], -1)),
            _createElementVNode("h2", _hoisted_3, _toDisplayString(userFirstName.value), 1)
          ]),
          _createVNode(NavLinks)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _cache[4] || (_cache[4] = _createElementVNode("div", { class: "sidebar-mobile__nav__log-out__line" }, null, -1)),
          _createElementVNode("span", {
            class: "sidebar-mobile__nav__log-out__text",
            onClick: logOut
          }, [
            _createElementVNode("span", _hoisted_5, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("nm-logout")
                ])),
                _: 1
              })
            ]),
            _cache[3] || (_cache[3] = _createTextVNode(" Sair "))
          ])
        ])
      ], 2)
    ], 2)
  ]))
}
}

})