<script setup lang="ts">
import { computed, defineProps } from "vue";
import { FlatInstallation } from "@/types/InstallationTypes";

import InstallationInfo from "@/components/molecules/InstallationInfo.vue";
import SignContractActivator from "@/components/atoms/SignContractActivator.vue";
import { canSignContract } from "@/utils/contract";

const props = defineProps<{
  installation: FlatInstallation;
}>();

const needContractUpdate = computed(() => {
  return canSignContract(props.installation);
});
</script>

<template>
  <div class="installation-card">
    <InstallationInfo
      :status="installation.status"
      :installationNumber="installation.installation_number"
      :address="installation.address"
      :supplier="installation.supplier"
    />
    <SignContractActivator
      v-if="needContractUpdate"
      :installation="installation"
    />
  </div>
</template>

<style lang="scss" scoped>
.installation-card {
  background: #fff;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0px 2px 2px 0px #2125290f;

  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
</style>
