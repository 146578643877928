import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import { toast } from "vue3-toastify";

import api from "@/api";

import { useAuthStore, useUserStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";
import {
  ContractContent,
  ContractPfRequestVariables,
  ContractPjRequestVariables,
} from "@/types/Contract";

import { getSupplierContractVariables } from "@/utils/supplierContractVariables";
import { handleError } from "@/utils/handleError";
import { maskCnpj, maskCpf } from "@/utils/mask";
import { capitalize } from "@/utils/format";

const initialContractState = () => ({
  installation: null as FlatInstallation | null,
  open: false,
  signatureStatus: "loading",
  content: {
    adesaoPdf: undefined as ArrayBufferLike | undefined,
    comercialPdf: undefined as ArrayBufferLike | undefined,
  },
  contractCreationTime: 0 as Date | number,
});

export const useContractStore = defineStore("contract", {
  state: () =>
    useSessionStorage("@metha-energia/adc-contract", initialContractState()),
  actions: {
    async clear() {
      Object.assign(this, initialContractState());
    },
    async openContract(installation: FlatInstallation) {
      this.signatureStatus = "loading";

      Object.assign(this, {
        open: true,
        loading: true,
        installation,
      });

      try {
        await this.preRenderContract();

        const content = await this.loadContractContent();

        const dateTimeNow = new Date().getTime();
        this.setContractCreationTime(dateTimeNow);

        if (!content) {
          throw new Error(
            "Não foi possível reconhecer a instalação selecionada. Clique em sair, entre na sua conta e tente novamente por favor"
          );
        }

        if (!(content.adesaoPdf && content.comercialPdf)) {
          throw new Error(
            "Não foi possível carregar o conteúdo do contrato. Tente novamente por favor"
          );
        }

        this.content = content;
      } catch (err) {
        Object.assign(this, {
          open: false,
          installation: null,
        });
        handleError(err, (error: string) => {
          toast.error(error, { position: toast.POSITION.BOTTOM_CENTER });
        });
      } finally {
        this.signatureStatus = "";
      }
    },
    closeContract() {
      this.clear();
    },
    setContractCreationTime(dateTime: Date | number) {
      this.contractCreationTime = dateTime;
    },
    async preRenderContract() {
      if (!this.installation) return;

      const authStore = useAuthStore();

      const installation = this.installation;

      const installation_id = installation.id;

      const userData = this.getUserContractVariables();
      const supplierData = getSupplierContractVariables(installation.supplier);

      if (!userData || !supplierData) throw new Error();

      const token = await authStore.getToken("preRenderContract");

      return api.contract.preRenderContract(
        {
          ...supplierData,
          ...userData,
          supplier: installation.supplier,
          juridicPerson: !!installation.legal_representative,
          bucket: process.env.VUE_APP_CONTRACTS_BUCKET,
        },
        { "g-recaptcha-response": token },
        { installation_id }
      );
    },
    async loadContractContent(): Promise<ContractContent | undefined> {
      if (!this.installation) return undefined;

      const comercialUrl = `/unsigned/${this.installation.id}_comercializacao.pdf`;
      const adesaoUrl = `/unsigned/${this.installation.id}_adesao.pdf`;

      let content = {
        adesaoPdf: undefined,
        comercialPdf: undefined,
      } as ContractContent;
      let counter = 0;

      while (counter < 7 || !content.adesaoPdf) {
        try {
          const [adesaoPdf, comercialPdf] = await Promise.all([
            api.aws.getFile<ArrayBufferLike>(adesaoUrl),
            api.aws.getFile<ArrayBufferLike>(comercialUrl),
          ]);

          if (adesaoPdf && comercialPdf) {
            content = { adesaoPdf: adesaoPdf, comercialPdf };
            break;
          }
        } catch (err) {
          if (counter === 6) break;

          await new Promise((resolve) => {
            setTimeout(() => {
              resolve(null);
            }, 4000);
          });
          counter += 1;
          continue;
        }
      }

      return content;
    },
    async downloadTerms(term: "adesao" | "comercializacao") {
      if (!this.installation) return;

      const contractBaseUrl = process.env["VUE_APP_CONTRACTS_BASE_URL"];

      const dataUrl = `${contractBaseUrl}/unsigned/${this.installation.id}_${term}.pdf`;
      const text = term === "adesao" ? "Adesão" : "Comercialização";
      const fileName = `Metha Energia Termo ${text}.pdf`;

      const downloadLink = document.createElement("a");
      downloadLink.href = dataUrl;
      downloadLink.target = "_blank";
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      URL.revokeObjectURL(dataUrl);
    },
    async sendContractSignature(recaptchaToken: string) {
      try {
        if (!this.installation) return new Error();

        this.signatureStatus = "signingContract";

        const installation_id = this.installation.id;
        if (!installation_id) {
          toast.error(
            "A instalação selecionada não possui um ID, tente fazer o acesso novamente por outra guia ou outro navegador por favor.",
            { position: toast.POSITION.BOTTOM_CENTER }
          );
          this.closeContract();
          return;
        }

        const userData = this.getUserContractVariables();
        const supplierData = getSupplierContractVariables(
          this.installation.supplier
        );

        if (!userData || !supplierData) throw new Error();

        await api.contract.sendContractSignature(
          installation_id,
          {
            createdOn: this.contractCreationTime,
            agreedOn: new Date().getTime(),
            data: {
              ...supplierData,
              ...userData,
              juridicPerson: !!this.installation.legal_representative,
            },
          },
          {
            "g-recaptcha-response": recaptchaToken,
          }
        );

        this.updateInstallationStatus(this.installation);

        this.signatureStatus = "successFeedback";
      } catch (error) {
        handleError(
          error,
          (errorMessage: string) => {
            toast.error(errorMessage, {
              position: toast.POSITION.BOTTOM_CENTER,
            });
          },
          {
            default:
              "Ocorreu um erro ao tentar registrar a assinatura do contrato. Tente novamente mais tarde por favor.",
          }
        );
        this.closeContract();
      }
    },
    updateInstallationStatus(installation: FlatInstallation) {
      const userStore = useUserStore();

      userStore.installations = userStore.installations.map(
        (actualInstallation) => {
          if (actualInstallation.id === installation.id) {
            return {
              ...actualInstallation,
              status:
                installation.status === "pending_agreement"
                  ? "under_analysis"
                  : installation.status,
              pendency_contract_update: false,
            };
          }
          return actualInstallation;
        }
      );
    },
    getUserContractVariables():
      | ContractPfRequestVariables
      | ContractPjRequestVariables
      | undefined {
      if (!this.installation) return;

      const userStore = useUserStore();

      const installation = this.installation;

      const cpf = maskCpf(userStore.cpf.replace(/\D/gm, ""));
      const name = capitalize(userStore.name);
      const email = Array.isArray(userStore.email)
        ? userStore.email[0]
        : userStore.email;
      const phone = Array.isArray(userStore.phone)
        ? userStore.phone[0]
        : userStore.phone;
      const address = capitalize(installation.address);
      const installation_number = installation.installation_number;
      const bucket = process.env.VUE_APP_CONTRACTS_BUCKET;
      const supplier = this.installation.supplier;

      if (!!installation.legal_representative && !!installation.company) {
        const corporateName = capitalize(
          installation.company.official_name ?? ""
        );
        const cnpj = maskCnpj(installation.legal_representative.cnpj);
        const representantName = capitalize(
          installation.legal_representative.name
        );
        const representantCpf = maskCpf(installation.legal_representative.cpf);
        const representantEmail = installation.legal_representative.email;

        return {
          phone,
          bucket,
          supplier,
          "Razão social cliente": corporateName,
          "CNPJ cliente": cnpj,
          "Endereço cliente": address,
          "Nome representante": representantName,
          "CPF representante": representantCpf,
          "Email representante": representantEmail,
          "Nome administrador": name,
          "CPF administrador": cpf,
          "Email administrador": email,
          "Número da Instalação": installation_number,
        } as ContractPjRequestVariables;
      }

      return {
        phone,
        bucket,
        supplier,
        CPF: cpf,
        Endereço: address,
        Email: email,
        "Nome cliente": name,
        "Número da Instalação": installation_number,
      } as ContractPfRequestVariables;
    },
  },
});
