<script setup lang="ts">
import { useAuthStore } from "@/stores";
import { ref, type Ref } from "vue";
import { VueRecaptcha } from "vue-recaptcha";

const RECAPTCHA_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

export interface CaptchaProvider {
  loaded: Ref<boolean>;
  getToken(action: string): Promise<string>;
}

const authStore = useAuthStore();

const loaded = ref(false);
const recaptcha = ref<InstanceType<typeof VueRecaptcha>>();

let resolveToken = (token: string) => console.log(token);
let rejectToken = (err: unknown): void => {
  console.log(err);
};

function onCaptchaVerify(token: string) {
  resolveToken(token);
}

function onCaptchaError(err: unknown) {
  rejectToken(err);
}

let lastActions: { [k: string]: number } = {};

function getToken(action: string): Promise<string> {
  let tries = 0;

  if (Object.keys(lastActions).includes(action)) {
    lastActions[action] += 1;
    tries = lastActions[action];
  } else {
    lastActions = {
      ...lastActions,
      [action]: tries,
    };
  }

  action = `${action}${tries}`;

  return new Promise((resolve, reject) => {
    resolveToken = resolve;
    rejectToken = reject;
    recaptcha.value?.execute(action);
  });
}

async function isLoaded() {
  loaded.value = true;

  authStore.setGetRecaptchaTokenFunction(async (action: string) => {
    let actualToken = await getToken(action);
    actualToken = actualToken as string;
    return actualToken ?? "";
  });
}
</script>

<template>
  <slot v-if="loaded"></slot>
  <VueRecaptcha
    ref="recaptcha"
    class="captcha"
    :sitekey="RECAPTCHA_KEY"
    size="invisible"
    badge="bottomright"
    @render="isLoaded"
    @verify="onCaptchaVerify"
    @error="onCaptchaError"
  />
</template>

<style lang="scss">
.grecaptcha-badge {
  visibility: hidden;
}
</style>
