import { defineComponent as _defineComponent } from 'vue'
import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "acordeon-switch" }
const _hoisted_2 = { class: "acordeon-switch__activator" }

import { computed, ref } from "vue";

import { useBreakpoints } from "@/libs/metha-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AcordeonSwitch',
  setup(__props) {

const { lgAndUp } = useBreakpoints();

const isOpen = ref(false);

const isAcordeonOpen = computed(() => {
  if (lgAndUp.value) return true;

  return isOpen.value;
});

const bindedProps = computed(() => {
  return {
    isOpen: isAcordeonOpen.value,
    handleClick,
  };
});

function handleClick() {
  isOpen.value = !isOpen.value;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default", _normalizeProps(_guardReactiveProps(bindedProps.value)))
    ], 512), [
      [_vShow, !isAcordeonOpen.value]
    ]),
    _withDirectives(_createElementVNode("div", null, [
      _renderSlot(_ctx.$slots, "content", _normalizeProps(_guardReactiveProps(bindedProps.value))),
      _withDirectives(_createElementVNode("div", null, [
        _renderSlot(_ctx.$slots, "retractor", _normalizeProps(_guardReactiveProps(bindedProps.value)))
      ], 512), [
        [_vShow, !_unref(lgAndUp)]
      ])
    ], 512), [
      [_vShow, isAcordeonOpen.value]
    ])
  ]))
}
}

})