<script setup lang="ts">
import { computed, defineProps } from "vue";
import { useRouter } from "vue-router";
import { MeButton } from "@/libs/metha-components";

import type { Invoice } from "@/types/InvoiceTypes";
import { formatDate, formatMoney } from "@/utils/format";

import PaymentMethodsModalWithActivator from "@/components/organisms/PaymentMethodsModalWithActivator.vue";

import ConsumptionGraph from "@/components/molecules/ConsumptionGraph.vue";

import StatusTag from "@/components/atoms/StatusTag.vue";

const props = defineProps<{
  lastInvoice: Invoice;
}>();

const router = useRouter();

const isPaid = computed(() => {
  return props.lastInvoice.status === "paid";
});

function redirectToInvoiceDetails() {
  router.push(`/faturas/${props.lastInvoice.id}`);
}
</script>

<template>
  <div class="last-invoice-details">
    <div class="last-invoice-details__header">
      <h3 class="last-invoice-details__header__title">Fatura mais recente</h3>
      <StatusTag
        :status="lastInvoice.status"
        invoice
        class="last-invoice-details__status"
      />
    </div>
    <div class="last-invoice-details__date-container">
      <div class="last-invoice-details__date-container__reference-month">
        <span class="last-invoice-details__date-container__text">
          Referente a
        </span>
        {{ lastInvoice.reference_month }}
      </div>
      <div class="last-invoice-details__date-container__due-date">
        <span class="last-invoice-details__date-container__text">
          Vencimento
        </span>
        {{ formatDate(lastInvoice.due_date) }}
      </div>
    </div>
    <div class="last-invoice-details__middle-section__item">
      <ConsumptionGraph
        :total="lastInvoice.kwh_consumption"
        :active="lastInvoice.kwh_injected"
        :discount="lastInvoice.discount_percentage"
        mainColor="#FFCC12"
        secondaryColor="#E3E5E4"
      />
    </div>
    <div class="last-invoice-details__bottom-section">
      <div class="last-invoice-details__values-container">
        <div class="last-invoice-details__values-container__total-price">
          <span
            class="last-invoice-details__values-container__total-price__text"
          >
            Total a pagar
          </span>
          {{ formatMoney(lastInvoice.total_value) }}
        </div>
      </div>
      <div class="last-invoice-details__button-container">
        <div
          v-if="!isPaid"
          class="last-invoice-details__button-container__payments"
        >
          <PaymentMethodsModalWithActivator :invoice="lastInvoice" />
        </div>
        <div>
          <MeButton block @click="redirectToInvoiceDetails">
            Detalhes da Fatura
          </MeButton>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.last-invoice-details {
  margin-top: 0;
  background: #fff;
  border-radius: 1rem;
  padding: 1.5rem 1rem;

  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 993px) {
    padding: 1.5rem;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__title {
      color: #6c757d;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 1.5rem;

      @media (min-width: 993px) {
        font-size: 1.125rem;
      }
    }
  }

  &__middle-section {
    display: flex;
    flex-direction: column-reverse;
    gap: 1.5rem;
    padding-bottom: 1rem;

    @media (min-width: 993px) {
      flex-direction: row;
      align-items: stretch;
      justify-items: space-between;
      padding-bottom: 1.5rem;
    }

    &__item {
      width: 100%;
      flex-grow: 1;
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: 993px) {
      flex-direction: row;
    }
  }

  &__status {
    width: fit-content;
  }

  &__date-container {
    color: #111;
    font-size: 1.375rem;
    font-weight: 700;
    line-height: 2.25rem;

    @media (min-width: 993px) {
      line-height: 2.25rem;
    }

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__text {
      color: #7a7a7a;
      letter-spacing: 0.4px;

      font-size: 1rem;
      font-weight: 500;
      line-height: 1.25rem;

      @media (min-width: 993px) {
        letter-spacing: unset;
      }
    }

    &__reference-month {
      text-align: left;
    }

    &__due-date {
      text-align: right;
    }

    &__reference-month,
    &__due-date {
      display: flex;
      flex-direction: column;
      gap: 0.125rem;
    }
  }

  &__chart-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    @media (min-width: 993px) {
      padding: 0 1.25rem;
    }
  }

  &__values-container {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;

    @media (min-width: 993px) {
      flex-direction: row;
      justify-content: space-between;
      max-width: unset;
    }

    &__total-price {
      display: flex;
      flex-direction: column;

      text-align: center;
      color: #000;
      font-size: 2.25rem;
      font-weight: 700;
      line-height: 2.5rem;

      @media (min-width: 993px) {
        text-align: left;
        line-height: 3.125rem;
      }

      &__text {
        color: #7a7a7a;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }
    }
  }

  &__bottom-section {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: 993px) {
      flex-direction: row;
      align-items: center;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @media (min-width: 993px) {
      gap: 1rem;
    }

    &__payments {
    }
  }
}
</style>
