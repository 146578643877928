import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }


import { MeButton } from "@/libs/metha-components";
import { Invoice } from "@/types/InvoiceTypes";
import downloadInvoice from "@/utils/downloadInvoice";


export default /*@__PURE__*/_defineComponent({
  __name: 'DownloadInvoiceButton',
  props: {
    invoice: {},
    disabled: { type: Boolean },
    url: {}
  },
  setup(__props: any) {

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_ctx.invoice.s3_url)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(MeButton), {
          onClick: _cache[0] || (_cache[0] = 
        () =>
          _unref(downloadInvoice)(
            props.invoice.s3_url,
            `Fatura Metha ${props.invoice.reference_month} - ${props.invoice.installation_number}`
          )
      ),
          block: ""
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Abrir PDF da fatura ")
          ])),
          _: 1
        })
      ]))
    : _createCommentVNode("", true)
}
}

})