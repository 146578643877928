<script setup lang="ts">
import { defineProps, computed } from "vue";
import { MeButton } from "@/libs/metha-components";

import { FlatInstallation } from "@/types/InstallationTypes";
import { useContractStore } from "@/stores";

const props = defineProps<{
  installation: FlatInstallation;
}>();

const contractStore = useContractStore();

const warningText = computed(() => {
  if (props.installation.status === "pending_agreement") {
    return "Você precisa assinar o contrato para concluir o cadastro desta instalação.";
  }

  return "Atualizamos nossos termos de adesão e comercialização. Você precisa assinar o contrato para continuar recebendo nossos serviços.";
});

function openContract() {
  contractStore.openContract(props.installation);
}
</script>

<template>
  <div class="sign-contract-activator">
    <div
      class="sign-contract-activator__status"
      v-if="props.installation.pendency_contract_update"
    >
      <div class="sign-contract-activator__status__ball"></div>
      <p class="sign-contract-activator__status__text">Contrato pendente</p>
    </div>
    <p class="sign-contract-activator__warning-text">
      <b>Atenção!</b>
      {{ warningText }}
    </p>
    <div class="sign-contract-activator__button">
      <MeButton @click="openContract">Ver e assinar contrato</MeButton>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sign-contract-activator {
  background-color: #e9ecef;
  padding: 1rem 1.5rem;
  border-radius: 0.5rem;

  display: flex;
  flex-direction: column;
  gap: 0.625rem;

  &__status {
    --status-color: #da4040;

    display: flex;
    align-items: center;
    gap: 0.5rem;

    &__ball {
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
      background-color: var(--status-color);
    }

    &__text {
      color: var(--status-color);
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 700;
      line-height: 1.25rem;
    }
  }

  &__warning-text {
    font-size: 1.125rem;
    line-height: 1.6875rem;
  }

  &__button {
  }
}
</style>
