import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";

import { Invoice, SimplifiedInvoice } from "@/types/InvoiceTypes";

import api from "@/api";
import { useAuthStore } from "./auth";

const initialInvoicesState = () => ({
  invoices: [] as SimplifiedInvoice[],
  selectedInvoice: {} as Invoice,
});

export const useInvoicesStore = defineStore("invoices", {
  state: () =>
    useSessionStorage("@metha-energia/adc-invoices", initialInvoicesState()),
  actions: {
    async clear() {
      Object.assign(this, initialInvoicesState());
    },
    setInvoices(invoices: SimplifiedInvoice[]) {
      this.invoices = invoices;
    },
    setSelectedInvoice(invoice: Invoice) {
      this.selectedInvoice = invoice;
    },
    async getAllInvoices(parent_id: string | number) {
      if (!parent_id) return;

      const authStore = useAuthStore();
      const token = await authStore.getToken("invoicesStoreGetAllInvoices");

      const { data } = await api.invoice.getAllInvoices(
        parent_id,
        {},
        {
          headers: {
            "g-recaptcha-response": token,
          },
        }
      );

      this.setInvoices(data.invoices);
    },
    async getInvoiceById(id: string) {
      const authStore = useAuthStore();
      const token = await authStore.getToken("invoicesStoreGetInvoiceById");

      const { data } = await api.invoice.getInvoiceById(
        id,
        {},
        { headers: { "g-recaptcha-response": token } }
      );

      this.setSelectedInvoice(data[0]);
    },
    getInvoiceByReferenceMonth(referenceMonth: string) {
      if (referenceMonth === this.selectedInvoice.reference_month) {
        return;
      }

      const invoice = this.invoices.find(
        (invoice) => invoice.reference_month === referenceMonth
      );

      return invoice;
    },
  },
});
