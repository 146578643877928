<script setup lang="ts">
import { ref } from "vue";
import { useBreakpoints } from "@/libs/metha-components";

const { lgAndUp } = useBreakpoints();

const alternated = ref(false);

function alternateDisplayedCard(eventCaller: "default" | "secondary") {
  const calledByDefault = eventCaller === "default";

  if (
    (calledByDefault && !alternated.value) ||
    (!calledByDefault && alternated.value)
  )
    return;

  alternated.value = !alternated.value;
}
</script>

<template>
  <template v-if="lgAndUp">
    <slot name="default"></slot>
    <slot name="secondary"></slot>
  </template>
  <div class="switch-stacked-cards" v-else>
    <div
      @click="() => alternateDisplayedCard('default')"
      :class="`switch-stacked-cards__card switch-stacked-cards__card--${
        alternated ? 'inactive' : 'active'
      }`"
    >
      <slot name="default"></slot>
    </div>
    <div
      @click="() => alternateDisplayedCard('secondary')"
      :class="`switch-stacked-cards__card switch-stacked-cards__card--${
        alternated ? 'active' : 'inactive'
      }`"
    >
      <slot name="secondary" v-bind="{ active: alternated }"></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.switch-stacked-cards {
  width: 100%;
  max-width: 450px;
  margin: 60px auto 0;

  position: relative;

  &__card {
    position: relative;
    height: 100%;
    min-height: 190px;

    &--active {
      z-index: 4;
    }

    &--inactive {
      position: absolute;
      top: -60px;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: 1;
    }
  }
}
</style>
