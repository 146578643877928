<script setup lang="ts">
import { defineProps } from "vue";
import { useRouter } from "vue-router";

import { MeButton, MeIcon } from "@/libs/metha-components";

import type { SimplifiedInvoice } from "@/types/InvoiceTypes";
import { formatDate, formatMoney, capitalize } from "@/utils/format";

import StatusTag from "@/components/atoms/StatusTag.vue";

defineProps<{
  invoice: SimplifiedInvoice;
}>();

const router = useRouter();

function redirectToDetailsPage(id: string) {
  router.push(`faturas/${id}`);
  return;
}
</script>

<template>
  <div class="invoices-list-row">
    <p class="invoices-list-row__reference-month">
      {{ capitalize(invoice.reference_month) }}
    </p>
    <p class="invoices-list-row--smaller invoices-list-row--only-desktop">
      {{ formatDate(invoice.due_date) }}
    </p>
    <p><StatusTag :status="invoice.status" invoice /></p>
    <p>{{ formatMoney(invoice.total_value) }}</p>
    <p class="invoices-list-row--only-desktop">
      <MeButton
        @click="() => redirectToDetailsPage(invoice.id)"
        variant="outline"
        color="foreground"
      >
        Ver detalhes
      </MeButton>
    </p>
    <div class="invoices-list-row__button invoices-list-row--only-mobile">
      <MeIcon width="20" height="20">chevron-right</MeIcon>
    </div>
    <div
      @click="() => redirectToDetailsPage(invoice.id)"
      class="invoices-list-row__mobile-link"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.invoices-list-row {
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  gap: 10px;

  color: #000;
  font-weight: 700;
  line-height: 1.5rem;

  font-size: 1rem;
  cursor: pointer;
  grid-template-columns: 1fr 1fr 1fr 20px;

  padding: 1rem 1rem 1rem 0.75rem;
  border: 1px solid #afafaf;
  border-radius: 1rem;

  @media (min-width: 993px) {
    border: none;
    border-radius: 0;
    padding: 0;
    font-size: 1.125rem;
    cursor: unset;
    grid-template-columns: repeat(5, 1fr);
  }

  p {
    width: fit-content;
    text-transform: capitalize;
  }

  &__reference-month {
    font-weight: 400;

    @media (min-width: 993px) {
      font-weight: 700;
    }
  }

  &__color-tag {
    width: 4px;
    height: 100%;
    border: none;
    border-radius: 100px;
  }

  &__button {
    font-size: 1.25rem;
    display: flex;
    justify-content: center;
  }

  &__mobile-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;

    @media (min-width: 993px) {
      display: none;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;

    @media (min-width: 993px) {
      padding-bottom: 20px;
      border-bottom: 1px solid #ced4da;

      .invoices-list-row__mobile-link {
        height: calc(100% - 20px);
      }
    }
  }

  &--smaller {
    font-size: 1rem;
  }

  &--only-desktop {
    display: none;

    @media (min-width: 993px) {
      display: unset;
    }
  }

  &--only-mobile {
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>
