import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "copy-container" }

import { onBeforeUnmount, ref } from "vue";
import useClipboard from "vue-clipboard3";


export default /*@__PURE__*/_defineComponent({
  __name: 'CopyContainer',
  props: {
  valueToCopy: {
    type: [String, Number],
    required: true,
  },
  successMessage: {
    type: String,
    default: "Copiado com sucesso!",
  },
  timeToReturnToBaseModel: {
    type: Number,
    default: 1000,
  },
  disabled: Boolean,
},
  setup(__props) {

const props = __props;

const { toClipboard } = useClipboard();

const copied = ref(false);
const timeout = ref<ReturnType<typeof setTimeout> | null>(null);

function returnToBaseModel() {
  try {
    const waitingTime = 3 * props.timeToReturnToBaseModel;

    timeout.value = setTimeout(() => {
      copied.value = false;
    }, waitingTime);
  } catch (error) {
    copied.value = false;
  }
}

async function copyToClipboard() {
  if (copied.value || props.disabled) return;

  try {
    await toClipboard(String(props.valueToCopy));
    copied.value = true;
    returnToBaseModel();
  } catch (e) {
    console.error(e);
    copied.value = false;
  }
}

onBeforeUnmount(() => {
  if (timeout.value) {
    clearTimeout(timeout.value);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([
        'copy-container__success',
        { 'copy-container__success--show': copied.value },
      ])
    }, _toDisplayString(__props.successMessage), 3),
    _createElementVNode("div", { onClick: copyToClipboard }, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}
}

})