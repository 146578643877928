import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "sign-contract-activator" }
const _hoisted_2 = {
  key: 0,
  class: "sign-contract-activator__status"
}
const _hoisted_3 = { class: "sign-contract-activator__warning-text" }
const _hoisted_4 = { class: "sign-contract-activator__button" }

import { computed } from "vue";
import { MeButton } from "@/libs/metha-components";

import { FlatInstallation } from "@/types/InstallationTypes";
import { useContractStore } from "@/stores";


export default /*@__PURE__*/_defineComponent({
  __name: 'SignContractActivator',
  props: {
    installation: {}
  },
  setup(__props: any) {

const props = __props;

const contractStore = useContractStore();

const warningText = computed(() => {
  if (props.installation.status === "pending_agreement") {
    return "Você precisa assinar o contrato para concluir o cadastro desta instalação.";
  }

  return "Atualizamos nossos termos de adesão e comercialização. Você precisa assinar o contrato para continuar recebendo nossos serviços.";
});

function openContract() {
  contractStore.openContract(props.installation);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.installation.pendency_contract_update)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _cache[0] || (_cache[0] = [
          _createElementVNode("div", { class: "sign-contract-activator__status__ball" }, null, -1),
          _createElementVNode("p", { class: "sign-contract-activator__status__text" }, "Contrato pendente", -1)
        ])))
      : _createCommentVNode("", true),
    _createElementVNode("p", _hoisted_3, [
      _cache[1] || (_cache[1] = _createElementVNode("b", null, "Atenção!", -1)),
      _createTextVNode(" " + _toDisplayString(warningText.value), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_unref(MeButton), { onClick: openContract }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createTextVNode("Ver e assinar contrato")
        ])),
        _: 1
      })
    ])
  ]))
}
}

})