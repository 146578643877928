import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "chart-legend" }
const _hoisted_2 = { class: "chart-legend__item__text-container" }
const _hoisted_3 = { class: "chart-legend__item__kwh" }
const _hoisted_4 = { class: "chart-legend__item__supplier" }



interface LegendItem {
  kwh: number;
  supplier: string;
  hasDiscount?: boolean;
  discount?: number;
  color: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ChartLegend',
  props: {
    items: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, ({ kwh, supplier, hasDiscount, discount, color }, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${index}`,
        class: "chart-legend__item"
      }, [
        _createElementVNode("div", {
          class: "chart-legend__item__color",
          style: _normalizeStyle({ background: color })
        }, null, 4),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", null, [
            _createElementVNode("span", null, [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("b", null, _toDisplayString(kwh), 1),
                _cache[0] || (_cache[0] = _createTextVNode(" kWh "))
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(supplier), 1)
            ])
          ]),
          hasDiscount
            ? (_openBlock(), _createElementBlock("span", {
                key: 0,
                class: "chart-legend__item__discount",
                style: _normalizeStyle({ color })
              }, _toDisplayString(discount) + "% desconto ", 5))
            : _createCommentVNode("", true)
        ])
      ]))
    }), 128))
  ]))
}
}

})