import { default as axios } from "axios";
import { useAuthStore } from "@/stores";

declare module "axios" {
  export interface AxiosRequestConfig {
    public?: boolean;
  }
}

axios.defaults.baseURL = `${process.env.VUE_APP_BASE_URL}`;

axios.interceptors.request.use(async (req) => {
  if (!req.public && req.headers) {
    if (!req.headers.Authorization) {
      const authStore = useAuthStore();
      const token = authStore.get();
      if (token) {
        req.headers.Authorization = token;
      }
    }
  }

  return req;
});

export default axios.request;
