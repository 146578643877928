export const isAdult = (value: string) => {
  if (!value) return false;

  const [day, month, year] = value.split("/");

  const dayNumber = Number(day);
  const monthNumber = Number(month);
  const yearNumber = Number(year);

  const actualDay = new Date().getDay();
  const actualMonth = new Date().getMonth() + 1;
  let actualYear = new Date().getFullYear();

  if (actualDay > dayNumber || actualMonth > monthNumber) {
    actualYear -= 1;
  }

  const actualAge = actualYear - yearNumber;

  const isGreaterThanRequiredAge = actualAge >= 18;

  return isGreaterThanRequiredAge;
};
