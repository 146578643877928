import { AxiosRequestConfig } from "axios";
import request from "./request";
import { AuthDTO, Query, VerifyDTO, VerifyEmailDTO } from "./types";

export async function checkCpf(
  cpf: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/register/${cpf}`,
    params,
    ...config,
  });
}

export async function verifySMS(
  data: VerifyDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/register/verify",
    data,
    params,
    ...config,
  });
}

export async function verifyEmail(
  data: VerifyEmailDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/register/verify_email",
    data,
    params,
    ...config,
  });
}

export async function authSMS(
  data: AuthDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/register/auth_sms",
    data: {
      ...data,
      skipBigBoost: true,
    },
    params,
    ...config,
  });
}

export async function authEmail(
  data: AuthDTO,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/register/auth",
    data: {
      ...data,
      skipBigBoost: true,
    },
    params,
    ...config,
  });
}
