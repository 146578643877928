import { Installation, FlatInstallation } from "@/types/InstallationTypes";
import { formatCEP } from "./format";

export const operationalInstallationStatuses = [
  "active",
  "approved",
  "under_cancellation",
  "suspended",
  "canceled",
  "cancelled",
];

function assembleAddress(installation: Installation) {
  const {
    address,
    address_number,
    address_supplement,
    neighborhood,
    city,
    uf,
    zip_code,
  } = installation.address;

  const supplement = address_supplement ? ` (${address_supplement})` : null;
  const zipCode = zip_code ? `CEP ${formatCEP(zip_code)}` : null;

  const addressPieces = [
    address,
    address_number,
    supplement,
    neighborhood,
    city,
    uf,
    zipCode,
  ];

  return addressPieces.filter((piece) => Boolean(piece)).join(", ");
}

function flatInstallationsData(installations: Installation[]) {
  const flattedInstallations = installations.map((installation) => {
    const flattedInstallation = {
      ...installation,
      address: "",
    } as FlatInstallation;

    flattedInstallation.address = assembleAddress(installation);

    return flattedInstallation;
  });

  return flattedInstallations;
}

function filterInstallationsByStatus(
  installations: Installation[],
  allowedStatus: string[]
) {
  const filteredInstallations = installations.filter(({ status }) => {
    return allowedStatus.includes(status);
  });

  return filteredInstallations;
}

export { flatInstallationsData, filterInstallationsByStatus };
