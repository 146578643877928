import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "ca-select" }
const _hoisted_2 = {
  key: 0,
  class: "ca-select__icon"
}
const _hoisted_3 = {
  key: 1,
  class: "ca-select__label"
}
const _hoisted_4 = { class: "ca-select__selected-option" }
const _hoisted_5 = ["onClick"]

import { ref, computed, PropType } from "vue";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

export interface CaSelectOption {
  name: string;
  value: string | number;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'CaSelect',
  props: {
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  options: {
    type: Array as PropType<
      ReadonlyArray<CaSelectOption> | Array<string | number>
    >,
    required: true,
  },
  getValue: {
    type: Function,
    default: (item: number | string | CaSelectOption) => {
      if (typeof item === "object") {
        return item.value;
      }

      return item;
    },
  },
  getName: {
    type: Function,
    default: (item: number | string | CaSelectOption) => {
      if (typeof item === "object") {
        return item.name;
      }

      return item;
    },
  },
  modelValue: {
    type: [String, Number],
    required: true,
  },
  optionsSide: {
    type: String as PropType<"left" | "right">,
    default: "left",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const { lgAndUp } = useBreakpoints();
const isOpen = ref(false);

const selectedOption = computed(() => {
  let selectedItem = null;

  for (let i = 0; i < props.options.length; i += 1) {
    const currentItem = props.options[i];
    if (props.getValue(currentItem) === props.modelValue) {
      selectedItem = currentItem;
      break;
    }
  }

  return selectedItem;
});

const highlightedValue = computed(() => {
  if (!lgAndUp.value && (props.label?.length ?? 0) > 0) {
    return props.label;
  }

  return props.getName(selectedOption.value);
});

const computedStyle = computed(() => {
  return `${props.optionsSide}: 0;`;
});

function handleChange(item: CaSelectOption | string | number) {
  const value = props.getValue(item);
  emit("update:modelValue", value);
  isOpen.value = false;
}

function closeSelect() {
  isOpen.value = false;
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "ca-select__label-container",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (isOpen.value = !isOpen.value))
    }, [
      (__props.icon && __props.icon.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_2, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(__props.icon), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (__props.label && __props.label.length)
        ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(__props.label) + ":", 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", _hoisted_4, _toDisplayString(highlightedValue.value), 1),
      _createElementVNode("span", {
        class: _normalizeClass([
          'ca-select__switch-icon',
          { 'ca-select__switch-icon--open': isOpen.value },
        ])
      }, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" chevron-bottom ")
          ])),
          _: 1
        })
      ], 2)
    ]),
    (isOpen.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "ca-select__options",
          style: _normalizeStyle(computedStyle.value)
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.options, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${index}`,
              onClick: () => handleChange(item),
              class: _normalizeClass([
          'ca-select__options__item',
          {
            'ca-select__options__item--selected': item === selectedOption.value,
          },
        ])
            }, _toDisplayString(__props.getName(item)), 11, _hoisted_5))
          }), 128))
        ], 4))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, closeSelect]
  ])
}
}

})