import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "installations-nav-select" }
const _hoisted_2 = { class: "installations-nav-select__label__wrapper" }
const _hoisted_3 = { class: "installations-nav-select__label__wrapper__label" }
const _hoisted_4 = { class: "installations-nav-select__label__wrapper__value" }
const _hoisted_5 = {
  key: 0,
  class: "installations-nav-select__options"
}
const _hoisted_6 = {
  key: 0,
  class: "installations-nav-select__options__divider-line"
}

import { ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import { MeIcon, useBreakpoints } from "@/libs/metha-components";

import { useUserStore } from "@/stores";
import { capitalize } from "@/utils/format";

import { FlatInstallation } from "@/types/InstallationTypes";

import InstallationNavSelectOption from "@/components/atoms/InstallationNavSelectOption.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InstallationsNavSelect',
  setup(__props) {

const route = useRoute();
const router = useRouter();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

const open = ref(false);

const selectedInstallation = computed(() => {
  return userStore.selectedInstallation;
});

const options = computed(() => {
  return userStore.operationalInstallations ?? [];
});

async function handleClick(value: FlatInstallation) {
  const selectedInstallationIsAlreadySelected =
    (userStore.selectedInstallation?.id ?? "") === value.id;

  if (selectedInstallationIsAlreadySelected) {
    open.value = false;
    return;
  }

  userStore.pageLoading = true;

  await userStore.switchInstallation(value);

  open.value = false;

  if (route.name === "invoice_details") {
    router.push("/faturas");
  } else {
    userStore.pageLoading = false;
  }
}

function closeInstallationNavSelect() {
  open.value = false;
}

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "installations-nav-select__label",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (open.value = !open.value))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, " Instalação" + _toDisplayString(_unref(lgAndUp) ? "" : `: ${selectedInstallation.value.installation_number}`), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(lgAndUp)
              ? selectedInstallation.value.installation_number
              : _unref(capitalize)(selectedInstallation.value.address)), 1)
      ]),
      _createElementVNode("div", {
        class: _normalizeClass([
          'installations-nav-select__label__icon',
          { 'installations-nav-select__label__icon--rotate': open.value },
        ])
      }, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("chevron-bottom")
          ])),
          _: 1
        })
      ], 2)
    ]),
    (open.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(options.value, (installation, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: `installations-nav-select-option-${index}`
            }, [
              _createVNode(InstallationNavSelectOption, {
                installation: installation,
                onClick: () => handleClick(installation)
              }, null, 8, ["installation", "onClick"]),
              (index < options.value.length - 1)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ])), [
    [_directive_click_outside, closeInstallationNavSelect]
  ])
}
}

})