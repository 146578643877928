import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "pix-payment" }
const _hoisted_2 = { class: "pix-payment__side-text" }
const _hoisted_3 = { class: "pix-payment__side-text__icon" }
const _hoisted_4 = {
  key: 0,
  class: "pix-payment__side-text__auxiliar"
}
const _hoisted_5 = {
  key: 0,
  class: "pix-payment__image"
}
const _hoisted_6 = {
  key: 0,
  class: "pix-payment__image__error-text"
}
const _hoisted_7 = ["src"]

import { computed } from "vue";
import { useBreakpoints, MeIcon } from "@/libs/metha-components";

import { Invoice } from "@/types/InvoiceTypes";
import { getQrImage } from "@/utils/aws";

import QrCodeImage from "@/assets/images/mock/qr-code.png";

import CopyContainer from "@/components/atoms/CopyContainer.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PixPayment',
  props: {
    auxiliarText: {},
    invoice: {}
  },
  setup(__props: any) {

const props = __props;

const { mdAndUp } = useBreakpoints();

const hasAuxiliarText = computed(() => {
  if (!props.auxiliarText) return false;

  return props.auxiliarText.trim().length !== 0;
});

const hasPixInformation = computed(() => {
  return (
    !!(props.invoice.pix?.brcode || props.invoice.pix?.qrcode?.s3_file) ?? null
  );
});

const pixCode = computed(() => {
  return props.invoice.pix?.brcode ?? "";
});

const hasQrCodeImage = computed(() => {
  return !!props.invoice.pix?.qrcode?.s3_file ?? false;
});

const qrCodeImagePath = computed(() => {
  const qrCodeInfo = hasQrCodeImage.value
    ? props.invoice.pix?.qrcode?.s3_file
    : null;

  if (qrCodeInfo) {
    return getQrImage(qrCodeInfo.bucket, qrCodeInfo.key);
  }

  return QrCodeImage;
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(CopyContainer, {
      valueToCopy: pixCode.value,
      disabled: !hasPixInformation.value,
      successMessage: "Código Pix copiado!"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: _normalizeClass([
          'pix-payment__content',
          { 'pix-payment__content--active': hasPixInformation.value },
        ])
        }, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("span", _hoisted_3, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("pix")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", null, [
              (hasAuxiliarText.value && hasPixInformation.value)
                ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.auxiliarText), 1))
                : _createCommentVNode("", true),
              _createElementVNode("p", {
                class: _normalizeClass(`${
                hasPixInformation.value
                  ? 'pix-payment__side-text__copy-text'
                  : 'pix-payment__side-text__auxiliar'
              }`)
              }, _toDisplayString(hasPixInformation.value
                  ? "Copiar o código Pix"
                  : "O pagamento por pix não está disponível para essa fatura"), 3)
            ])
          ]),
          (_unref(mdAndUp))
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                (hasPixInformation.value && !hasQrCodeImage.value)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, " Houve um problema ao gerar o QR Code "))
                  : _createCommentVNode("", true),
                _createElementVNode("img", {
                  src: qrCodeImagePath.value,
                  alt: "QR Code - Aperte aqui para copiar",
                  class: _normalizeClass([
              'pix-payment__image__qr-code',
              { 'pix-payment__image__qr-code--blur': !hasQrCodeImage.value },
            ])
                }, null, 10, _hoisted_7)
              ]))
            : _createCommentVNode("", true)
        ], 2)
      ]),
      _: 1
    }, 8, ["valueToCopy", "disabled"])
  ]))
}
}

})