import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "view-invoice-composed-select" }

import { ref, watch, type PropType } from "vue";
import { useRouter } from "vue-router";

import { useInvoicesStore } from "@/stores";

import { getMonthNameFromReferenceMonth } from "@/utils/format";

import CaComposedSelect from "@/components/atoms/CaComposedSelect.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ViewInvoiceSelectInvoice',
  props: {
  label: {
    type: String,
  },
  icon: {
    type: String,
  },
  primaryOptions: {
    type: Array as PropType<Array<number>>,
    required: true,
  },
  secondaryOptions: {
    type: Object as PropType<{ [key: number]: string[] }>,
  },
  getValue: {
    type: Function,
    default: (item: number | string) => item,
  },
  getName: {
    type: Function,
    default: (item: number | string) => item,
  },
  currentYear: {
    type: Number,
    required: true,
  },
  currentInvoice: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const router = useRouter();

const props = __props;

const invoicesStore = useInvoicesStore();
const value = ref(props.currentInvoice);
const selectedYear = ref(props.currentYear);

function getName(item: string) {
  if (!item || !item.length) return item;

  return getMonthNameFromReferenceMonth(item);
}

function getCompleteReferenceMonthName(reference_month: string) {
  if (!reference_month) return "";

  const [, year] = reference_month.split("/");
  return `${getMonthNameFromReferenceMonth(reference_month)}/${year}`;
}

watch(value, () => {
  const selectedInvoice = invoicesStore.getInvoiceByReferenceMonth(value.value);

  if (selectedInvoice) {
    router.push(`/faturas/${selectedInvoice.id}`);
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[1] || (_cache[1] = _createElementVNode("p", { class: "view-invoice-composed-select__title-desktop" }, " Fatura referente a ", -1)),
    _cache[2] || (_cache[2] = _createElementVNode("p", { class: "view-invoice-composed-select__title-mobile" }, "Referente a", -1)),
    _createVNode(CaComposedSelect, {
      label: getCompleteReferenceMonthName(__props.currentInvoice),
      selectedPrimary: selectedYear.value,
      primaryOptions: __props.primaryOptions,
      modelValue: value.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
      secondaryOptions: __props.secondaryOptions,
      getName: getName
    }, null, 8, ["label", "selectedPrimary", "primaryOptions", "modelValue", "secondaryOptions"])
  ]))
}
}

})