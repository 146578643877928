<script setup lang="ts">
import { computed, defineProps, PropType, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";

import type { Invoice } from "@/types/InvoiceTypes";
import { formatMoney, formatDate } from "@/utils/format";

import PixPayment from "@/components/molecules/PixPayment.vue";
import BarcodePayment from "@/components/molecules/BarcodePayment.vue";

const props = defineProps({
  invoice: {
    type: Object as PropType<Invoice>,
    required: true,
  },
});

const openPaymentsModal = ref(false);

const barcode = computed(() => {
  return props.invoice.boleto?.line ?? "";
});
</script>

<template>
  <div>
    <p
      class="payments-methods__activator link"
      @click="openPaymentsModal = true"
    >
      Ver opções de pagamento
    </p>
    <div
      class="payments-methods__modal__background"
      @click="openPaymentsModal = false"
      v-if="openPaymentsModal"
    ></div>
    <section class="payments-methods__modal" v-if="openPaymentsModal">
      <div class="payments-methods__modal__content">
        <div
          class="payments-methods__modal__close-button"
          @click="openPaymentsModal = false"
        >
          <MeIcon>x</MeIcon>
        </div>
        <div class="payments-methods__modal__value-and-date">
          <div>
            <p class="payments-methods__modal__value-and-date__title">
              Valor total a pagar
            </p>
            <p class="payments-methods__modal__value-and-date__value">
              {{ formatMoney(invoice.total_value) }}
            </p>
          </div>
          <div>
            <p class="payments-methods__modal__value-and-date__title">
              Vencimento
            </p>
            <p class="payments-methods__modal__value-and-date__value">
              {{ formatDate(invoice.due_date) }}
            </p>
          </div>
        </div>
        <div class="payments-methods__modal__payment-method">
          <p class="payments-methods__modal__payment-method__title">
            Pague com Pix
          </p>
          <p class="payments-methods__modal__payment-method__text">
            Tenha sua fatura compensada imediatamente ou em até 2 horas.
          </p>
          <div class="payments-methods__modal__payment-method__limit-card">
            <PixPayment :invoice="invoice" />
          </div>
        </div>
        <div class="payments-methods__modal__payment-method">
          <p class="payments-methods__modal__payment-method__title">
            Pague com Boleto
          </p>
          <p class="payments-methods__modal__payment-method__text">
            O pagamento pode levar até 2 dias úteis para ser processado.
          </p>
          <BarcodePayment :barcode="barcode" />
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
.payments-methods {
  &__activator {
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-align: center;
    white-space: nowrap;
    cursor: pointer;
  }

  &__modal {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;

    cursor: normal;

    &__background {
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 10;

      background-color: rgba(0, 0, 0, 0.25);
    }

    &__content {
      position: relative;
      z-index: 10;
      width: 95%;
      margin: 0.5rem;
      background-color: #fff;
      box-shadow: 0px 0.25rem 0.375rem 0px rgba(33, 37, 41, 0.2);
      border-radius: 1.5rem;
      padding: 2.5rem 1.75rem 1.75rem 1.75rem;
      max-width: 450px;

      @media (min-width: 993px) {
        padding: 3.125rem 1.5rem 1.75rem 1.5rem;
        min-width: 400px;
        max-width: fit-content;
      }
    }

    &__close-button {
      position: absolute;
      top: 10px;
      right: 15px;
      cursor: pointer;
    }

    &__value-and-date {
      display: flex;
      gap: 1.25rem;

      @media (min-width: 993px) {
        gap: 6.25rem;
      }

      &__title {
        font-size: 1rem;
        line-height: 1rem;
        font-weight: 400;
        color: #6c757d;

        @media (min-width: 993px) {
          font-size: 1.25rem;
          line-height: 1.5rem;
        }
      }

      &__value {
        font-size: 1.25rem;
        line-height: 1.5rem;
        font-weight: 700;
        color: #111111;

        @media (min-width: 993px) {
          font-size: 1.875rem;
          line-height: 2.25rem;
        }
      }
    }

    &__payment-method {
      padding: 1.5rem 0 0.5rem 0;
      display: flex;
      flex-direction: column;
      gap: 0.625rem;

      &__title {
        font-size: 1rem;
        font-weight: 700;
        line-height: 1.5rem;
        color: #000000;

        @media (min-width: 993px) {
          font-size: 1.25rem;
          font-weight: 400;
        }
      }

      &__text {
        font-size: 1rem;
        line-height: 1.25rem;
        font-weight: 400;
        color: #6c757d;

        @media (min-width: 993px) {
          line-height: 1.5rem;
        }
      }
    }
  }
}
</style>
