const prepositions = [
  "a",
  "ante",
  "após",
  "até",
  "com",
  "contra",
  "de",
  "desde",
  "em",
  "entre",
  "para",
  "per",
  "perante",
  "por",
  "sem",
  "sob",
  "sobre",
  "trás",
  "afora",
  "como",
  "conforme",
  "consoante",
  "durante",
  "exceto",
  "mediante",
  "menos",
  "salvo",
  "segundo",
  "visto",
  "ao",
  "aos",
  "na",
  "nas",
  "no",
  "nos",
  "da",
  "das",
  "do",
  "dos",
  "daquilo",
  "naquele",
  "numa",
];

export default prepositions;
