import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "faq-search-bar__container" }
const _hoisted_2 = { class: "faq-search-bar__icon" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = {
  key: 0,
  class: "faq-search-bar__results"
}
const _hoisted_5 = {
  key: 0,
  class: "faq-search-bar__results__loading"
}
const _hoisted_6 = { class: "faq-search-bar__results__loading__spinner" }
const _hoisted_7 = {
  key: 1,
  class: "faq-search-bar__results__item__title--error"
}
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "faq-search-bar__results__item__title" }
const _hoisted_10 = ["innerHTML"]

import { computed, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { MeIcon } from "@/libs/metha-components";
import { toast } from "vue3-toastify";

import { InputFileEvent } from "@/types/HTMLEventHandling";
import { useFaqStore } from "@/stores/faq";
import debounce from "@/utils/debounce";

import CaSpinner from "@/components/atoms/CaSpinner.vue";
import { useUserStore } from "@/stores/user";
import { SearchedArticle } from "@/types/Faq";


export default /*@__PURE__*/_defineComponent({
  __name: 'FaqSearchBar',
  setup(__props) {

const router = useRouter();

const faqStore = useFaqStore();
const userStore = useUserStore();

const searchParam = ref("");
const loading = ref(false);
const isOpen = ref(false);

const results = computed(() => {
  return faqStore.searchResults ?? [];
});

function resetSearchBar() {
  searchParam.value = "";
  loading.value = false;
  isOpen.value = false;
  faqStore.resetSearchBar();
}

function debouncedFunction(arg: string) {
  const debounced = debounce(async () => {
    search(arg);
  }, 800);

  debounced();
}

async function search(param: string) {
  if (loading.value) return;
  loading.value = true;

  await faqStore.searchArticles(param);

  loading.value = false;
}

function handleInput(e: Event) {
  e.preventDefault();

  const event = e as InputFileEvent;

  searchParam.value = event.target.value;
}

function openSearchResults() {
  isOpen.value = true;
}

function closeSearchResults() {
  isOpen.value = false;
}

function formatSnippet(html: string) {
  const highlightSearchedTerm =
    '<em style="font-weight: 700 !important; font-style: italic;"';

  return html.replace("<em", highlightSearchedTerm);
}

async function switchSelectedArticle(article: SearchedArticle) {
  try {
    userStore.loadingScreen = true;
    await faqStore.setCurrentSection(article.section_id);

    router.push(`/central-de-ajuda/${article.id}`);

    resetSearchBar();
  } catch (error) {
    toast.error(
      "Ocorreu um erro ao selecionar o artigo. Tente novamente por favor",
      {
        position: toast.POSITION.BOTTOM_CENTER,
      }
    );
  } finally {
    userStore.loadingScreen = false;
  }
}

watch(
  () => searchParam.value,
  (newVal: string) => {
    if (newVal.length === 0) {
      faqStore.clearSearchResults();
    } else {
      debouncedFunction(newVal);
    }
  }
);

return (_ctx: any,_cache: any) => {
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`faq-search-bar`, { 'faq-search-bar--is-open': isOpen.value }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("search")
          ])),
          _: 1
        })
      ]),
      _withDirectives(_createElementVNode("input", {
        type: "text",
        placeholder: "Digite sua dúvida...",
        disabled: loading.value,
        class: "faq-search-bar__input",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchParam).value = $event)),
        onClick: openSearchResults,
        onInput: handleInput
      }, null, 40, _hoisted_3), [
        [_vModelText, searchParam.value]
      ])
    ]),
    (_unref(faqStore).searchRequestMade)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (loading.value)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_5, [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(CaSpinner, {
                    size: 14,
                    width: 2
                  })
                ]),
                _cache[2] || (_cache[2] = _createTextVNode(" Carregando... "))
              ]))
            : (!results.value || results.value?.length === 0)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_7, " Não foi encontrado nenhum resultado para essa pesquisa. "))
              : (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(results.value, (item, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "faq-search-bar__results__item",
                    onClick: () => switchSelectedArticle(item),
                    key: `faq-search-results-item-${index}`
                  }, [
                    _createElementVNode("h4", _hoisted_9, _toDisplayString(item.title), 1),
                    _createElementVNode("p", {
                      class: "faq-search-bar__results__item__description",
                      innerHTML: formatSnippet(item.snippet)
                    }, null, 8, _hoisted_10)
                  ], 8, _hoisted_8))
                }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 2)), [
    [_directive_click_outside, closeSearchResults]
  ])
}
}

})