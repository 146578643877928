import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "sustainability-card" }
const _hoisted_2 = { class: "sustainability-card__header" }
const _hoisted_3 = { class: "sustainability-card__header__icon" }
const _hoisted_4 = { class: "sustainability-card__text" }
const _hoisted_5 = ["innerHTML"]

import { computed } from "vue";
import { MeIcon } from "@/libs/metha-components";

import {
  getSustainabilityCardText,
  getSustainabilityCardValue,
} from "@/utils/sustainabilityCard";


export default /*@__PURE__*/_defineComponent({
  __name: 'SustainabilityCard',
  props: {
  cardInfo: {
    type: Object,
    required: true,
  },
  kwhConsumption: {
    type: Number,
    required: true,
  },
  activeIndex: {
    type: Number,
    required: true,
  },
  numberOfBullets: {
    type: Number,
    required: true,
  },
  goToNextCard: {
    type: Function,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const cardValue = computed(() => {
  return getSustainabilityCardValue(props.cardInfo.type, props.kwhConsumption);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "sustainability-card__header__title" }, "Sustentabilidade", -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_unref(MeIcon), null, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("nm-plant")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(getSustainabilityCardText)(__props.cardInfo.type)), 1),
    _createElementVNode("p", {
      class: _normalizeClass([
        'sustainability-card__value',
        {
          'sustainability-card__value--sub-margin-remove':
            cardValue.value.match('<sub>'),
        },
      ]),
      innerHTML: cardValue.value
    }, null, 10, _hoisted_5),
    _createElementVNode("div", {
      class: "sustainability-card__nav-indicator",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (__props.goToNextCard && __props.goToNextCard(...args)))
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.numberOfBullets, (idx) => {
        return (_openBlock(), _createElementBlock("div", {
          key: `sustainability-card__nav-indicator__bullet-${idx}`,
          class: _normalizeClass([
          'sustainability-card__nav-indicator__bullet',
          {
            'sustainability-card__nav-indicator__bullet--active':
              __props.activeIndex + 1 === idx,
          },
        ])
        }, null, 2))
      }), 128))
    ])
  ]))
}
}

})