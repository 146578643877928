import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "me-header" }
const _hoisted_2 = { class: "me-header__container me-header__information" }
const _hoisted_3 = { class: "me-header__wrapper-menu-title" }
const _hoisted_4 = { class: "me-header__greeting" }
const _hoisted_5 = { class: "me-header__options" }
const _hoisted_6 = { class: "me-header__options__item__icon" }
const _hoisted_7 = {
  key: 0,
  class: "me-header__container"
}
const _hoisted_8 = {
  key: 0,
  class: "me-header__installation-select"
}
const _hoisted_9 = { class: "me-header__installation-select__address" }


import { useRouter } from "vue-router";

import {
  MeIcon,
  // MeIconNotification,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore } from "@/stores";
import { capitalize } from "@/utils/format";

import InstallationsNavSelect from "@/components/molecules/InstallationsNavSelect.vue";
import MobileActionButton from "@/components/molecules/MobileActionButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LayoutHeader',
  props: {
    title: {},
    notificationNumber: {},
    hideInstallationSwitchComponent: { type: Boolean }
  },
  setup(__props: any) {



const router = useRouter();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

async function logOut() {
  await userStore.logOutUser();
  router.push("/login");
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!_unref(lgAndUp))
          ? (_openBlock(), _createBlock(MobileActionButton, { key: 0 }))
          : _createCommentVNode("", true),
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(capitalize)(_ctx.title)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "me-header__options__item" }, null, -1)),
        _createElementVNode("div", {
          class: "me-header__options__item",
          onClick: logOut
        }, [
          _createElementVNode("span", _hoisted_6, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[0] || (_cache[0] = [
                _createTextVNode("nm-logout")
              ])),
              _: 1
            })
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("p", null, "Sair", -1))
        ])
      ])
    ]),
    (!_ctx.hideInstallationSwitchComponent)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          (!_unref(userStore).pageLoading || !_unref(lgAndUp))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createVNode(InstallationsNavSelect),
                _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(capitalize)(_unref(userStore).selectedInstallation.address)), 1)
              ]))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})