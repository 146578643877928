<script setup lang="ts">
import { onBeforeUnmount, ref, computed, watch, onMounted } from "vue";

import { MeIcon, MeButton, MeOtpCode } from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useLoginStore, useUserStore } from "@/stores";

import { maskEmail } from "@/utils/mask";
import { handleError } from "@/utils/handleError";

const loginStore = useLoginStore();
const userStore = useUserStore();

const otpCode = ref("");
const loadingSeconds = ref(0);
const timerInterval = ref<number>();

const loading = ref(false);

const filteredEmail = computed(() => {
  return maskEmail(loginStore.email);
});

function startTimer() {
  if (loadingSeconds.value !== 0) return;

  loadingSeconds.value = 30;
  timerInterval.value = setInterval(() => {
    if (loadingSeconds.value === 1) {
      clearInterval(timerInterval.value);
    }
    loadingSeconds.value -= 1;
  }, 1000);
}

function abortTimer() {
  loadingSeconds.value = 0;
  clearInterval(timerInterval.value);
}

async function sendCode() {
  if (loadingSeconds.value !== 0) return;

  try {
    userStore.loadingScreen = true;

    startTimer();

    await loginStore.sendOtpCode();
  } catch (error) {
    abortTimer();

    if (loginStore.verificationMethod === "SMS") {
      loginStore.blockSmsVerification = true;
      changeMethod();
    } else {
      handleError(error, loginStore.setOtpError);
    }
  } finally {
    userStore.loadingScreen = false;
  }
}

async function handleSubmit() {
  if (otpCode.value.length !== 6 || loginStore.loadingOtp || loading.value)
    return;
  loading.value = true;
  userStore.loadingScreen = true;

  try {
    await loginStore.validateOtpCode(otpCode.value);
    await loginStore.redirectToInstallationSelection();
  } catch (error) {
    handleError(error, loginStore.setOtpError);
  } finally {
    loading.value = false;
    userStore.loadingScreen = false;
    loginStore.loadingOtp = false;
  }
}

function changeMethod() {
  if (loadingSeconds.value !== 0 && !loginStore.blockSmsVerification) return;

  loginStore.changeVerificationMethod();
  sendCode();
}

function goBackButtonSubmit() {
  loginStore.changeStatus("verify-cpf");
}

watch(otpCode, () => {
  loginStore.setOtpError("");
});

onMounted(async () => {
  loginStore.verificationMethod = loginStore.blockSmsVerification
    ? "e-mail"
    : "SMS";
  loginStore.setOtpError("");
  if (loginStore.shouldSendOtpCode) {
    sendCode();
  } else {
    startTimer();
  }
});

onBeforeUnmount(() => {
  abortTimer();
});
</script>

<template>
  <Form class="login__container login-verify-otp" @submit="handleSubmit">
    <p class="login-verify-otp__return me--link" @click="goBackButtonSubmit">
      <span class="login-verify-otp__return__icon-container">
        <div class="login-verify-otp__return__icon">
          <MeIcon>arrow-back</MeIcon>
        </div>
      </span>
      <span class="login-verify-otp__return__text">Voltar</span>
    </p>
    <div class="login-verify-otp__container">
      <div class="login-verify-otp__metha-logo">
        <img
          src="@/assets/brand/logo.svg"
          alt="Logo da Metha Energia"
          class="login-verify-otp__metha-logo"
          width="205"
        />
      </div>
      <h2 class="login-verify-otp__title">Confirmar identidade</h2>
      <p class="login-verify-otp__register">
        <template v-if="loginStore.verificationMethod === 'SMS'">
          Enviamos um código via SMS de verificação para o celular com número
          final: <b>{{ loginStore.phone.slice(-4) }}</b>
        </template>
        <template v-else>
          Enviamos um código de verificação para seu e-mail:
          {{ filteredEmail }}, confira em sua caixa de entrada e também na caixa
          de spam.
        </template>
      </p>
      <div class="login-verify-otp__code-container">
        <div class="login-verify-otp__code-container__resend-container">
          <p
            :class="[
              'login-verify-otp__code-container__resend-container__title',
              { 'login-verify-otp--error': loginStore.otpError },
            ]"
          >
            Código
          </p>
        </div>
        <MeOtpCode v-model="otpCode" :error="loginStore.otpError" />
        <p
          class="login-verify-otp--error"
          v-if="loginStore.otpError && loginStore.otpError.length > 0"
        >
          {{ loginStore.otpError }}
        </p>
      </div>
      <MeButton
        block
        :disabled="otpCode.length !== 6 || loginStore.loadingOtp || loading"
        class="login-verify-otp__otp-submit-button"
      >
        Entrar
      </MeButton>
      <p
        class="login-verify-otp__code-container__resend-container__button"
        @click="sendCode"
      >
        <template v-if="loadingSeconds !== 0">
          Código enviado! Aguarde {{ loadingSeconds }}s para reenviar.
        </template>
        <template v-else>
          <span
            class="login-verify-otp__code-container__resend-container__break-in-mobile"
          >
            Não recebeu?
            <span
              class="me--link me--bold cursor-pointer"
              @click.prevent="sendCode"
            >
              enviar outro {{ loginStore.verificationMethod }}
            </span>
          </span>
          <template v-if="!loginStore.blockSmsVerification">
            ou
            <span
              class="me--link me--bold cursor-pointer"
              @click.prevent="changeMethod"
            >
              enviar via
              {{ loginStore.verificationMethod === "SMS" ? "e-mail" : "SMS" }}
            </span>
          </template>
        </template>
      </p>
    </div>
    <div></div>
  </Form>
</template>

<style lang="scss" scoped>
.login-verify-otp {
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;

  @media (min-width: 993px) {
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }

  &__return {
    width: 100%;
    max-width: 495px;
    margin: 1rem auto 0;
    padding-left: 1.5rem;

    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    color: #232326;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;

    @media (min-width: 993px) {
      margin-top: 0;
    }

    &__icon {
      position: relative;
      width: 24px;
      height: 24px;
      font-size: 1.125rem;

      i {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }

    &__text {
      padding-top: 2px;

      @media (min-width: 768px) {
        padding-top: 0;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    padding: 1.5rem;
  }

  &__metha-logo {
    width: 230px;
    margin: 0 auto;

    @media (min-width: 993px) {
      width: 205px;
      margin: 0;
    }
  }

  &__title {
    color: #000;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;

    margin: 2.5rem 0 0.5rem;

    @media (min-width: 993px) {
      font-size: 2rem;
      margin: 4rem 0 1.5rem;
    }
  }

  &__register {
    color: #7a7a7a;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1.125rem;
      line-height: 1.875rem;
      letter-spacing: -0.0225rem;
    }
  }

  &__code-container {
    margin: 2.5rem 0;

    &__resend-container {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 0.25rem;

      &__title {
        text-transform: uppercase;
        color: #000;
        font-size: 1rem;
        font-weight: 600;
        line-height: normal;
      }

      &__button {
        text-align: center;
        margin-top: 2rem;
        text-decoration: none;
        color: #232326;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.5rem;
      }

      &__break-in-mobile {
        display: block;

        @media (min-width: 993px) {
          display: inline;
        }
      }
    }
  }

  &__otp-submit-button {
    width: 100%;
  }

  &--error {
    color: #ff0000;
    font-size: 0.875rem;
    margin-top: 0.675rem;
  }
}
</style>
