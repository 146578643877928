import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "service-channel-item" }
const _hoisted_2 = { class: "service-channel-item__title" }
const _hoisted_3 = { class: "service-channel-item__icon" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "service-channel-item__text" }
const _hoisted_6 = ["href"]
const _hoisted_7 = { key: 1 }

import { computed } from "vue";

import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";

interface ServiceChannelItem {
  icon: string;
  title: string;
  text: string;
  button_text: string;
  url: string | null;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceChannelItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const props = __props;

const userStore = useUserStore();

const usesMethaComponentsLib = computed(() => {
  return !props.item.icon.match(/[/.]/);
});

function openChat() {
  userStore.openSunshineChat();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h3", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        (usesMethaComponentsLib.value)
          ? (_openBlock(), _createBlock(_unref(MeIcon), { key: 0 }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.item.icon), 1)
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("img", {
              key: 1,
              src: _ctx.item.icon,
              style: { marginBottom: '-0.5rem' }
            }, null, 8, _hoisted_4))
      ]),
      _createTextVNode(" " + _toDisplayString(_ctx.item.title), 1)
    ]),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.item.text), 1),
    (typeof _ctx.item.url === 'string')
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          class: "service-channel-item__url",
          href: _ctx.item.url,
          target: "_blank"
        }, _toDisplayString(_ctx.item.button_text), 9, _hoisted_6))
      : (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_unref(MeButton), {
            onClick: openChat,
            class: "service-channel-item__button"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.item.button_text), 1)
            ]),
            _: 1
          })
        ]))
  ]))
}
}

})