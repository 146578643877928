import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { useAuthStore } from "@/stores";
import { ref, type Ref } from "vue";
import { VueRecaptcha } from "vue-recaptcha";

export interface CaptchaProvider {
  loaded: Ref<boolean>;
  getToken(action: string): Promise<string>;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RecaptchaWrapper',
  setup(__props) {

const RECAPTCHA_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY;

const authStore = useAuthStore();

const loaded = ref(false);
const recaptcha = ref<InstanceType<typeof VueRecaptcha>>();

let resolveToken = (token: string) => console.log(token);
let rejectToken = (err: unknown): void => {
  console.log(err);
};

function onCaptchaVerify(token: string) {
  resolveToken(token);
}

function onCaptchaError(err: unknown) {
  rejectToken(err);
}

let lastActions: { [k: string]: number } = {};

function getToken(action: string): Promise<string> {
  let tries = 0;

  if (Object.keys(lastActions).includes(action)) {
    lastActions[action] += 1;
    tries = lastActions[action];
  } else {
    lastActions = {
      ...lastActions,
      [action]: tries,
    };
  }

  action = `${action}${tries}`;

  return new Promise((resolve, reject) => {
    resolveToken = resolve;
    rejectToken = reject;
    recaptcha.value?.execute(action);
  });
}

async function isLoaded() {
  loaded.value = true;

  authStore.setGetRecaptchaTokenFunction(async (action: string) => {
    let actualToken = await getToken(action);
    actualToken = actualToken as string;
    return actualToken ?? "";
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (loaded.value)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : _createCommentVNode("", true),
    _createVNode(_unref(VueRecaptcha), {
      ref_key: "recaptcha",
      ref: recaptcha,
      class: "captcha",
      sitekey: _unref(RECAPTCHA_KEY),
      size: "invisible",
      badge: "bottomright",
      onRender: isLoaded,
      onVerify: onCaptchaVerify,
      onError: onCaptchaError
    }, null, 8, ["sitekey"])
  ], 64))
}
}

})