export const maskEmail = (email: string) => {
  if (!email) return;

  const [user, mail] = email.split("@");

  let middleIndex = Math.floor(user.length / 2);

  const middleFilter = "*".repeat(middleIndex);

  if (user.length % 2 !== 0) middleIndex += 1;

  const filtered = `${user.substring(0, middleIndex)}${middleFilter}@${mail}`;

  return filtered;
};

export function maskCnpj(cnpj: string) {
  const normalizedCnpj = cnpj.replace(/\D/gm, "");
  const masked = normalizedCnpj.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
  return masked;
}

export function maskCpf(cpf: string) {
  const normalizedCpf = cpf.replace(/\D/gm, "");
  const masked = normalizedCpf.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    "$1.$2.$3-$4"
  );
  return masked;
}
