import { BasicContractVariables } from "@/types/Contract";

const suppliersVariables = {
  cemig: {
    "Nome Consórcio": "CONSÓRCIO AVANTTI METHA",
    "CNPJ Consórcio": "47.387.900/0001-05",
    "Nome concessionária": "CEMIG",
    "Razão social concessionária": "CEMIG Distribuição S.A.",
    "CNPJ concessionária": "06.981.180/0001-16",
    desconto: "15%",
  },
  "energisa-mt": {
    "Nome Consórcio": "CONSÓRCIO PERSEUS METHA",
    "CNPJ Consórcio": "48.212.730/0001-82",
    "Nome concessionária": "ENERGISA MT",
    "Razão social concessionária":
      "Energisa Mato Grosso - Distribuidora de Energia S.A.",
    "CNPJ concessionária": "03.467.321/0001-99",
    desconto: "12%",
  },
  "cpfl-paulista": {
    "Nome Consórcio": "CONSÓRCIO VIRGO METHA",
    "CNPJ Consórcio": "48.175.520/0001-61",
    "Nome concessionária": "CPFL",
    "Razão social concessionária": "Companhia Paulista de Força e Luz",
    "CNPJ concessionária": "33.050.196/0001-88",
    desconto: "10%",
  },
} as unknown as { [key: string]: BasicContractVariables };

function getSupplierContractVariables(
  supplier: string
): BasicContractVariables {
  const key = supplier as keyof typeof suppliersVariables;

  return suppliersVariables[key] || suppliersVariables["cemig"];
}

export { getSupplierContractVariables };
