import detect from "detect.js";

const oWebViewInterface = window.nsWebViewInterface;
const userOS = detect.parse(navigator.userAgent).os.family ?? "";

const allowedSystems = ["android", "ios"];

const shouldEmitNotifications = allowedSystems.includes(userOS.toLowerCase());

function emitNotification(event: string, payload?: any) {
  if (shouldEmitNotifications) oWebViewInterface?.emit(event, payload);
}

function listenNotificationFromApp(
  event: string,
  handler: (arg?: any) => void
) {
  if (shouldEmitNotifications) oWebViewInterface?.on(event, handler);
}

function hasAppWrapper() {
  return !!shouldEmitNotifications;
}

export { emitNotification, listenNotificationFromApp, hasAppWrapper };
