import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CaSpinner',
  props: {
  size: {
    type: Number,
    default: 60,
  },
  width: {
    type: Number,
    default: 10,
  },
},
  setup(__props) {

const props = __props;

const computedStyle = computed(() => ({
  "--spinner-size": `${props.size}px`,
  "--spinner-width": `${props.width}px`,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "spinner",
    style: _normalizeStyle(computedStyle.value)
  }, null, 4))
}
}

})