import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "payments-methods__modal"
}
const _hoisted_2 = { class: "payments-methods__modal__content" }
const _hoisted_3 = { class: "payments-methods__modal__value-and-date" }
const _hoisted_4 = { class: "payments-methods__modal__value-and-date__value" }
const _hoisted_5 = { class: "payments-methods__modal__value-and-date__value" }
const _hoisted_6 = { class: "payments-methods__modal__payment-method" }
const _hoisted_7 = { class: "payments-methods__modal__payment-method__limit-card" }
const _hoisted_8 = { class: "payments-methods__modal__payment-method" }

import { computed, PropType, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";

import type { Invoice } from "@/types/InvoiceTypes";
import { formatMoney, formatDate } from "@/utils/format";

import PixPayment from "@/components/molecules/PixPayment.vue";
import BarcodePayment from "@/components/molecules/BarcodePayment.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'PaymentMethodsModalWithActivator',
  props: {
  invoice: {
    type: Object as PropType<Invoice>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const openPaymentsModal = ref(false);

const barcode = computed(() => {
  return props.invoice.boleto?.line ?? "";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", {
      class: "payments-methods__activator link",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (openPaymentsModal.value = true))
    }, " Ver opções de pagamento "),
    (openPaymentsModal.value)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "payments-methods__modal__background",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (openPaymentsModal.value = false))
        }))
      : _createCommentVNode("", true),
    (openPaymentsModal.value)
      ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: "payments-methods__modal__close-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (openPaymentsModal.value = false))
            }, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("x")
                ])),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _cache[4] || (_cache[4] = _createElementVNode("p", { class: "payments-methods__modal__value-and-date__title" }, " Valor total a pagar ", -1)),
                _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formatMoney)(__props.invoice.total_value)), 1)
              ]),
              _createElementVNode("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("p", { class: "payments-methods__modal__value-and-date__title" }, " Vencimento ", -1)),
                _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(formatDate)(__props.invoice.due_date)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "payments-methods__modal__payment-method__title" }, " Pague com Pix ", -1)),
              _cache[7] || (_cache[7] = _createElementVNode("p", { class: "payments-methods__modal__payment-method__text" }, " Tenha sua fatura compensada imediatamente ou em até 2 horas. ", -1)),
              _createElementVNode("div", _hoisted_7, [
                _createVNode(PixPayment, { invoice: __props.invoice }, null, 8, ["invoice"])
              ])
            ]),
            _createElementVNode("div", _hoisted_8, [
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "payments-methods__modal__payment-method__title" }, " Pague com Boleto ", -1)),
              _cache[9] || (_cache[9] = _createElementVNode("p", { class: "payments-methods__modal__payment-method__text" }, " O pagamento pode levar até 2 dias úteis para ser processado. ", -1)),
              _createVNode(BarcodePayment, { barcode: barcode.value }, null, 8, ["barcode"])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})