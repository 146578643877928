import { defineStore } from "pinia";
import {
  useUserStore,
  useAuthStore,
  useInvoicesStore,
  usePersistentAuthStore,
} from "@/stores";
import { useSessionStorage } from "@vueuse/core";

import type { ComponentsName, VerificationMethod } from "@/types/LoginTypes";

import api from "@/api";
import { handleError } from "@/utils/handleError";
import { operationalInstallationStatuses } from "@/utils/installations";

const initialLoginState = () => ({
  step: "verify-cpf" as ComponentsName,
  cpf: "",
  phone: "",
  email: "",
  cpfError: "",
  shouldSendOtpCode: false,
  blockSmsVerification: false,
  verificationMethod: "SMS" as VerificationMethod,
  otpError: "",
  loadingOtp: false,
  isActiveUser: true,
});

export const useLoginStore = defineStore("login", {
  state: () =>
    useSessionStorage("@metha-energia/adc-login", initialLoginState()),
  actions: {
    async clear() {
      Object.assign(this, initialLoginState());
    },
    changeStatus(status: ComponentsName) {
      this.step = status;
    },
    setCpfError(message: string) {
      this.cpfError = message;
    },
    async updateCpf(cpf: string) {
      this.cpf = cpf;
    },
    async checkCpf(cpf: string) {
      const authStore = useAuthStore();
      const token = await authStore.getToken("loginStoreCheckCpf");

      const { data } = await api.login.checkCpf(
        cpf,
        {},
        { headers: { "g-recaptcha-response": token } }
      );

      return data;
    },
    async submitCpf() {
      try {
        const data = await this.checkCpf(this.cpf);

        if (!data.isActiveUser) {
          this.isActiveUser = false;
          return;
        }

        if (!data.phone || !data.email) {
          this.setCpfError("Este número de CPF não foi encontrado");
          return;
        }

        this.setUserInfo(data);
        this.shouldSendOtpCode = true;

        this.changeStatus("verify-otp");
      } catch (error) {
        handleError(error, this.setCpfError);
      }
    },
    setUserInfo(data: { phone: string; email: string }) {
      const sanitizedUser = { ...data };

      if (Array.isArray(data.email)) {
        sanitizedUser.email = data.email[0];
      }

      if (Array.isArray(data.phone)) {
        sanitizedUser.phone = data.phone[0];
      }

      Object.assign(this, sanitizedUser);
    },
    changeVerificationMethod() {
      if (this.loadingOtp) return;

      let type = "e-mail" as VerificationMethod;

      if (this.verificationMethod === "e-mail") {
        type = "SMS";
      }

      this.verificationMethod = type;
    },
    async sendOtpCode() {
      if (this.loadingOtp) return;

      try {
        this.shouldSendOtpCode = false;

        const authStore = useAuthStore();
        const token = await authStore.getToken("loginStoreSendOtpCode");

        this.setOtpError("");

        if (this.verificationMethod === "SMS" && !this.blockSmsVerification) {
          return api.login.verifySMS(
            {
              cpf: this.cpf,
              phone: this.phone.slice(-4),
            },
            {},
            { headers: { "g-recaptcha-response": token } }
          );
        } else {
          return api.login.verifyEmail(
            {
              cpf: this.cpf,
              email: this.email,
            },
            {},
            { headers: { "g-recaptcha-response": token } }
          );
        }
      } catch (error) {
        handleError(error, (err: string) => {
          console.log(err);
        });
      }
    },
    setOtpError(message: string) {
      this.otpError = message;
    },
    async validateOtpCode(code: string) {
      if (this.loadingOtp) return;
      this.loadingOtp = true;

      const userStore = useUserStore();
      const authStore = useAuthStore();

      const token = await authStore.getToken("loginStoreValidateOtpCode");

      const payload = {
        cpf: this.cpf,
        phone: this.phone.slice(-4),
        email: this.email,
        code,
      };

      userStore.setUser(payload);

      const headers = { "g-recaptcha-response": token };

      if (this.verificationMethod === "SMS") {
        const { data } = await api.login.authSMS(payload, {}, { headers });
        authStore.set(data);
      } else {
        const { data } = await api.login.authEmail(payload, {}, { headers });
        authStore.set(data);
      }

      await userStore.getUser();

      await authStore.getAuthForChat();
    },
    retrieveSelectedInstallation() {
      const userStore = useUserStore();
      const persistentAuthStore = usePersistentAuthStore();

      const lastLoginInstallationId =
        persistentAuthStore.getLastSessionInstallationId();

      let installation = null;

      if (userStore.operationalInstallations.length === 1) {
        installation = userStore.installations[0];
      } else {
        installation = userStore.findInstallationById(lastLoginInstallationId);
      }

      if (
        installation &&
        !operationalInstallationStatuses.includes(installation.status)
      ) {
        return null;
      }

      return installation;
    },
    async redirectToInstallationSelection() {
      const userStore = useUserStore();
      const invoicesStore = useInvoicesStore();

      const installation = this.retrieveSelectedInstallation();

      if (installation) {
        userStore.selectInstallation(installation);
        await invoicesStore.getAllInvoices(installation.id);

        this.redirect();
      } else {
        this.changeStatus("select-installation");
      }
    },
    redirect(path?: string) {
      const router = this.router;
      const query = router.currentRoute.value.query;

      if (query.redirect && typeof query.redirect === "string") {
        router.push(query.redirect);
      } else if (path) {
        router.push(path);
      } else {
        router.push("/");
      }
    },
  },
});
