import { AxiosRequestConfig } from "axios";
import request from "./request";
import { Query } from "./types";

export async function getAuthChat(
  userId: string,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "POST",
    url: "/zendesk-chat-auth",
    data: { userId },
    params,
    ...config,
  });
}
