import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed } from "vue";
import { useBreakpoints } from "@/libs/metha-components";
import {
  getInvoiceStatus,
  getInstallationStatus,
  getInvoiceStatusColor,
  getInstallationStatusColor,
  dangerStatuses,
} from "@/utils/status";


export default /*@__PURE__*/_defineComponent({
  __name: 'StatusTag',
  props: {
    status: {},
    invoice: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const { smAndDown } = useBreakpoints();

const statusTranslated = computed(() => {
  let translatedStatus = "";

  if (props.invoice) {
    translatedStatus = getInvoiceStatus(props.status);
  } else {
    translatedStatus = getInstallationStatus(props.status);
  }

  if (translatedStatus.toLowerCase() === "em aberto" && smAndDown.value) {
    return "aberta";
  }

  return translatedStatus;
});

const statusColor = computed(() => {
  if (props.invoice) {
    return getInvoiceStatusColor(props.status);
  }

  return getInstallationStatusColor(props.status);
});

const statusTextColor = computed(() => {
  if (dangerStatuses.includes(props.status.toLowerCase())) {
    return "#fff";
  }

  return "#000";
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      color: statusTextColor.value,
      'background-color': `rgba(${statusColor.value})`,
    }),
    class: "status-tag"
  }, _toDisplayString(statusTranslated.value), 5))
}
}

})