<script setup lang="ts">
import { nextTick, readonly, ref } from "vue";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";

import AcordeonSwitch from "@/components/molecules/AcordeonSwitch.vue";
import ServiceChannelItem from "@/components/molecules/ServiceChannelItem.vue";

const items = readonly([
  {
    icon: "nm-chat",
    title: "Chat",
    text: "O Chat é nosso principal canal de atendimento! Tire suas dúvidas e resolva o que precisar com a gente.",
    button_text: "Conversar no chat",
    url: null,
  },
  {
    icon: "nm-phone-ringing",
    title: "Telefone",
    text: "Alguma coisa importante que é melhor falar pelo telefone? Liga para a gente discando o número abaixo.",
    button_text: "(31) 3003-0531",
    url: "tel:+553130030531",
  },
  {
    icon: "nm-email",
    title: "E-mail",
    text: "Prefere um contato mais formal, e deixar tudo registrado por e-mail? Use o endereço abaixo.",
    button_text: "contato@methaenergia.com.br",
    url: "mailto:contato@methaenergia.com.br",
  },
]);

const userStore = useUserStore();

const serviceChannelsSectionTop = ref<HTMLElement>();

function scrollAfterOpeningMenu(cb: () => void) {
  cb();

  nextTick(() => {
    serviceChannelsSectionTop.value?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  });
}

function openChat() {
  userStore.openSunshineChat();
}
</script>

<template>
  <section
    style="padding-bottom: 1.5rem; scroll-margin: 77px"
    ref="serviceChannelsSectionTop"
  >
    <div class="adc__invoices__section service-channels">
      <h1 class="service-channels__title">Canais de atendimento</h1>
      <h2 class="service-channels__subtitle">Segunda a sexta de 09h as 19h</h2>
      <AcordeonSwitch>
        <template #default="{ handleClick }">
          <div class="service-channels__mobile-menu">
            <MeButton
              @click="openChat"
              block
              class="service-channels__mobile-menu__open-chat"
            >
              <span class="service-channels__mobile-menu__open-chat__icon">
                <MeIcon>nm-chat</MeIcon>
              </span>
              Conversar no chat
            </MeButton>
            <MeButton
              @click="() => scrollAfterOpeningMenu(handleClick)"
              variant="outline"
              block
            >
              Outros canais de atendimento
            </MeButton>
          </div>
        </template>
        <template #content="{ isOpen }">
          <div
            class="service-channels__contacts-container"
            :style="{ 'margin-top': isOpen ? '2rem' : '0' }"
          >
            <ServiceChannelItem
              v-for="(item, index) in items"
              :item="item"
              :key="`${index}`"
            />
          </div>
        </template>
        <template #retractor="{ handleClick }">
          <div
            @click="handleClick"
            class="service-channels__mobile-menu__retractor"
          >
            <MeIcon>chevron-bottom</MeIcon>
          </div>
        </template>
      </AcordeonSwitch>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.service-channels {
  border: 2px solid #ffcc12;
  border-radius: 1rem;

  margin: 1.5rem auto 0;
  padding: 1.5rem 2rem;

  @media (min-width: 993px) {
    margin: 0 auto;
    padding: 2rem 1.5rem;
  }

  &__title {
    margin-bottom: 0.5rem;
    color: #000;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1.75rem;
      line-height: normal;
      text-align: center;
    }
  }

  &__subtitle {
    color: #000;
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.75rem;

    margin-bottom: 1rem;

    @media (min-width: 993px) {
      font-size: 1.375rem;
      line-height: normal;
      text-align: center;

      margin-bottom: 2.5rem;
    }
  }

  &__contacts-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 0 auto 1.5rem;
    width: fit-content;
    max-width: 100%;

    @media (min-width: 993px) {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 1rem;
    }
  }

  &__mobile-menu {
    display: grid;
    gap: 1rem;

    &__open-chat {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      &__icon {
        font-size: 1.125rem;
        width: 18px;
        height: 18px;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__retractor {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      background: #ffcc12;
      padding: 4px;
      border: none;
      border-radius: 100%;
      width: 30px;
      height: 30px;
      font-size: 1.5rem;
      font-weight: bolder;

      transform: rotate(180deg);
    }
  }
}
</style>
