<script setup lang="ts">
import { navLinks } from "@/router/navLinks";

import MobileNavigationItem from "@/components/atoms/MobileNavigationItem.vue";
import { computed } from "vue";

const filteredNavLinks = computed(() => {
  return navLinks.slice(1);
});
</script>

<template>
  <nav class="mobile-navigation">
    <MobileNavigationItem
      v-for="{ icon, title, route } in filteredNavLinks"
      :key="`mobile-nav-icon-${title}`"
      :icon="icon"
      :title="title"
      :route="route"
    />
  </nav>
</template>

<style lang="scss" scoped>
.mobile-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;

  width: 100%;
  max-width: 450px;
  margin: 0 auto;
}
</style>
