import * as aws from "./aws";
import * as chat from "./chat";
import * as contract from "./contract";
import * as faq from "./faq";
import * as installation from "./installation";
import * as invoice from "./invoice";
import * as login from "./login";
import * as user from "./user";

export default {
  aws,
  chat,
  contract,
  faq,
  installation,
  invoice,
  login,
  user,
};
