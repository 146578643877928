import {
  CustomErrorEvent,
  CustomErrors,
  SetErrorMessageFunction,
} from "@/types/Error";

import { useUserStore, useWarningStore } from "@/stores";

import { emitNotification } from "@/utils/appComunication";

function handleError(
  error: unknown,
  setter: SetErrorMessageFunction,
  customErrors?: CustomErrors
) {
  const userStore = useUserStore();
  const warningStore = useWarningStore();

  const err = error as CustomErrorEvent;
  console.log("O erro assim como você o criou: (raw):\n");
  console.error(err);
  emitNotification("rawError", err);
  const ERRORS = {
    504: "Isso demorou mais que o esperado. Tente novamente",
    ...customErrors,
    default: err.response?.data?.error ?? String(error),
    network: "Erro na conexão com o servidor",
  };

  const errorCode = (
    err.response ? err.response?.status ?? "network" : "default"
  ) as keyof typeof ERRORS;

  if (err.response?.status === "401") {
    warningStore.setShowAuthenticationError(true);
    return userStore.logOutUser();
  }

  if (err.response?.data?.cause) {
    console.log("O erro, senhor: ", err.response.data.cause);
    emitNotification("error", err.response.data.cause);
    setter(err.response.data.cause);
  } else {
    console.log("O erro, senhor: ", ERRORS[errorCode] ?? ERRORS.default);
    emitNotification("error", ERRORS[errorCode] ?? ERRORS.default);
    setter(ERRORS[errorCode] ?? ERRORS.default);
  }
}

export { handleError };
