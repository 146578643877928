<script setup lang="ts">
import { defineProps } from "vue";
import { Article } from "@/types/Faq";

defineProps<{
  article: Article;
}>();

function insertLimitStyleOnHtml(html: string) {
  const limitedWidthIframe =
    '<iframe style="max-width: 100% !important; height: auto !important; aspect-ratio: 16/9 !important;"';

  const limitedWidthLink =
    '<a style="white-space: wrap !important; max-width: 100% !important;"';

  return html
    .replace("<iframe", limitedWidthIframe)
    .replace("<a ", limitedWidthLink);
}
</script>

<template>
  <h3 class="opened-article-content__title">{{ article.title }}</h3>
  <div
    class="opened-article-content__content"
    v-html="insertLimitStyleOnHtml(article.body)"
  ></div>
</template>

<style lang="scss" scoped>
.opened-article-content {
  &__title {
    margin: 1.5rem 0 1.125rem;

    color: #000;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 2.25rem;

    @media (min-width: 993px) {
      font-size: 1.875rem;
      line-height: 2.25rem;
    }

    @media (min-width: 1400px) {
      font-size: 2.25rem;
      line-height: 2.875rem;
    }
  }

  &__content {
    margin-bottom: 3.5rem;

    overflow-x: hidden;

    color: #000;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1.125rem;
      line-height: 2rem;
    }
  }
}
</style>
