<script setup lang="ts">
import { ref } from "vue";
import { Form } from "vee-validate";

import {
  MeButton,
  MeIcon,
  MeSelectInstallationCard,
  useBreakpoints,
} from "@/libs/metha-components";

import { useUserStore, useLoginStore, useInvoicesStore } from "@/stores";

import { FlatInstallation } from "@/types/InstallationTypes";

import { getSupplierLogo } from "@/utils/supplierlogo";
import { handleError } from "@/utils/handleError";
import { capitalize } from "@/utils/format";

const loginStore = useLoginStore();
const userStore = useUserStore();
const invoicesStore = useInvoicesStore();
const { mdAndDown } = useBreakpoints();

const selectedInstallation = ref<string | number>();
const selectInstallationError = ref("");

async function handleSubmit() {
  if (!selectedInstallation.value) return;

  try {
    userStore.loadingScreen = true;

    const installation = userStore.installations.find(
      (installation: FlatInstallation) =>
        installation.id === selectedInstallation.value
    );

    if (!installation) return;

    userStore.selectInstallation(installation);

    await invoicesStore.getAllInvoices(installation.id);

    userStore.loadingScreen = false;

    loginStore.redirect();
  } catch (error) {
    handleError(error, (err: string) => {
      selectInstallationError.value = err;
    });
  } finally {
    userStore.loadingScreen = false;
  }
}

function goBackToVerifyCpf() {
  userStore.logOutUser();
  loginStore.changeStatus("verify-cpf");
}
</script>

<template>
  <div class="login__container login-select-installation">
    <p
      class="login-select-installation__return me--link"
      @click="goBackToVerifyCpf"
      v-if="!mdAndDown"
    >
      <span class="login-select-installation__return__icon-container">
        <div class="login-select-installation__return__icon">
          <MeIcon> arrow-back </MeIcon>
        </div>
      </span>
      <span class="login-verify-otp__return__text">Voltar</span>
    </p>
    <Form class="login-select-installation__container" @submit="handleSubmit">
      <div class="login-select-installation__metha-logo">
        <img
          src="@/assets/brand/logo.svg"
          alt="Logo da Metha Energia"
          class="login-select-installation__metha-logo"
          width="205"
        />
      </div>
      <h2 class="login-select-installation__title">Instalações</h2>
      <p class="login-select-installation__register">
        Selecione uma instalação abaixo para continuar
      </p>
      <div class="login-select-installation__installations-container">
        <MeSelectInstallationCard
          v-for="{
            id,
            status,
            installation_number,
            address,
            supplier,
          } in userStore.operationalInstallations"
          :key="`login-select-installation-${installation_number}`"
          :status="status"
          :installationNumber="installation_number"
          :address="capitalize(address)"
          :clickable="id !== selectedInstallation"
          :supplierLogo="getSupplierLogo(supplier)"
          :selected="id === selectedInstallation"
          selectable
          @click="selectedInstallation = id"
        />
        <p
          class="login-select-installation--error"
          v-if="selectInstallationError && selectInstallationError.length > 0"
        >
          {{ selectInstallationError }}
        </p>
      </div>
      <MeButton
        block
        class="login-select-installation__submit-button"
        :disabled="!selectedInstallation"
      >
        Continuar
      </MeButton>
      <p
        class="login-select-installation__return me--link"
        @click="goBackToVerifyCpf"
        v-if="mdAndDown"
      >
        <span class="login-select-installation__return__icon-container">
          <div class="login-select-installation__return__icon">
            <MeIcon> arrow-back </MeIcon>
          </div>
        </span>
        <span class="login-verify-otp__return__text">Voltar</span>
      </p>
    </Form>
    <div></div>
  </div>
</template>

<style lang="scss" scoped>
.login-select-installation {
  overflow-y: auto;

  @media (min-width: 993px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50vw;
  }

  &__return {
    width: 100%;
    max-width: 495px;
    margin: 1.5rem auto 0;

    display: flex;
    align-items: flex-start;
    gap: 0.5rem;

    color: #232326;
    font-size: 1rem;
    font-weight: 600;
    line-height: normal;

    @media (min-width: 993px) {
      margin-top: 0;
      padding-left: 1.5rem;
    }

    &__icon {
      position: relative;
      width: 24px;
      height: 24px;
      font-size: 1.125rem;

      i {
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
      }
    }

    &__text {
      padding-top: 2px;

      @media (min-width: 768px) {
        padding-top: 0;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 495px;
    margin: 0 auto;
    padding: 1.5rem;
  }

  &__metha-logo {
    width: 230px;
    margin: 0 auto;

    @media (min-width: 993px) {
      width: 205px;
      margin: 0;
    }
  }

  &__title {
    color: #000;
    font-feature-settings: "calt" off;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 2.25rem;

    margin: 2.5rem 0 0.5rem;

    @media (min-width: 993px) {
      font-size: 2rem;
      margin: 4rem 0 1.5rem;
    }
  }

  &__register {
    color: #7a7a7a;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1.5rem;

    @media (min-width: 993px) {
      font-size: 1.125rem;
      line-height: 1.875rem;
      letter-spacing: -0.0225rem;
    }
  }

  &__installations-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
    justify-content: center;

    margin: 1.5rem 0 2rem;

    @media (min-width: 993px) {
      margin: 2.5rem 0;
    }
  }

  &__submit-button {
    width: 100%;
  }
}
</style>
