import { createApp } from "vue";
import { createGtm } from "@gtm-support/vue-gtm";
import App from "./App.vue";
import router from "./router";
import pinia from "./stores";

import "@metha-energia/icons";
import "@/assets/styles/main.scss";
import "vue3-toastify/dist/index.css";
import "./vee-validate";
import "@vee-validate/rules";

import { MethaPreset } from "@/libs/metha-components";
import { ThemePlugin } from "@/libs/metha-components";

import Maska from "maska";
import Vue3Toastify, { type ToastContainerOptions } from "vue3-toastify";
import ClickOutside from "@/directives/click-outside";

declare global {
  export interface Window {
    nsWebViewInterface?: {
      emit: (event: string, data: any) => void;
      on: (event: string, eventHandler: (arg?: any) => void) => void;
    };
  }
}

const app = createApp(App)
  .directive("click-outside", ClickOutside)
  .use(pinia)
  .use(Maska)
  .use(Vue3Toastify, {
    autoClose: 5000,
  } as ToastContainerOptions)
  .use(ThemePlugin, MethaPreset)
  .use(router)
  .use(createGtm({ id: "GTM-5CMNXJGC" }));

app.mount("#app");
