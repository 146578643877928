import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "warning-modal" }
const _hoisted_2 = {
  key: 0,
  class: "warning-modal__icon"
}
const _hoisted_3 = { class: "warning-modal__title" }
const _hoisted_4 = ["innerHTML"]


import { MeIcon, MeButton } from "@/libs/metha-components";
import CaModal from "@/components/atoms/CaModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WarningModal',
  props: {
    title: {},
    text: {},
    icon: {},
    closeButtonText: {},
    closeModal: { type: Function }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(CaModal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "warning-modal__close-button",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
        }, [
          _createVNode(_unref(MeIcon), null, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createTextVNode("x")
            ])),
            _: 1
          })
        ]),
        (_ctx.icon && _ctx.icon.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.icon), 1)
                ]),
                _: 1
              })
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.title), 1),
        _createElementVNode("p", {
          class: "warning-modal__text",
          innerHTML: _ctx.text
        }, null, 8, _hoisted_4),
        (_ctx.closeButtonText && _ctx.closeButtonText.length)
          ? (_openBlock(), _createBlock(_unref(MeButton), {
              key: 1,
              variant: "outline",
              onClick: _ctx.closeModal
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.closeButtonText), 1)
              ]),
              _: 1
            }, 8, ["onClick"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }))
}
}

})