<script setup lang="ts">
import { useBreakpoints } from "@/libs/metha-components";
import { useUserStore } from "@/stores";

import LoggedIn from "@/layouts/LoggedIn.vue";
import LayoutHeader from "@/components/organisms/LayoutHeader.vue";
import LayoutSidebar from "@/components/organisms/LayoutSidebar.vue";
import SkeletonLoading from "@/components/organisms/SkeletonLoading.vue";
import { computed } from "vue";
import { useRoute } from "vue-router";

const route = useRoute();
const userStore = useUserStore();
const { lgAndUp } = useBreakpoints();

const notificationNumber = 3;

const hideInstallationSwitchComponent = computed(() => {
  return route.meta.hideInstallationSwitchComponent ?? false;
});

const rounded = computed(() => {
  return route.meta.rounded ?? false;
});
</script>

<template>
  <LoggedIn>
    <aside class="sidebar">
      <LayoutSidebar v-if="lgAndUp" :notificationNumber="notificationNumber" />
    </aside>
    <header class="header">
      <LayoutHeader
        :title="userStore.pageTitle"
        :notificationNumber="notificationNumber"
        :hideInstallationSwitchComponent="hideInstallationSwitchComponent"
      />
    </header>
    <main class="main">
      <div :class="['main__content', { 'main__content--rounded': rounded }]">
        <SkeletonLoading v-show="userStore.pageLoading" />
        <slot></slot>
      </div>
    </main>
  </LoggedIn>
</template>
