import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "switch-stacked-cards"
}

import { ref } from "vue";
import { useBreakpoints } from "@/libs/metha-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'SwitchStackedCards',
  setup(__props) {

const { lgAndUp } = useBreakpoints();

const alternated = ref(false);

function alternateDisplayedCard(eventCaller: "default" | "secondary") {
  const calledByDefault = eventCaller === "default";

  if (
    (calledByDefault && !alternated.value) ||
    (!calledByDefault && alternated.value)
  )
    return;

  alternated.value = !alternated.value;
}

return (_ctx: any,_cache: any) => {
  return (_unref(lgAndUp))
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        _renderSlot(_ctx.$slots, "default"),
        _renderSlot(_ctx.$slots, "secondary")
      ], 64))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          onClick: _cache[0] || (_cache[0] = () => alternateDisplayedCard('default')),
          class: _normalizeClass(`switch-stacked-cards__card switch-stacked-cards__card--${
        alternated.value ? 'inactive' : 'active'
      }`)
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 2),
        _createElementVNode("div", {
          onClick: _cache[1] || (_cache[1] = () => alternateDisplayedCard('secondary')),
          class: _normalizeClass(`switch-stacked-cards__card switch-stacked-cards__card--${
        alternated.value ? 'active' : 'inactive'
      }`)
        }, [
          _renderSlot(_ctx.$slots, "secondary", _normalizeProps(_guardReactiveProps({ active: alternated.value })))
        ], 2)
      ]))
}
}

})