<script setup lang="ts">
import { onBeforeMount } from "vue";
import { toast } from "vue3-toastify";

import LoginSteps from "@/components/organisms/LoginSteps.vue";
import {
  useAuthStore,
  useLoginStore,
  usePersistentAuthStore,
  useUserStore,
  useWarningStore,
} from "@/stores";
import { emitNotification } from "@/utils/appComunication";

const loginStore = useLoginStore();
const userStore = useUserStore();
const authStore = useAuthStore();
const persistentAuthStore = usePersistentAuthStore();
const warningStore = useWarningStore();

onBeforeMount(async () => {
  emitNotification("websiteIsLoaded");
  const showWarning = warningStore.showAuthenticationError ?? false;
  if (showWarning) {
    toast.error(
      `Falha na autenticação do usuário. Favor fazer login novamente.`,
      { position: toast.POSITION.BOTTOM_CENTER }
    );
    warningStore.setShowAuthenticationError(false);
  } else {
    try {
      const tokenData = persistentAuthStore.getJwt();
      const cpf = persistentAuthStore.getDocument();

      if (tokenData && cpf) {
        userStore.loadingScreen = true;
        const { token, token_exp_date_in_milliseconds } = tokenData;

        authStore.set({ token, token_exp_date_in_milliseconds });
        userStore.cpf = cpf;

        await userStore.getUser();
        await authStore.getAuthForChat();

        await loginStore.redirectToInstallationSelection();
      }
    } catch (_) {
      persistentAuthStore.logOut();
      loginStore.changeStatus("verify-cpf");
    } finally {
      userStore.loadingScreen = false;
    }
  }
});
</script>

<template>
  <div class="login-page">
    <LoginSteps />
    <div class="login-page__side">
      <div class="login-page__side__wrapper">
        <img
          src="@/assets/brand/logo-icon.svg"
          alt=""
          class="login-page__side__logo"
        />
        <img
          src="@/assets/gifs/login-title.gif"
          alt="Sua empresa de energia do futuro"
          class="login-page__side__title"
        />
      </div>
      <img
        class="login-page__side__image"
        src="@/assets/images/login-side-image.png"
        alt="Celulares com a área do cliente Metha"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.login-page {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  overflow: auto;

  &__side {
    @media (max-width: 992px) {
      display: none;
    }

    background: url("@/assets/images/login-side-image-background.png");
    background-size: cover;
    background-position: bottom left;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 5rem 0;
    height: 100%;
    width: 50vw;

    &__wrapper {
      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    &__logo {
      -webkit-filter: invert(100%);
      filter: invert(100%);
      width: auto;
      height: 46px;
    }

    &__title {
      width: auto;
      height: 54px;
    }

    &__image {
      width: 100%;
      max-width: 437px;
      margin: auto;
    }
  }
}
</style>
