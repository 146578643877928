import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import auth from "@/middlewares/auth";

import { initializeSmooch, disableSmooch } from "@/plugins/sunshineChat";
import { validateJwtToken } from "@/utils/validateJwt";

import DefaultLayout from "@/layouts/DefaultLayout.vue";
import NotLoggedInLayout from "@/layouts/NotLoggedInLayout.vue";

import HomePage from "@/pages/HomePage.vue";
import LoginPage from "@/pages/LoginPage.vue";
import InvoicesPage from "@/pages/InvoicesPage.vue";
import ViewInvoicePage from "@/pages/ViewInvoicePage.vue";
import HelpCenterPage from "@/pages/HelpCenterPage.vue";
import FaqArticle from "@/pages/FaqArticle.vue";
import InstallationsPage from "@/pages/InstallationsPage.vue";
// import MyAccountPage from "@/pages/MyAccountPage.vue";

import { emitNotification } from "@/utils/appComunication";
import { nextTick } from "vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomePage,
    meta: {
      layout: DefaultLayout,
      title: "Início",
    },
    beforeEnter: [auth],
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      layout: NotLoggedInLayout,
      title: "Login",
    },
  },
  {
    path: "/faturas",
    name: "invoices",
    component: InvoicesPage,
    meta: {
      layout: DefaultLayout,
      title: "Minhas Faturas",
      rounded: true,
    },
    beforeEnter: [auth],
  },
  {
    path: "/faturas/:id",
    name: "invoice_details",
    component: ViewInvoicePage,
    meta: {
      layout: DefaultLayout,
      title: "Detalhes da fatura",
      rounded: true,
    },
    beforeEnter: [auth],
  },
  {
    path: "/central-de-ajuda",
    name: "help_center",
    component: HelpCenterPage,
    meta: {
      layout: DefaultLayout,
      title: "Central de ajuda",
      hideInstallationSwitchComponent: true,
    },
    beforeEnter: [auth],
  },
  {
    path: "/central-de-ajuda/:id",
    name: "faq_article",
    component: FaqArticle,
    meta: {
      layout: DefaultLayout,
      title: "Central de ajuda - Artigo",
      hideInstallationSwitchComponent: true,
    },
    beforeEnter: [auth],
  },
  {
    path: "/instalacoes",
    name: "installations",
    component: InstallationsPage,
    meta: {
      layout: DefaultLayout,
      title: "Minhas Instalações",
      hideInstallationSwitchComponent: true,
    },
  },
  // {
  //   path: "/conta",
  //   name: "my_account",
  //   component: MyAccountPage,
  //   meta: {
  //     layout: DefaultLayout,
  //     title: "Minha Conta",
  //     rounded: true,
  //     hideInstallationSwitchComponent: true,
  //   },
  //   beforeEnter: [auth],
  // },
  { path: "/:patchMatch(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    }

    window.scrollTo(0, 0);
  },
});

router.beforeEach(async (to) => {
  if (to.meta?.title && typeof to.meta.title === "string") {
    document.title = `${to.meta.title} - Metha Energia`;
  } else {
    document.title = "Metha Energia";
  }

  if (to.path !== "/login") {
    await validateJwtToken();
  }

  if (to.path.includes("/central-de-ajuda")) {
    const initializeOpened = to.fullPath.includes("chat=open");
    await initializeSmooch(initializeOpened);
  } else {
    await disableSmooch();
  }
});

router.afterEach(() => {
  const previousPage = window.history.state.back ?? "/login";
  const enableCanGoBackButton = !previousPage.includes("/login");
  emitNotification("updateCanGoBackAvailability", enableCanGoBackButton);

  nextTick(() => {
    const greeting = document.querySelector(".me-header__greeting");
    greeting?.scrollIntoView({ block: "end" });
  });
});

export default router;
