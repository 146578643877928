let timer: ReturnType<typeof setTimeout>;

function debounce(fn: () => void, wait: number) {
  return (...args: []) => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn(...args);
    }, wait);
  };
}

export default debounce;
