import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "sustainability-statistics" }
const _hoisted_2 = { class: "sustainability-statistics__header" }
const _hoisted_3 = { class: "sustainability-statistics__header__switch" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "sustainability-statistics__text" }
const _hoisted_6 = ["innerHTML"]

import { computed, markRaw, ref } from "vue";
import { MeIcon } from "@/libs/metha-components";

import {
  getSustainabilityCardText,
  getSustainabilityCardValue,
} from "@/utils/sustainabilityCard";


export default /*@__PURE__*/_defineComponent({
  __name: 'SustainabilityStatistics',
  props: {
  kwhConsumption: {
    type: Number,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const switchCardsOptions = markRaw([
  {
    icon: "nm-reduction-co2",
    type: "co2",
  },
  {
    icon: "nm-plant",
    type: "trees",
  },
  {
    icon: "nm-rising-sun",
    type: "coal",
  },
]);

const type = ref<"co2" | "trees" | "coal">("co2");

const cardValue = computed(() => {
  return getSustainabilityCardValue(type.value, props.kwhConsumption);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h2", { class: "sustainability-statistics__header__title" }, "Sustentabilidade", -1)),
      _createElementVNode("nav", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(switchCardsOptions), (option) => {
          return (_openBlock(), _createElementBlock("div", {
            key: `sustainability-statistics-switch-nav-item-${option.type}`,
            class: _normalizeClass([
            `sustainability-statistics__header__switch__item sustainability-statistics__header__switch__item--${option.type}`,
            {
              'sustainability-statistics__header__switch__item--active':
                type.value === option.type,
            },
          ]),
            onClick: ($event: any) => (type.value = option.type)
          }, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(option.icon), 1)
              ]),
              _: 2
            }, 1024)
          ], 10, _hoisted_4))
        }), 128))
      ])
    ]),
    _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(getSustainabilityCardText)(type.value)), 1),
    _createElementVNode("p", {
      class: _normalizeClass([
        'sustainability-statistics__value',
        {
          'sustainability-statistics__value--sub-margin-remove':
            cardValue.value.match('<sub>'),
        },
      ]),
      innerHTML: cardValue.value
    }, null, 10, _hoisted_6)
  ]))
}
}

})