import { defineStore } from "pinia";
import { useSessionStorage } from "@vueuse/core";
import { toast } from "vue3-toastify";

import faqApi from "@/api/faq";
import { handleError } from "@/utils/handleError";
import { Section, Article, SearchedArticle } from "@/types/Faq";
import { useUserStore } from "./user";
import { useAuthStore } from "./auth";

const initialFaqState = () => ({
  categoryId: "" as string | number | null,
  sections: [] as Section[],
  currentSection: "",
  currentSectionArticles: [] as Article[],
  currentArticle: null as Article | null,
  gettingArticleError: null as string | null,
  loadingArticle: false,
  searchResults: [] as SearchedArticle[],
  searchRequestMade: false,
});

export const useFaqStore = defineStore("faq", {
  state: () => useSessionStorage("@metha-energia/adc-faq", initialFaqState()),
  actions: {
    async clear() {
      Object.assign(this, initialFaqState());
    },
    setCategoryId(categoryId?: string | number | null) {
      this.categoryId = categoryId ?? null;
    },
    setSections(sections: Section[]) {
      this.sections = sections;
    },
    cleanSelectedArticle() {
      this.currentSection = "";
      this.currentArticle = null;
      this.currentSectionArticles = [];
    },
    clearSearchResults() {
      this.searchResults = [];
    },
    resetSearchBar() {
      this.searchResults = [];
      this.searchRequestMade = false;
    },
    async setCurrentSection(sectionId: number) {
      try {
        const currentSection = this.sections.find(({ id }) => id === sectionId);

        if (!currentSection) throw new Error();

        this.currentSection = currentSection.name;
        this.currentSectionArticles = await this.getArticles(currentSection.id);
      } catch (error) {
        toast.error("Não foi encontrada a seção selecionada. Tente novamente", {
          position: toast.POSITION.BOTTOM_CENTER,
        });
      }
    },
    async getHelpCenterCategory() {
      try {
        const categoryId = process.env.VUE_APP_FAQ_CATEGORY_ID;

        this.setCategoryId(categoryId);
      } catch (error) {
        this.setCategoryId();
        toast.error(
          "Não foi possível carregar o FAQ. Tente novamente por favor.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
      }
    },
    async getSections(categoryId: string | number) {
      try {
        const { data } = await faqApi.getFaqSections(categoryId);
        const helpCenterSections = data.sections;

        this.setSections(helpCenterSections);
      } catch (error) {
        this.setCategoryId();
        toast.error(
          "Não foi possível carregar o FAQ. Tente novamente por favor.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
      }
    },
    async getArticles(sectionId: number) {
      try {
        const { data } = await faqApi.getFaqArticles(sectionId);
        const sectionArticles = data.articles;

        return sectionArticles;
      } catch (error) {
        this.setCategoryId();
        toast.error(
          "Não foi possível carregar o FAQ. Tente novamente por favor.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
        return [];
      }
    },
    async getArticleContent(articleId: number) {
      try {
        this.loadingArticle = true;
        this.gettingArticleError = null;
        const { data } = await faqApi.getFaqArticleContent(articleId);

        this.currentArticle = data.article;
      } catch (error) {
        this.currentArticle = null;
        this.gettingArticleError =
          "Não foi possível carregar o artigo selecionado. Tente novamente por favor.";
        handleError(error, (err: string) => {
          toast.error(err, { position: toast.POSITION.BOTTOM_CENTER });
        });
      } finally {
        this.loadingArticle = false;
      }
    },
    async voteArticle(articleId: number, vote: "up" | "down") {
      const userStore = useUserStore();
      const authStore = useAuthStore();

      const userId = userStore.id;
      const recaptchaToken = await authStore.getToken(
        `voteArticle${articleId}`
      );

      await faqApi.voteArticle(articleId, vote, userId, {
        headers: {
          Authorization: authStore.get(),
          "g-recaptcha-response": recaptchaToken,
        },
      });
    },
    async searchArticles(param: string) {
      try {
        const categoryId = this.categoryId ?? 0;
        const { data } = await faqApi.searchArticles(param, categoryId);
        const mostRelevantResults = data.results.slice(0, 3);
        this.searchRequestMade = true;
        this.searchResults = mostRelevantResults;
      } catch (error) {
        this.searchRequestMade = false;
        this.searchResults = [];
        toast.error(
          "Ocorreu um erro ao realizar sua pesquisa. Tente novamente por favor.",
          { position: toast.POSITION.BOTTOM_CENTER }
        );
      }
    },
  },
});
