import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "path-indicator" }
const _hoisted_2 = { class: "path-indicator__text" }
const _hoisted_3 = {
  key: 0,
  class: "path-indicator__icon"
}


import { MeIcon } from "@/libs/metha-components";


export default /*@__PURE__*/_defineComponent({
  __name: 'PathIndicator',
  props: {
    items: {}
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, {
        key: `path-indicator-${item}-${index}`
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(item), 1),
        (index < _ctx.items.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, [
              _createVNode(_unref(MeIcon), null, {
                default: _withCtx(() => _cache[0] || (_cache[0] = [
                  _createTextVNode("chevron-right")
                ])),
                _: 1
              })
            ]))
          : _createCommentVNode("", true)
      ], 64))
    }), 128))
  ]))
}
}

})