import { AxiosRequestConfig } from "axios";
import request from "./request";
import { Query } from "./types";

export async function getUser(
  data: { cpf: string },
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "GET",
    url: `/register/${data.cpf}`,
    data,
    params,
    ...config,
  });
}

export async function updateUser(
  // cpf: string,
  data: any,
  params: Query = {},
  config: AxiosRequestConfig = {}
) {
  return request({
    method: "PATCH",
    // url: `/register/${cpf}`,
    url: `/register`,
    data,
    params,
    ...config,
  });
}
