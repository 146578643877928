import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "opened-article-content__title" }
const _hoisted_2 = ["innerHTML"]


import { Article } from "@/types/Faq";


export default /*@__PURE__*/_defineComponent({
  __name: 'OpenedArticleContent',
  props: {
    article: {}
  },
  setup(__props: any) {



function insertLimitStyleOnHtml(html: string) {
  const limitedWidthIframe =
    '<iframe style="max-width: 100% !important; height: auto !important; aspect-ratio: 16/9 !important;"';

  const limitedWidthLink =
    '<a style="white-space: wrap !important; max-width: 100% !important;"';

  return html
    .replace("<iframe", limitedWidthIframe)
    .replace("<a ", limitedWidthLink);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h3", _hoisted_1, _toDisplayString(_ctx.article.title), 1),
    _createElementVNode("div", {
      class: "opened-article-content__content",
      innerHTML: insertLimitStyleOnHtml(_ctx.article.body)
    }, null, 8, _hoisted_2)
  ], 64))
}
}

})