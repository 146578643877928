<script setup lang="ts">
import { defineProps } from "vue";
import { MeButton } from "@/libs/metha-components";
import { Invoice } from "@/types/InvoiceTypes";
import downloadInvoice from "@/utils/downloadInvoice";

const props = defineProps<{
  invoice: Invoice;
  disabled: boolean;
  url?: string;
}>();
</script>

<template>
  <div v-if="invoice.s3_url">
    <MeButton
      @click="
        () =>
          downloadInvoice(
            props.invoice.s3_url,
            `Fatura Metha ${props.invoice.reference_month} - ${props.invoice.installation_number}`
          )
      "
      block
    >
      Abrir PDF da fatura
    </MeButton>
  </div>
</template>
