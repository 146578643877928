import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "sidebar-tabs__icon"
}

import { computed } from "vue";
import { useRouter } from "vue-router";

import { MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";


export default /*@__PURE__*/_defineComponent({
  __name: 'SidebarTabs',
  props: {
  icon: String,
  title: {
    type: String,
    required: true,
  },
  route: {
    type: String,
    required: true,
  },
  active: Boolean,
},
  setup(__props) {

const props = __props;

const router = useRouter();
const userStore = useUserStore();

const hasIcon = computed(() => {
  return typeof props.icon === "string" && props.icon.trim().length > 0;
});

function redirect() {
  if (props.active) return;
  userStore.switchSidebarMenu();
  router.push(props.route);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([
      'sidebar-tabs',
      {
        'sidebar-tabs--active': __props.active,
        'sidebar-tabs--clickable': !__props.active,
      },
    ]),
    onClick: redirect
  }, [
    (hasIcon.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_1, [
          _createVNode(_unref(MeIcon), null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(__props.icon), 1)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(__props.title), 1)
  ], 2))
}
}

})