import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "loading-screen__background" }
const _hoisted_2 = { class: "loading-screen__spinner-container relative" }

import Spinner from "@/components/atoms/CaSpinner.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'LoadingScreen',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(Spinner, { size: 100 })
    ])
  ]))
}
}

})