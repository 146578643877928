<script setup lang="ts">
import { defineProps, computed } from "vue";
import { useBreakpoints } from "@/libs/metha-components";
import {
  getInvoiceStatus,
  getInstallationStatus,
  getInvoiceStatusColor,
  getInstallationStatusColor,
  dangerStatuses,
} from "@/utils/status";

const props = defineProps<{
  status: string;
  invoice?: boolean;
}>();

const { smAndDown } = useBreakpoints();

const statusTranslated = computed(() => {
  let translatedStatus = "";

  if (props.invoice) {
    translatedStatus = getInvoiceStatus(props.status);
  } else {
    translatedStatus = getInstallationStatus(props.status);
  }

  if (translatedStatus.toLowerCase() === "em aberto" && smAndDown.value) {
    return "aberta";
  }

  return translatedStatus;
});

const statusColor = computed(() => {
  if (props.invoice) {
    return getInvoiceStatusColor(props.status);
  }

  return getInstallationStatusColor(props.status);
});

const statusTextColor = computed(() => {
  if (dangerStatuses.includes(props.status.toLowerCase())) {
    return "#fff";
  }

  return "#000";
});
</script>

<template>
  <div
    :style="{
      color: statusTextColor,
      'background-color': `rgba(${statusColor})`,
    }"
    class="status-tag"
  >
    {{ statusTranslated }}
  </div>
</template>

<style lang="scss" scoped>
.status-tag {
  border: none;
  border-radius: 5rem;
  text-align: center;
  width: 100%;
  height: min-content;
  align-self: center;
  text-transform: lowercase;

  padding: 0.125rem 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5rem;

  @media (min-width: 993px) {
    padding: 0.25rem 0.62rem;
    font-size: 1.125rem;
  }
}
</style>
