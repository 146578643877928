<script setup lang="ts">
import { computed, onBeforeMount } from "vue";
import { useUserStore } from "@/stores";

import { formatCPF } from "@/utils/format";
import { orderInstallations } from "@/utils/orderInstallations";

import InstallationCard from "@/components/organisms/InstallationCard.vue";

const userStore = useUserStore();

const orderedInstallations = computed(() => {
  return orderInstallations(userStore.installations);
});

onBeforeMount(() => {
  userStore.setPageTitle("Minhas Instalações");
});
</script>

<template>
  <div class="installations-page">
    <section
      class="adc__invoices__section installations-page__client-information"
    >
      <p>
        <span class="installations-page__client-information__label">
          Titular:
        </span>
        {{ userStore.name }}
      </p>
      <p>
        <span class="installations-page__client-information__label">
          Cpf:
        </span>
        {{ formatCPF(userStore.cpf) }}
      </p>
    </section>
    <section
      class="adc__invoices__section installations-page__installations-list"
    >
      <InstallationCard
        v-for="installation in orderedInstallations"
        :key="`installation-list-${installation.id}`"
        :installation="installation"
      />
    </section>
  </div>
</template>

<style lang="scss" scoped>
.installations-page {
  display: flex;
  flex-direction: column;

  gap: 1rem;
  padding: 0 0 3rem 0;

  @media (min-width: 993px) {
    gap: 1.5rem;
    padding: 0 0 3rem 0;
  }

  &__client-information {
    background-color: transparent;
    text-transform: capitalize;

    color: #000;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.5rem;
    padding: 0;

    @media (min-width: 993px) {
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.5rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.5rem;
    }

    &__label {
      font-weight: 500;
      text-transform: uppercase;
      margin-right: 5px;

      @media (min-width: 993px) {
        color: #6c757d;
      }
    }
  }

  &__installations-list {
    background: transparent;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    padding: 0;

    @media (min-width: 993px) {
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
    }
  }
}
</style>
