import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

import { onMounted } from "vue";
import { MeApp } from "@/libs/metha-components";

import { useContractStore, useUserStore, useWarningStore } from "@/stores";

import WarningModal from "@/components/molecules/WarningModal.vue";
import MethaContract from "@/components/organisms/MethaContract.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoggedIn',
  setup(__props) {

const userStore = useUserStore();
const contractStore = useContractStore();
const warningStore = useWarningStore();

onMounted(() => {
  contractStore.clear();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(MeApp), { id: "me-app" }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      (_unref(warningStore).showSupplierWarning)
        ? (_openBlock(), _createBlock(WarningModal, {
            key: 0,
            title: "Atenção!",
            text: "Caso a sua <b>fatura com referência a Março/24</b> ainda não tenha sido disponibilizada, não se preocupe. Ela será enviada em breve! A concessionária realizou um refaturamento e por isso os dados foram disponibilizados de forma mais tardia.",
            icon: "alert",
            closeButtonText: "Entendi",
            closeModal: _unref(warningStore).closeSupplierWarning
          }, null, 8, ["closeModal"]))
        : _createCommentVNode("", true),
      (_unref(warningStore).showBusyChatWarning && _unref(userStore).calledOpenChat)
        ? (_openBlock(), _createBlock(WarningModal, {
            key: 1,
            title: "Atenção!",
            text: "Em virtude das atualizações em nossas plataformas de atendimento/área do cliente para trazer mais qualidade, estamos enfrentando uma alta demanda, por isso o tempo de retorno pode ser maior do que o usual. A sua dúvida será respondida assim que possível! Agradecemos a sua compreensão.",
            icon: "alert",
            closeButtonText: "Entendi",
            closeModal: _unref(warningStore).closeBusyChatWarning
          }, null, 8, ["closeModal"]))
        : _createCommentVNode("", true),
      (_unref(contractStore).open)
        ? (_openBlock(), _createBlock(MethaContract, { key: 2 }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})