export function calculate(kwh_consumption: number) {
  return {
    kwh_consumption,
    get co2_kg() {
      return +(this.kwh_consumption * 0.385).toFixed(2);
    },
    get co2() {
      return +(this.kwh_consumption * 0.000385).toFixed(2);
    },
    get trees() {
      return Math.round((this.co2_kg / 1000) * 7.14);
    },
    get coal() {
      return +(this.kwh_consumption / 7.353).toFixed(2);
    },
  };
}
