import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/brand/logo-icon.svg'
import _imports_1 from '@/assets/gifs/login-title.gif'
import _imports_2 from '@/assets/images/login-side-image.png'


const _hoisted_1 = { class: "login-page" }

import { onBeforeMount } from "vue";
import { toast } from "vue3-toastify";

import LoginSteps from "@/components/organisms/LoginSteps.vue";
import {
  useAuthStore,
  useLoginStore,
  usePersistentAuthStore,
  useUserStore,
  useWarningStore,
} from "@/stores";
import { emitNotification } from "@/utils/appComunication";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginPage',
  setup(__props) {

const loginStore = useLoginStore();
const userStore = useUserStore();
const authStore = useAuthStore();
const persistentAuthStore = usePersistentAuthStore();
const warningStore = useWarningStore();

onBeforeMount(async () => {
  emitNotification("websiteIsLoaded");
  const showWarning = warningStore.showAuthenticationError ?? false;
  if (showWarning) {
    toast.error(
      `Falha na autenticação do usuário. Favor fazer login novamente.`,
      { position: toast.POSITION.BOTTOM_CENTER }
    );
    warningStore.setShowAuthenticationError(false);
  } else {
    try {
      const tokenData = persistentAuthStore.getJwt();
      const cpf = persistentAuthStore.getDocument();

      if (tokenData && cpf) {
        userStore.loadingScreen = true;
        const { token, token_exp_date_in_milliseconds } = tokenData;

        authStore.set({ token, token_exp_date_in_milliseconds });
        userStore.cpf = cpf;

        await userStore.getUser();
        await authStore.getAuthForChat();

        await loginStore.redirectToInstallationSelection();
      }
    } catch (_) {
      persistentAuthStore.logOut();
      loginStore.changeStatus("verify-cpf");
    } finally {
      userStore.loadingScreen = false;
    }
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(LoginSteps),
    _cache[0] || (_cache[0] = _createStaticVNode("<div class=\"login-page__side\" data-v-6af20adb><div class=\"login-page__side__wrapper\" data-v-6af20adb><img src=\"" + _imports_0 + "\" alt=\"\" class=\"login-page__side__logo\" data-v-6af20adb><img src=\"" + _imports_1 + "\" alt=\"Sua empresa de energia do futuro\" class=\"login-page__side__title\" data-v-6af20adb></div><img class=\"login-page__side__image\" src=\"" + _imports_2 + "\" alt=\"Celulares com a área do cliente Metha\" data-v-6af20adb></div>", 1))
  ]))
}
}

})