import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/brand/logo.svg'


const _hoisted_1 = { class: "login-verify-cpf__container" }
const _hoisted_2 = { class: "login-verify-cpf__register" }
const _hoisted_3 = {
  class: "me--link",
  href: "https://cadastro.methaenergia.com.br/"
}
const _hoisted_4 = {
  key: 0,
  class: "login-verify-cpf__not-active-error"
}
const _hoisted_5 = { class: "login-verify-cpf__register-button" }

import { ref, watch, computed } from "vue";

import { MeTextField, MeButton, useBreakpoints } from "@/libs/metha-components";
import { Form } from "vee-validate";

import { useLoginStore, useUserStore } from "@/stores";

import { validateCPF } from "@/utils/validate";


export default /*@__PURE__*/_defineComponent({
  __name: 'LoginVerifyCpf',
  setup(__props) {

const loginStore = useLoginStore();
const userStore = useUserStore();
const { mdAndDown } = useBreakpoints();

const cpf = ref("");
const loading = ref(false);

const invalidSubmit = computed(
  () =>
    !!(
      loading.value ||
      cpf.value.length !== 14 ||
      loginStore.cpfError.length ||
      !loginStore.isActiveUser
    )
);

async function handleSubmit() {
  if (invalidSubmit.value) return;

  loading.value = true;
  userStore.loadingScreen = true;

  await loginStore.submitCpf();

  loading.value = false;
  userStore.loadingScreen = false;
}

function redirectToMethaPwa() {
  window.location.href = "https://cadastro.methaenergia.com.br/";
}

watch(
  cpf,
  async (value: string) => {
    const normalizedCPF = value.replace(/\D/gm, "");
    loginStore.setCpfError("");
    loginStore.isActiveUser = true;

    if (normalizedCPF.length < 11) return;

    if (!validateCPF(normalizedCPF)) {
      loginStore.setCpfError("É necessário digitar um número de CPF válido");
    } else {
      loginStore.updateCpf(normalizedCPF);
    }
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createBlock(_unref(Form), {
    class: "login__container login-verify-cpf",
    onSubmit: handleSubmit
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "login-verify-cpf__metha-logo" }, [
          _createElementVNode("img", {
            src: _imports_0,
            alt: "Logo da Metha Energia",
            class: "login-verify-cpf__metha-logo",
            width: "205"
          })
        ], -1)),
        _cache[7] || (_cache[7] = _createElementVNode("h2", { class: "login-verify-cpf__title" }, "Bem vindo(a)!", -1)),
        _createElementVNode("p", _hoisted_2, [
          _cache[2] || (_cache[2] = _createTextVNode(" Faça login abaixo ou ")),
          _createElementVNode("a", _hoisted_3, "cadastre-se" + _toDisplayString(_unref(mdAndDown) ? "" : " na Metha"), 1)
        ]),
        _withDirectives(_createVNode(_unref(MeTextField), {
          label: "Seu CPF",
          modelValue: cpf.value,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((cpf).value = $event)),
          placeholder: "000.000.000-00",
          error: _unref(loginStore).cpfError,
          class: "login-verify-cpf__cpf-input"
        }, null, 8, ["modelValue", "error"]), [
          [_directive_maska, '###.###.###-##']
        ]),
        (!_unref(loginStore).isActiveUser)
          ? (_openBlock(), _createElementBlock("p", _hoisted_4, _cache[3] || (_cache[3] = [
              _createTextVNode(" Você ainda não é um usuário ativo. Finalize seu cadastro "),
              _createElementVNode("a", {
                class: "me--link",
                href: "https://cadastro.methaenergia.com.br/cadastro-existente"
              }, "clicando aqui", -1),
              _createTextVNode(" . ")
            ])))
          : _createCommentVNode("", true),
        _createVNode(_unref(MeButton), {
          block: "",
          class: "login-verify-cpf__login-button",
          disabled: invalidSubmit.value
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode(" Entrar ")
          ])),
          _: 1
        }, 8, ["disabled"]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "login-verify-cpf__create-account" }, [
          _createElementVNode("p", { class: "login-verify-cpf__create-account__text" }, " Ainda não possui uma conta? "),
          _createElementVNode("div", { class: "login-verify-cpf__create-account__line" })
        ], -1)),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_unref(MeButton), {
            variant: "outline",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (redirectToMethaPwa()))
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Cadastrar na Metha ")
            ])),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})