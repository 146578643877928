const invalidList = [
  "00000000000",
  "11111111111",
  "22222222222",
  "33333333333",
  "44444444444",
  "55555555555",
  "66666666666",
  "77777777777",
  "88888888888",
  "99999999999",
];

export const cpf = (value = "") => {
  if (!value.trim()) {
    return false;
  }

  const cpfNumber = value.replace(/\D/g, "");

  if (invalidList.includes(cpfNumber)) return false;

  let sum = 0;
  let rest;

  for (let i = 1; i <= 9; i++)
    sum = sum + parseInt(cpfNumber.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpfNumber.substring(9, 10))) return false;

  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum = sum + parseInt(cpfNumber.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;

  if (rest == 10 || rest == 11) rest = 0;
  if (rest != parseInt(cpfNumber.substring(10, 11))) return false;
  return true;
};
