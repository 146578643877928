import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "last-invoice-details" }
const _hoisted_2 = { class: "last-invoice-details__header" }
const _hoisted_3 = { class: "last-invoice-details__date-container" }
const _hoisted_4 = { class: "last-invoice-details__date-container__reference-month" }
const _hoisted_5 = { class: "last-invoice-details__date-container__due-date" }
const _hoisted_6 = { class: "last-invoice-details__middle-section__item" }
const _hoisted_7 = { class: "last-invoice-details__bottom-section" }
const _hoisted_8 = { class: "last-invoice-details__values-container" }
const _hoisted_9 = { class: "last-invoice-details__values-container__total-price" }
const _hoisted_10 = { class: "last-invoice-details__button-container" }
const _hoisted_11 = {
  key: 0,
  class: "last-invoice-details__button-container__payments"
}

import { computed } from "vue";
import { useRouter } from "vue-router";
import { MeButton } from "@/libs/metha-components";

import type { Invoice } from "@/types/InvoiceTypes";
import { formatDate, formatMoney } from "@/utils/format";

import PaymentMethodsModalWithActivator from "@/components/organisms/PaymentMethodsModalWithActivator.vue";

import ConsumptionGraph from "@/components/molecules/ConsumptionGraph.vue";

import StatusTag from "@/components/atoms/StatusTag.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'LastInvoiceDetails',
  props: {
    lastInvoice: {}
  },
  setup(__props: any) {

const props = __props;

const router = useRouter();

const isPaid = computed(() => {
  return props.lastInvoice.status === "paid";
});

function redirectToInvoiceDetails() {
  router.push(`/faturas/${props.lastInvoice.id}`);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("h3", { class: "last-invoice-details__header__title" }, "Fatura mais recente", -1)),
      _createVNode(StatusTag, {
        status: _ctx.lastInvoice.status,
        invoice: "",
        class: "last-invoice-details__status"
      }, null, 8, ["status"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _cache[1] || (_cache[1] = _createElementVNode("span", { class: "last-invoice-details__date-container__text" }, " Referente a ", -1)),
        _createTextVNode(" " + _toDisplayString(_ctx.lastInvoice.reference_month), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _cache[2] || (_cache[2] = _createElementVNode("span", { class: "last-invoice-details__date-container__text" }, " Vencimento ", -1)),
        _createTextVNode(" " + _toDisplayString(_unref(formatDate)(_ctx.lastInvoice.due_date)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(ConsumptionGraph, {
        total: _ctx.lastInvoice.kwh_consumption,
        active: _ctx.lastInvoice.kwh_injected,
        discount: _ctx.lastInvoice.discount_percentage,
        mainColor: "#FFCC12",
        secondaryColor: "#E3E5E4"
      }, null, 8, ["total", "active", "discount"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _cache[3] || (_cache[3] = _createElementVNode("span", { class: "last-invoice-details__values-container__total-price__text" }, " Total a pagar ", -1)),
          _createTextVNode(" " + _toDisplayString(_unref(formatMoney)(_ctx.lastInvoice.total_value)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        (!isPaid.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createVNode(PaymentMethodsModalWithActivator, { invoice: _ctx.lastInvoice }, null, 8, ["invoice"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _createVNode(_unref(MeButton), {
            block: "",
            onClick: redirectToInvoiceDetails
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode(" Detalhes da Fatura ")
            ])),
            _: 1
          })
        ])
      ])
    ])
  ]))
}
}

})