import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "swipe-sustainability-statistics" }

import { computed, markRaw, reactive, ref } from "vue";
import SustainabilityCard from "@/components/atoms/SustainabilityCard";

const decisionVal = 40;

export default /*@__PURE__*/_defineComponent({
  __name: 'SwipeSustainabilityStatistics',
  props: {
  kwhConsumption: {
    type: Number,
    required: true,
  },
  active: Boolean,
},
  setup(__props) {

const props = __props;

const cardsWithTypes = markRaw([
  {
    type: "co2",
  },
  {
    type: "trees",
  },
  {
    type: "coal",
  },
]);

const animating = ref(false);
const cardsCounter = ref(0);
const pullDeltaX = ref(0);
const deg = ref(0);

const refs = ref<Array<HTMLDivElement | Element | null>>([]);

const cards = reactive(
  cardsWithTypes.map((el, index) => ({
    ...el,
    toRight: false,
    toLeft: false,
    inactive: false,
    pulling: false,
    transform: "",
    zIndex: cardsWithTypes.length * 2 - index,
  }))
);

function resetAllCards() {
  cardsCounter.value = 0;
  cards.forEach((card, index) => {
    cards[index] = {
      ...card,
      toRight: false,
      toLeft: false,
      inactive: false,
      pulling: false,
      transform: "",
      zIndex: cardsWithTypes.length - index,
    };
  });
}

const card = computed(() => {
  return cards[cardsCounter.value];
});

function goToNextCard() {
  pullChange(-200);
  release();
}

function pullChange(customPullValue?: number) {
  if (typeof customPullValue === "number") {
    pullDeltaX.value = customPullValue;
  }

  animating.value = true;
  deg.value = pullDeltaX.value / 10;
  card.value.transform =
    "translateX(" + pullDeltaX.value + "px) rotate(" + deg.value + "deg)";
}

function release() {
  card.value.pulling = false;
  const pullValue = pullDeltaX.value;
  let goToNextCard = false;
  let reset = false;

  if (pullValue >= decisionVal) {
    card.value.toRight = true;
  } else if (pullValue <= -decisionVal) {
    card.value.toLeft = true;
  }

  if (Math.abs(pullValue) >= decisionVal) {
    card.value.inactive = true;

    setTimeout(function () {
      card.value.zIndex -= cards.length;

      card.value.inactive = false;
      card.value.toLeft = false;
      card.value.toRight = false;
      card.value.transform = "";

      goToNextCard = true;

      if (cardsCounter.value >= cards.length - 1) {
        reset = true;
        resetAllCards();
      }
    }, 300);
  }

  setTimeout(function () {
    card.value.transform = "";

    if (goToNextCard) {
      cardsCounter.value += 1;
    }

    if (reset) {
      cardsCounter.value = 0;
    }

    pullDeltaX.value = 0;
    animating.value = false;
  }, 300);
}

function startInteraction(e) {
  if (animating.value || !props.active) return;

  var startX = e.pageX || e.touches[0].pageX;

  card.value.pulling = true;

  document.addEventListener("mousemove", moveInteraction);
  document.addEventListener("touchmove", moveInteraction);
  document.addEventListener("mouseup", endInteraction);
  document.addEventListener("touchend", endInteraction);

  function moveInteraction(e) {
    var x = e.pageX || e.touches[0].pageX;
    pullDeltaX.value = x - startX;
    if (!pullDeltaX.value) return;
    pullChange();
  }

  function endInteraction() {
    document.removeEventListener("mousemove", moveInteraction);
    document.removeEventListener("touchmove", moveInteraction);
    document.removeEventListener("mouseup", endInteraction);
    document.removeEventListener("touchend", endInteraction);
    if (!pullDeltaX.value) return;
    release();
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cards, (card, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: _normalizeClass([
        'swipe-sustainability-statistics__card',
        {
          'swipe-sustainability-statistics--to-right': card.toRight,
          'swipe-sustainability-statistics--to-left': card.toLeft,
          'swipe-sustainability-statistics--pulling': card.pulling,
          'swipe-sustainability-statistics--inactive': card.inactive,
        },
      ]),
        style: _normalizeStyle(`transform: ${card.transform}; z-index: ${card.zIndex};`),
        onMousedown: startInteraction,
        onTouchstart: startInteraction,
        ref_for: true,
        ref: 
        (el) => {
          refs.value[index] = el;
        }
      
      }, [
        _createVNode(_unref(SustainabilityCard), {
          cardInfo: card,
          kwhConsumption: __props.kwhConsumption,
          activeIndex: index,
          numberOfBullets: cards.length,
          goToNextCard: goToNextCard
        }, null, 8, ["cardInfo", "kwhConsumption", "activeIndex", "numberOfBullets"])
      ], 38))
    }), 128))
  ]))
}
}

})