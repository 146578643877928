import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "adc__invoices__section service-channels" }
const _hoisted_2 = { class: "service-channels__mobile-menu" }
const _hoisted_3 = { class: "service-channels__mobile-menu__open-chat__icon" }
const _hoisted_4 = ["onClick"]

import { nextTick, readonly, ref } from "vue";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores";

import AcordeonSwitch from "@/components/molecules/AcordeonSwitch.vue";
import ServiceChannelItem from "@/components/molecules/ServiceChannelItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ServiceChannels',
  setup(__props) {

const items = readonly([
  {
    icon: "nm-chat",
    title: "Chat",
    text: "O Chat é nosso principal canal de atendimento! Tire suas dúvidas e resolva o que precisar com a gente.",
    button_text: "Conversar no chat",
    url: null,
  },
  {
    icon: "nm-phone-ringing",
    title: "Telefone",
    text: "Alguma coisa importante que é melhor falar pelo telefone? Liga para a gente discando o número abaixo.",
    button_text: "(31) 3003-0531",
    url: "tel:+553130030531",
  },
  {
    icon: "nm-email",
    title: "E-mail",
    text: "Prefere um contato mais formal, e deixar tudo registrado por e-mail? Use o endereço abaixo.",
    button_text: "contato@methaenergia.com.br",
    url: "mailto:contato@methaenergia.com.br",
  },
]);

const userStore = useUserStore();

const serviceChannelsSectionTop = ref<HTMLElement>();

function scrollAfterOpeningMenu(cb: () => void) {
  cb();

  nextTick(() => {
    serviceChannelsSectionTop.value?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  });
}

function openChat() {
  userStore.openSunshineChat();
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", {
    style: {"padding-bottom":"1.5rem","scroll-margin":"77px"},
    ref_key: "serviceChannelsSectionTop",
    ref: serviceChannelsSectionTop
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[4] || (_cache[4] = _createElementVNode("h1", { class: "service-channels__title" }, "Canais de atendimento", -1)),
      _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "service-channels__subtitle" }, "Segunda a sexta de 09h as 19h", -1)),
      _createVNode(AcordeonSwitch, null, {
        default: _withCtx(({ handleClick }) => [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_unref(MeButton), {
              onClick: openChat,
              block: "",
              class: "service-channels__mobile-menu__open-chat"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, [
                  _createVNode(_unref(MeIcon), null, {
                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                      _createTextVNode("nm-chat")
                    ])),
                    _: 1
                  })
                ]),
                _cache[1] || (_cache[1] = _createTextVNode(" Conversar no chat "))
              ]),
              _: 1
            }),
            _createVNode(_unref(MeButton), {
              onClick: () => scrollAfterOpeningMenu(handleClick),
              variant: "outline",
              block: ""
            }, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode(" Outros canais de atendimento ")
              ])),
              _: 2
            }, 1032, ["onClick"])
          ])
        ]),
        content: _withCtx(({ isOpen }) => [
          _createElementVNode("div", {
            class: "service-channels__contacts-container",
            style: _normalizeStyle({ 'margin-top': isOpen ? '2rem' : '0' })
          }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(items), (item, index) => {
              return (_openBlock(), _createBlock(ServiceChannelItem, {
                item: item,
                key: `${index}`
              }, null, 8, ["item"]))
            }), 128))
          ], 4)
        ]),
        retractor: _withCtx(({ handleClick }) => [
          _createElementVNode("div", {
            onClick: handleClick,
            class: "service-channels__mobile-menu__retractor"
          }, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode("chevron-bottom")
              ])),
              _: 1
            })
          ], 8, _hoisted_4)
        ]),
        _: 1
      })
    ])
  ], 512))
}
}

})