import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/gifs/loading-contract.gif'
import _imports_1 from '@/assets/gifs/spinner-check.gif'


const _hoisted_1 = { class: "metha-contract" }
const _hoisted_2 = { class: "metha-contract__content" }
const _hoisted_3 = { class: "metha-contract__content__container" }
const _hoisted_4 = { class: "metha-contract__content__container" }
const _hoisted_5 = { class: "metha-contract__content__container" }
const _hoisted_6 = { class: "metha-contract__success-feedback" }
const _hoisted_7 = { class: "metha-contract__success-feedback__title" }
const _hoisted_8 = { class: "metha-contract__success-feedback__description" }

import { computed } from "vue";
import { MeIcon, MeButton } from "@/libs/metha-components";
import { useContractStore } from "@/stores";

import Spinner from "@/components/atoms/CaSpinner.vue";
import ContractDisplay from "@/components/molecules/ContractDisplay.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'MethaContract',
  setup(__props) {

const contractStore = useContractStore();

const isUpdatingContract = computed(
  () => contractStore.installation?.status !== "pending_agreement"
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("div", _hoisted_3, _cache[1] || (_cache[1] = [
        _createElementVNode("img", {
          src: _imports_0,
          alt: ""
        }, null, -1),
        _createElementVNode("p", { class: "metha-contract__loading-text" }, [
          _createTextVNode(" Carregando contrato."),
          _createElementVNode("br"),
          _createTextVNode("Aguarde... ")
        ], -1)
      ]), 512), [
        [_vShow, _unref(contractStore).signatureStatus === 'loading']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_4, [
        _createVNode(Spinner, { size: 60 })
      ], 512), [
        [_vShow, _unref(contractStore).signatureStatus === 'signingContract']
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: "metha-contract__close-button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(contractStore).closeContract && _unref(contractStore).closeContract(...args)))
          }, [
            _createVNode(_unref(MeIcon), null, {
              default: _withCtx(() => _cache[2] || (_cache[2] = [
                _createTextVNode("x")
              ])),
              _: 1
            })
          ]),
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_1,
            alt: "Contrato assinado com sucesso",
            class: "metha-contract__success-feedback__gif"
          }, null, -1)),
          _createElementVNode("h2", _hoisted_7, _toDisplayString(isUpdatingContract.value
                ? "Contrato atualizado com sucesso"
                : "Cadastro finalizado com sucesso"), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(isUpdatingContract.value
                ? "Agora está tudo certo com as condições necessárias para continuar recebendo os serviços da Metha. Obrigado por manter suas informações atualizadas conosco!"
                : "Agora o seu cadastro está sendo analisado. Em até 5 dias úteis você vai receber o nosso retorno sobre a aprovação da sua instalação."), 1),
          _createElementVNode("div", null, [
            _createVNode(_unref(MeButton), {
              variant: "outline",
              onClick: _unref(contractStore).closeContract
            }, {
              default: _withCtx(() => _cache[3] || (_cache[3] = [
                _createTextVNode(" Fechar ")
              ])),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ], 512), [
        [_vShow, _unref(contractStore).signatureStatus === 'successFeedback']
      ]),
      _withDirectives(_createElementVNode("div", null, [
        _createVNode(ContractDisplay)
      ], 512), [
        [_vShow, _unref(contractStore).signatureStatus === '']
      ])
    ], 512), [
      [_vShow, _unref(contractStore).open]
    ])
  ]))
}
}

})