<script setup lang="ts">
import { computed, onBeforeMount, ref } from "vue";
import { toast } from "vue3-toastify";
import { MeButton, MeIcon } from "@/libs/metha-components";

import { useUserStore } from "@/stores/user";
import { useFaqStore } from "@/stores/faq";

import { handleError } from "@/utils/handleError";
import FaqSectionCard from "@/components/molecules/FaqSectionCard";

const userStore = useUserStore();
const faqStore = useFaqStore();

const maxRows = 1;
const columns = 3;

const isOpen = ref(false);

const maxDisplayedElements = computed(() => {
  return maxRows * columns;
});

const hasExpandButton = computed(() => {
  return faqStore.sections.length > maxRows * columns;
});

const alwaysDisplayedElements = computed(() => {
  return faqStore.sections.slice(0, maxDisplayedElements.value);
});

const hiddenElements = computed(() => {
  if (faqStore.sections.length > maxDisplayedElements.value) {
    return faqStore.sections.slice(maxDisplayedElements.value);
  }

  return [];
});

onBeforeMount(async () => {
  try {
    userStore.pageLoading = true;

    await faqStore.getHelpCenterCategory();

    const categoryId = faqStore.categoryId ?? 0;
    await faqStore.getSections(categoryId);
  } catch (error) {
    handleError(error, (err: string) => {
      toast.error(err, {
        position: toast.POSITION.BOTTOM_CENTER,
      });
    });
  } finally {
    userStore.pageLoading = false;
  }
});
</script>

<template>
  <section class="grid-faq">
    <div class="grid-faq__container">
      <div
        v-for="(section, index) in alwaysDisplayedElements"
        :key="`faq-section-card-${index}`"
      >
        <FaqSectionCard :section="section" :maxArticles="4" />
      </div>
    </div>
    <div class="grid-faq__hidden-container" v-show="hasExpandButton && isOpen">
      <div
        v-for="(section, index) in hiddenElements"
        :key="`faq-section-card-hidden-${index}`"
      >
        <FaqSectionCard :section="section" />
      </div>
    </div>
    <div v-if="hasExpandButton" class="grid-faq__expander">
      <MeButton
        @click="isOpen = !isOpen"
        class="grid-faq__container__expander__button"
        variant="outline"
        color="foreground"
      >
        <MeIcon :style="{ transform: `rotate(${isOpen ? '180' : '0'}deg)` }">
          chevron-bottom
        </MeIcon>
        <p>Ver {{ isOpen ? "menos" : "mais" }} tópicos</p>
      </MeButton>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.grid-faq {
  max-width: 950px;
  width: calc(100% - 2rem);
  margin: 1.5rem auto;

  @media (min-width: 1020px) {
    max-width: 1180px;
    width: calc(100% - 6rem);
  }

  &__container,
  &__hidden-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 993px) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  &__hidden-container {
    margin-top: 1rem;
  }

  &__expander {
    width: 100%;
    display: flex;
    justify-content: center;

    margin-top: 1.5rem;

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }
  }
}
</style>
